import React, {useContext, useEffect, useState} from 'react'
import {BrowserRouter} from 'react-router-dom'
import Router from './Router.js'
import {ContextApplication} from './contexto/ApplicationContext.js'
import Spinner from './componentes/Spinner.jsx'
import {BarraNavegacion} from './componentes/Navegacion'
import {useMsal} from '@azure/msal-react'
import OfflineWarning from './componentes/OffLineWarning.jsx'
// import { registerNewPatient } from './components/API/ConsultCreatePatientAPI';
// import { postNewOrderService } from './components/API/CreateOrderService'
// import { postNewPatientHistory } from './components/API/MedicForm'
import ModalCendiatra from './componentes/Modales/ModalCendiatra.jsx'
import {PoblarCacheComun} from './cache/servicios-cache/Comun.js'
import {ObtenerTodasLasEps} from './cache/servicios-cache/Eps.js'
import {ObtenerTodasLasArl} from './cache/servicios-cache/Arl.js'
import {ObtenerFondoDePensiones} from './cache/servicios-cache/FondoPension.js'
import {
  ObtenerEstadosCiviles,
  ObtenerEscolaridades,
  ObtenerRazas,
  ObtenerSexos,
  ObtenerZonas,
  ObtenerEstratos,
  ObtenerIndicativos,
  ObtenerFacturarA,
  ObtenerTiposDeExamen,
  ObtenerActividadEconomica,
} from './cache/servicios-cache/Referencia.js'
import {ObtenerTiposDeAtencion} from './cache/servicios-cache/TipoAtencion'
import {ObtenerGruposSanguineos} from './cache/servicios-cache/GrupoSanguineo'
import Seleccion from './paginas/compartida/Seleccion.jsx'
import {ROLES_APP} from './constantes/roles'
import {ContextoNavegacion} from './contexto'
import {rolRequiereConfiguracionSede} from './utilidades/funcionesComunes.js'
import {AgregarFuentesDeDatosACache} from './cache/servicios-cache/FuentesDeDatos.js'
import {AgregarEmpresasACache} from './cache/servicios-cache/Empresas.js'
import {obtenerVersionApp} from './config.js'

const Home = () => {
  const {accounts} = useMsal()
  const [numeroVersion, setNumeroVersion] = useState('Cargando versión...')
  const rolUsuario = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  useEffect(() => {
    obtenerVersionApp()
      .then((version) => {
        setNumeroVersion(`4.0.${version}`)
      })
      .catch((error) => {
        console.error('Error al obtener la versión de la aplicación:', error)
        setNumeroVersion('Error al cargar la versión')
      })
  }, [])

  const ctx = useContext(ContextApplication)

  const contextoNavegacion = useContext(ContextoNavegacion)

  useEffect(() => {
    isOnline() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isOnline = () => {
    if (navigator.onLine) {
      ctx.setOfflineMode(false)
    } else {
      ctx.setOfflineMode(true)
    }
  }

  window.addEventListener('online', isOnline)
  window.addEventListener('offline', isOnline)

  useEffect(() => {
    AgregarFuentesDeDatosACache()
    PoblarCacheComun().then(async () => {
      ctx.setGruposSanguineos(await ObtenerGruposSanguineos())
      ctx.setEstadosCiviles(await ObtenerEstadosCiviles())
      ctx.setEscolaridades(await ObtenerEscolaridades())
      ctx.setRazas(await ObtenerRazas())
      ctx.setSexos(await ObtenerSexos())
      ctx.setZonas(await ObtenerZonas())
      ctx.setEstratos(await ObtenerEstratos())
      ctx.setIndicativos(await ObtenerIndicativos())
      ctx.setFacturarA(await ObtenerFacturarA())
      ctx.setTipoExamen(await ObtenerTiposDeExamen())
      ctx.setEps(await ObtenerTodasLasEps())
      ctx.setArl(await ObtenerTodasLasArl())
      ctx.setTipoAtencion(await ObtenerTiposDeAtencion())
      ctx.setFondoPension(await ObtenerFondoDePensiones())
      ctx.setActividadesEconomicas(await ObtenerActividadEconomica())
    })
  }, [accounts]) // eslint-disable-next-line react-hooks/exhaustive-deps

  const seleccionarComponente = (rol) => {
    const componentes = [
      {
        roles: [ROLES_APP.RECEPCION, ROLES_APP.RECEPCION_ALIADO],
        componente: <Seleccion SeleccionSede="Recepción" />,
      },
      {
        roles: [
          ROLES_APP.AUDITOR,
          ROLES_APP.JEFE_SEDE,
          ROLES_APP.AUXILIAR_CONTABLE,
          ROLES_APP.TESORERO,
          ROLES_APP.FACTURADOR,
        ],
        componente: <Seleccion SeleccionSede={''} />,
      },
    ]

    return (
      componentes.find((item) => item.roles.includes(rol))?.componente ?? (
        <Seleccion SeleccionSede="Consultorio" />
      )
    )
  }

  //#region temporally comment out, please DO NOT remove
  // useEffect(() => {

  //     const uploadCreateUserForm = async () => {
  //         if (!ctx.offlineMode) {

  //             const cendiatraDbName = 'cendiatra-db'
  //             let db = await new Dexie(cendiatraDbName).open();
  //             Promise.resolve(db.table('CreateUserForm').toArray()).then((res) => {
  //                 if (res.length > 0) {
  //                     res.forEach(async (item) => {
  //                         await registerNewPatient(item.data).then((response) => {
  //                             if (response.status === 200) {
  //                                 console.log('Se ha registrado el paciente')
  //                             }
  //                         })
  //                             .catch((error) => {
  //                                 console.log(`Ocurió un error: ${error}`)
  //                             })
  //                     })
  //                 }
  //             }).then(() => {
  //                 db.table('CreateUserForm').clear()
  //             })
  //                 .catch((error) => {
  //                     console.log(`Ocurió un error: ${error}`)
  //                 })
  //         }
  //     }

  //     const uploadCreateOrderForm = async () => {
  //         if (!ctx.offlineMode) {

  //             const cendiatraDbName = 'cendiatra-db'
  //             let db = await new Dexie(cendiatraDbName).open();
  //             Promise.resolve(db.table('CreateOrderForm').toArray()).then((res) => {
  //                 if (res.length > 0) {

  //                     console.log(res)
  //                     res.forEach(async (item) => {

  //                         await postNewOrderService(item.data).then((response) => {
  //                             if (response.status === 200) {

  //                                 console.log('Se ha Creado la orden de servicio')

  //                             }
  //                         })
  //                             .catch((error) => {
  //                                 console.log(`Ocurió un error: ${error}`)
  //                             })
  //                     })
  //                 }
  //             }).then(() => {
  //                 db.table('CreateOrderForm').clear()
  //             })
  //                 .catch((error) => {
  //                     console.log(`Ocurió un error: ${error}`)
  //                 })
  //         }
  //     }

  //     const uploadCreateMedicForm = async () => {
  //         if (!ctx.offlineMode) {

  //             const cendiatraDbName = 'cendiatra-db'
  //             let db = await new Dexie(cendiatraDbName).open();
  //             Promise.resolve(db.table('CreateMedicForm').toArray()).then((res) => {
  //                 if (res.length > 0) {

  //                     res.forEach(async (item) => {

  //                         await postNewPatientHistory(item.data).then((response) => {
  //                             if (response.status === 200) {

  //                                 console.log('Se ha Creado el formulario medico')

  //                             }
  //                         })
  //                             .catch((error) => {
  //                                 console.log(`Ocurió un error: ${error}`)
  //                             })
  //                     })
  //                 }
  //             }).then(() => {
  //                 db.table('CreateMedicForm').clear()
  //             })

  //                 .catch((error) => {
  //                     console.log(`Ocurió un error: ${error}`)
  //                 })
  //         }
  //     }
  //     Dexie.exists('cendiatra-db').then((exist) => {
  //         if (exist) {

  //             uploadCreateUserForm()
  //             uploadCreateOrderForm()
  //             uploadCreateMedicForm()
  //         }
  //     });

  // }, [ctx.offlineMode])
  //#endregion
  return (
    <>
      <ModalCendiatra />
      <BrowserRouter>
        {ctx.isLoading || ctx.countFetching > 0 ? <Spinner /> : null}
        {ctx.offlineMode ? <OfflineWarning /> : null}
        {contextoNavegacion.mostrar && <BarraNavegacion />}
        {!!!ctx.sede.sedeId && rolRequiereConfiguracionSede(rolUsuario) ? (
          seleccionarComponente(rolUsuario)
        ) : (
          <>
            <Router />
            <div className="text-cendiatra fixed text-sm bottom-0 right-0 p-2">
              {' '}
              {numeroVersion}
            </div>
          </>
        )}
      </BrowserRouter>
    </>
  )
}

export default Home
