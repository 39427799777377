import React, {useEffect, useState, useContext, useRef} from 'react'
import {Input, ErroresInput, InputMoneda} from '../inputs'
import BotonAgregar from '../../imagenes/addButton.png'
import BotonQuitar from '../../imagenes/removeButton.png'
import BotonConImagen from '../BotonConImagen'
import {Controller, useForm} from 'react-hook-form'
import {
  regexCorreoElectronico,
  regexValidarNit,
  regexNombreEmpresaHC,
  regexTextoCaracteresBasicos,
} from '../../constantes'
import {aplicarFormatoMoneda} from '../../utilidades'
import {BotonSimple} from '../Botones'
import {GuardarDatosFacturacionPago} from '../../microservicios'
import {ContextApplication} from '../../contexto'
import clsx from 'clsx'
import {TEXTO_VISUAL} from '../../constantes'
import {v4 as uuidv4} from 'uuid'

const FormularioDatosDeLosServicios = ({
  valorFactura,
  funcionCerrar,
  valoresFormulario,
  servicios,
  refrescarDatos,
}) => {
  const {
    control,
    trigger,
    reset,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const datosServicioPorDefecto = {
    cantidad: '',
    id: '',
    nombreServicio: '',
    datosServicio: {},
    valorServicio: '',
    totalServicios: '',
  }

  const contextoAplicacion = useContext(ContextApplication)

  const [datosServicioParaAgregar, setDatosServicioParaAgregar] = useState(
    datosServicioPorDefecto
  )
  const [serviciosFactura, setServiciosFactura] = useState([])

  const [serviciosFiltrados, setServiciosFiltrados] = useState([])

  const [valorTotal, setValorTotal] = useState(0)

  const totalServiciosRef = useRef(0)

  const [mostrarErrorValoresDiferentes, setMostrarErrorValoresDiferentes] =
    useState(false)

  const sedeId = JSON.parse(sessionStorage.getItem('sedeId'))

  useEffect(() => {
    if (valoresFormulario) {
      reset(valoresFormulario)
      setServiciosFactura(
        servicios.map((servicio) => {
          return {
            cantidad: servicio.cantidad,
            nombreServicio: servicio.nombreServicio,
            valorServicio: servicio.valor,
            totalServicios:
              parseFloat(servicio.cantidad) * parseFloat(servicio.valor),
          }
        })
      )
    }
  }, [])

  const objetoVacio = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }
  const formularioTieneErrores = objetoVacio(errors)

  useEffect(() => {
    trigger()
  }, [])

  const guardarInformacionDatosServicios = async () => {
    const valoresFormulario = getValues()

    if (parseFloat(valorFactura.valor) !== parseFloat(valorTotal)) {
      return setMostrarErrorValoresDiferentes(true)
    }

    const payload = {
      pagoId: valorFactura?.id,
      nit: valoresFormulario?.nit,
      razonSocial: valoresFormulario?.razonSocial,
      correo: valoresFormulario?.correo,
      sedeId: sedeId,
      servicios: serviciosFactura?.map((servicio) => {
        return {
          cantidad: servicio.cantidad,
          nombreServicio: servicio.nombreServicio,
          valor: servicio.valorServicio,
        }
      }),
    }

    try {
      const respuesta = await GuardarDatosFacturacionPago(payload)

      if (respuesta.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: '',
          contenido: (
            <div className="w-full flex justify-center items-center text-center flex-wrap">
              <span className="w-full font-bold text-3xl mb-12 normal-case">
                Guardado exitoso
              </span>
              <span className="w-full normal-case text-xl">
                La confirmación del pago fue guardada correctamente.
              </span>
            </div>
          ),

          botones: [
            {
              nombre: 'Cerrar',
              click: () => funcionCerrar(),
            },
          ],
        })
        return refrescarDatos()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const valorCantidad = parseFloat(datosServicioParaAgregar.cantidad)
    const valorServicio = parseFloat(datosServicioParaAgregar.valorServicio)

    if (isNaN(valorCantidad) && isNaN(valorServicio)) {
      totalServiciosRef.current = ''
    } else {
      setDatosServicioParaAgregar((prev) => {
        return {
          ...prev,
          totalServicios: valorCantidad * valorServicio,
        }
      })

      totalServiciosRef.current = valorCantidad * valorServicio
    }
  }, [
    datosServicioParaAgregar.cantidad,
    datosServicioParaAgregar.valorServicio,
  ])

  useEffect(() => {
    const obtenerSumatoriaServicios = () => {
      const sumatoriaValorServicios = serviciosFactura.reduce(
        (total, servicio) => {
          return total + servicio.totalServicios
        },
        0
      )
      setValorTotal(sumatoriaValorServicios)
    }
    obtenerSumatoriaServicios()
  }, [serviciosFactura])

  const actualizarServicioParaAgregar = (nombre, e) => {
    setDatosServicioParaAgregar((prev) => {
      return {
        ...prev,
        [nombre]: e.target?.value ? e.target?.value : e?.floatValue,
      }
    })
  }

  const agregarServicioFactura = () => {
    if (
      datosServicioParaAgregar?.cantidad &&
      datosServicioParaAgregar?.nombreServicio &&
      datosServicioParaAgregar?.valorServicio &&
      !errors.nombreServicio
    ) {
      const servicioConId = {...datosServicioParaAgregar, id: uuidv4()}
      setServiciosFactura((prev) => [...prev, servicioConId])
      setDatosServicioParaAgregar(datosServicioPorDefecto)
    }
  }

  const eliminarServicios = (id) => {
    const nuevoArreglo = serviciosFactura.filter(
      (servicio) => servicio.id !== id
    )
    setServiciosFactura(nuevoArreglo)
  }

  return (
    <>
      <div
        className={`w-full ${
          mostrarErrorValoresDiferentes ? 'hidden' : 'flex'
        } justify-center items-center flex-wrap -mt-10`}
      >
        <div className="w-4/10 flex items-center text-2xl text-center font-bold normal-case">
          {`Datos de los servicios ${clsx({
            'que se envían a facturar': !valoresFormulario,
          })}`}
        </div>
        <div
          className={`w-full flex jusitfy-center items-center border rounded-2xl flex-wrap p-4 mt-10 text-center ${
            valoresFormulario ? 'pointer-events-none' : ''
          }`}
        >
          <span className="w-full text-center normal-case">
            Datos para facturación electrónica
          </span>
          <div className="w-full flex justify-evenly items-center mt-5">
            <Controller
              name="nit"
              control={control}
              rules={{
                required: true,
                pattern: regexValidarNit,
              }}
              render={({field: {value, onChange}}) => (
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-3/12 text-left'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    !valoresFormulario && 'border',
                    Object.keys(errors).includes('nit')
                      ? 'border-cendiatra-rojo-1'
                      : 'border-cendiatra'
                  )}
                  tipo={'text'}
                  placeholder={'Descripción'}
                  titulo={'NIT*'}
                  valor={value}
                />
              )}
            />
            <Controller
              name="razonSocial"
              control={control}
              rules={{
                required: true,
                pattern: regexNombreEmpresaHC,
              }}
              render={({field: {value, onChange}}) => (
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-3/12 text-left normal-case'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    !valoresFormulario && 'border',
                    Object.keys(errors).includes('razonSocial')
                      ? 'border-cendiatra-rojo-1'
                      : 'border-cendiatra'
                  )}
                  tipo={'text'}
                  placeholder={'Descripción'}
                  titulo={'Razón social*'}
                  valor={value}
                />
              )}
            />
            <Controller
              name="correo"
              control={control}
              rules={{
                required: true,
                pattern: regexCorreoElectronico,
              }}
              render={({field: {value, onChange}}) => (
                <div className="w-4/12 flex items-center justify-center flex-wrap">
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full text-left normal-case'}
                    estilosInput={clsx(
                      'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                      !valoresFormulario && 'border',
                      Object.keys(errors).includes('correo')
                        ? 'border-cendiatra-rojo-1'
                        : 'border-cendiatra'
                    )}
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Correo*'}
                    valor={value}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'correo'}
                    tipoError={'pattern'}
                    mensaje={'Ej: ejemplo@ejemplo.com'}
                  />
                </div>
              )}
            />
          </div>
        </div>
        {!valoresFormulario ? (
          <div className="w-full flex items-center justify-center flex-wrap">
            <span className="w-full text-center mt-10 text-cendiatra-gris-2 normal-case ">
              Debe coincidir el valor pagado con el valor de los servicios
            </span>
            <span className="w-full text-center mt-10 normal-case text-cendiatra font-extrabold text-lg">
              Valor del pago : {aplicarFormatoMoneda(valorFactura.valor)}
            </span>
          </div>
        ) : null}
        <div className="w-full flex jusitfy-center items-center border rounded-2xl flex-wrap p-4 mt-10 text-center">
          <span className="w-full text-center normal-case">
            Servicios a facturar
          </span>
          {!valoresFormulario ? (
            <div className="w-full flex justify-evenly items-center mt-5 flex-wrap">
              <Input
                onChange={(e) => actualizarServicioParaAgregar('cantidad', e)}
                estilosContenedor={'w-22% text-left normal-case'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'number'}
                placeholder={'Descripción'}
                titulo={'Cantidad'}
                valor={datosServicioParaAgregar.cantidad}
                nombre={'cantidad'}
              />
              <Controller
                name="nombreServicio"
                control={control}
                rules={{
                  required: false,
                  pattern: regexTextoCaracteresBasicos,
                }}
                render={({field: {onChange}}) => (
                  <Input
                    funcion={(e) => {
                      onChange(e)
                      actualizarServicioParaAgregar('nombreServicio', e)
                    }}
                    estilosContenedor={'w-22%  text-left normal-case'}
                    estilosInput={clsx(
                      'appearance-none rounded relative block border w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                      Object.keys(errors).includes('nombreServicio')
                        ? 'border-cendiatra-rojo-1'
                        : 'border-cendiatra'
                    )}
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Servicio'}
                    valor={datosServicioParaAgregar.nombreServicio}
                    nombre={'nombreServicio'}
                  />
                )}
              />
              <InputMoneda
                estilosContenedor={'w-22% text-left -mt-2 normal-case'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                titulo={'Valor'}
                valor={datosServicioParaAgregar.valorServicio}
                formatoVacio={true}
                placeholder={'Descripción'}
                onValueChange={(e) =>
                  actualizarServicioParaAgregar('valorServicio', e)
                }
                nombre={'valorServicio'}
              />
              <div className="w-22% flex jusitfy-center items-center flex-wrap">
                <label className="w-full block text-13px leading-15px text-cendiatra-verde-2 my-5px text-left -mt-1 normal-case">
                  Total servicios
                </label>
                <span className="w-full text-left appearance-none  relative block p-1.5 text-cendiatra-gris-3  rounded-lg focus:outline-none   ">
                  {isNaN(totalServiciosRef.current)
                    ? '$'
                    : aplicarFormatoMoneda(totalServiciosRef.current)}
                </span>
              </div>
              <BotonConImagen
                ancho={'w-7% flex justify-center items-center'}
                imagen={BotonAgregar}
                textoAlternativo={'Agregar'}
                funcionAEjecutar={agregarServicioFactura}
                estilosImagen={'w-8 h-8'}
              />
              <div className="w-full flex justify-center items-center mt-1">
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={'nombreServicio'}
                  tipoError={'pattern'}
                  mensaje={TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
                />
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-start items-center mt-5 text-sm text-left normal-case">
              <div className="w-22% pl-2">Cantidad</div>
              <div className="w-22% pl-6">Servicio</div>
              <div className="w-22% pl-8">Valor</div>
              <div className="w-22% pl-10">Total servicios</div>
            </div>
          )}

          <span className="w-full border border-cendiatra-verde-3 mt-2"></span>

          <div className="w-full flex justify-evenly items-center my-4 flex-wrap">
            {serviciosFactura.map((servicio) => (
              <div className="w-full flex justify-center items-center my-1">
                <span className="w-22% text-left mt-2 text-cendiatra-gris-1">
                  {servicio.cantidad}
                </span>
                <span className="w-22% text-left mt-2 text-cendiatra-gris-1">
                  {servicio.nombreServicio}
                </span>
                <span className="w-22% text-left mt-2 pl-4 text-cendiatra-gris-1">
                  {aplicarFormatoMoneda(servicio.valorServicio)}
                </span>
                <span className="w-22% text-left mt-2 pl-4 text-cendiatra-gris-1">
                  {aplicarFormatoMoneda(servicio.totalServicios)}
                </span>
                <div className="w-7%">
                  <BotonConImagen
                    ancho={`w-full ${
                      valoresFormulario ? 'hidden' : 'flex'
                    } justify-center items-center`}
                    imagen={BotonQuitar}
                    textoAlternativo={'Agregar'}
                    funcionAEjecutar={() => eliminarServicios(servicio.id)}
                    estilosImagen={'w-8 h-8'}
                  />
                </div>
              </div>
            ))}
            <div className="w-full flex justify-end items-center text-cendiatra mt-10 mr-5 capitalize text-lg font-bold">
              Total {aplicarFormatoMoneda(valorTotal)}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-evenly items-center mt-20">
          <BotonSimple
            texto={'Regresar'}
            estilosBoton={`hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra`}
            funcion={() => funcionCerrar(valorFactura.id)}
            deshabilitado={false}
          />
          {!valoresFormulario ? (
            <BotonSimple
              texto={'Guardar'}
              estilosBoton={`hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center ${
                !formularioTieneErrores || serviciosFactura?.length === 0
                  ? 'bg-cendiatra-gris-2 pointer-events-none'
                  : 'bg-btnBg'
              } bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra`}
              funcion={() => guardarInformacionDatosServicios()}
              deshabilitado={
                !formularioTieneErrores || serviciosFactura?.length === 0
              }
            />
          ) : null}
        </div>
      </div>
      {mostrarErrorValoresDiferentes ? (
        <div className={`w-full flex justify-center items-center flex-wrap`}>
          <span className="w-full text-center text-2xl font-bold text-cendiatra-rojo-1 mb-10">
            Error de datos
          </span>
          <span className="w-full text-center text-lg font-bold text-cendiatra-gris-2 mb-10 normal-case">
            El valor de los servicios no corresponde con el pago
          </span>
          <BotonSimple
            texto={'Regresar'}
            estilosBoton={`hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra`}
            funcion={() => setMostrarErrorValoresDiferentes(false)}
            deshabilitado={false}
          />
        </div>
      ) : null}
    </>
  )
}

export default FormularioDatosDeLosServicios
