import React, {useState, useEffect, useContext, useCallback} from 'react'
import {ContextoEmpresa} from '../../contexto'
import {
  ObtenerCiudadPorId,
  ObtenerTodasCiudades,
  ObtenerDepartamentoPorId,
  ObtenerTodosDepartamentos,
  AgregarEmpresasACache,
  ObtenerActividadEconomicaPorId,
} from '../../cache/servicios-cache'

import {
  ExportarEmpresas,
  ObtenerEmpresaPorIdDB,
  ObtenerProximoCodigoInterno,
} from '../../microservicios'
import {BotonesFormularioPasos, ExportarCSV, BotonSimple} from '../Botones'
import {Input} from '../inputs'
import {encabezadosExportarEmpresas} from '../CSV/EncabezadosArchivos'
import {TablaConPaginacionYFiltros, EncabezadoFondo} from '../Tablas'
import moment from 'moment'
import {validarTipoFacturacion, validarFecha} from '../../utilidades'
import {
  obtenerUsuarioB2cPorGuid,
  obtenerUsuarioB2cPorEmpresaId,
} from '../../cache/servicios-cache/UsuariosPortal'
import {UseObtenerRolValido} from '../../utilidades/funcionesComunes'
import {ObtenerEmpresasPag} from '../../microservicios'

export default function FormularioSeleccionEmpresa({numPag}) {
  const cambiarTextoColumnaPorRol = UseObtenerRolValido()

  const filtrosIniciales = {
    tamano: 10,
    pagina: 1,
    filtros: {
      nombreEmpresa: '',
      nit: '',
    },
  }
  const [dataFiltros, setDataFiltros] = useState(filtrosIniciales)
  const [actualizarInformacion, setActualizarInformacion] = useState(true)

  const [listaEmpresas, setListaEmpresas] = useState({
    empresas: [],
    datosPaginacion: {},
  })

  const columnasTablaEmpresas = React.useMemo(() => [
    {
      Header: 'id',
      accessor: 'id',
      className: 'hidden',
      headerStyle: 'hidden',
      disableFilters: true,
    },
    {
      Header: () => <EncabezadoFondo titulo={'NIT'} paddingFondoClaro={''} />,
      accessor: 'nit',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Razón Social'} paddingFondoClaro={''} />
      ),
      accessor: 'razonSocial',
      className: 'w-3/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Activa o Inactiva'} paddingFondoClaro={''} />
      ),
      accessor: 'activa',
      className: 'w-3/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Ejecutivo Comercial'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'ejecutivoComercial',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
  ])

  const contextoEmpresa = useContext(ContextoEmpresa)

  const obtenerInformacionInicial = useCallback(async () => {
    await AgregarEmpresasACache()
  }, [])

  useEffect(() => {
    const obtenerEmpresas = async () => {
      const payload = {
        tamano: dataFiltros.tamano,
        pagina: dataFiltros.pagina,
        filtros: {
          nombreEmpresa: dataFiltros.filtros.nombreEmpresa ?? '',
          nit: dataFiltros.filtros.nit ?? '',
        },
      }
      try {
        let empresasAMostrar = await ObtenerEmpresasPag(payload)
        if (empresasAMostrar?.data) {
          setListaEmpresas({
            empresas: mapListaEmpresas(empresasAMostrar?.data?.datos),
            datosPaginacion: empresasAMostrar?.data?.paginacion,
          })
        }
      } catch (error) {
        setListaEmpresas({
          empresas: [],
          datosPaginacion: {},
        })
        console.log(error)
      }
    }
    obtenerEmpresas()
  }, [actualizarInformacion])

  const mapListaEmpresas = (empresas) => {
    const empresasMapeadas = empresas.map((empresa) => ({
      id: empresa.id,
      nit: empresa.nit,
      razonSocial: empresa.nombre,
      activa: empresa.estaActiva == true ? 'ACTIVA' : 'INACTIVA',
      ejecutivoComercial: empresa.ejecutivoComercial,
    }))

    return empresasMapeadas
  }

  useEffect(() => {
    if (contextoEmpresa.numeroPagina == numPag) {
      obtenerInformacionInicial().catch(console.error)
      contextoEmpresa.setformularioActualTieneErrores(true)
    }
  }, [contextoEmpresa.numeroPagina])

  const validarCodigoInterno = async (codigoInterno) => {
    if (codigoInterno) {
      return codigoInterno
    }

    try {
      const respuesta = await ObtenerProximoCodigoInterno('empresa')
      if (respuesta.status === 200) {
        return respuesta?.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const obtenerInformacionB2C = async (usuario, empresaId) => {
    if (usuario) {
      const datosB2c = await obtenerUsuarioB2cPorGuid(usuario)
      if (datosB2c) {
        return datosB2c
      }
    }
    if (empresaId) {
      const datosEmpresas = await obtenerUsuarioB2cPorEmpresaId(empresaId)
      return datosEmpresas
    }
    return {}
  }

  const empresaSeleccionada = async (empresaSeleccionada) => {
    if (
      empresaSeleccionada &&
      empresaSeleccionada.original &&
      empresaSeleccionada.original.id !== contextoEmpresa.idEmpresa
    ) {
      await ObtenerEmpresaPorIdDB(empresaSeleccionada.original.id).then(
        async (res) => {
          const actividadEconomicaId =
            !!res.data?.facturacionEmpresa.actividadEconomicaId

          contextoEmpresa.setIdEmpresa(res.data.id)
          let ciudad =
            parseInt(res?.data?.ciudad) !== 0
              ? await ObtenerCiudadPorId(res.data.ciudad)
              : ''
          let departamento =
            ciudad !== ''
              ? await ObtenerDepartamentoPorId(ciudad[0]?.DepartamentoId)
              : ''
          let fechaCorteEditable
          if (res.data.facturacionEmpresa.fechaCorte != '0001-01-01T00:00:00') {
            fechaCorteEditable = moment(
              res.data.facturacionEmpresa.fechaCorte
            ).format('YYYY-MM-DD')
          }
          contextoEmpresa.setformularioDatos({
            nit: res.data.nit ?? '',
            verificacionNit: res.data.verificacionNit ?? '',
            razonSocial: res.data.nombre ?? '',
            nombreEmpresaHC: res.data.nombreEmpresaHC ?? '',
            direccion: res.data.direccion ?? '',
            pais: 1,
            departamento: departamento?.Nombre ?? '',
            ciudad: ciudad[0]?.Nombre ?? '',
            telefono: res.data.telefono ?? '',
            correo: res.data.correo ?? '',
          })

          contextoEmpresa.setUltimoNitEmpresa(res.data?.nit ?? 0)

          const respuestaActividadEconomicaId =
            res.data.facturacionEmpresa.actividadEconomicaId
          const actividadEconomicaIdValidacion =
            respuestaActividadEconomicaId !== 0
              ? respuestaActividadEconomicaId
              : null

          if (!actividadEconomicaIdValidacion) {
            contextoEmpresa.setActividadEconomicaSeleccionada({
              filtro: '',
              id: '',
              nombre: '',
            })
          }

          contextoEmpresa.setformularioFacturacion({
            factura: res.data.facturacionEmpresa?.razonSocial ?? '',
            RUT53: res.data.facturacionEmpresa?.rut53 ?? '',
            RUT54: res.data.facturacionEmpresa?.rut54 ?? '',
            codigoPostal: res.data.facturacionEmpresa?.codigoPostal ?? 0,
            actividadEconomica:
              res.data.facturacionEmpresa?.actividadEconomica ?? '',
            actividadEconomicaId: actividadEconomicaIdValidacion,
            matriculaMercantil:
              res.data.facturacionEmpresa?.matriculaMercantil ?? '',
            correoFacturacion:
              res.data.facturacionEmpresa?.correoFacturacion ?? '',
            valorContrato: res.data.facturacionEmpresa?.valorContrato ?? '',
            condicionesPago: res.data.facturacionEmpresa?.condicionPago ?? '',
            descuento: res.data.facturacionEmpresa?.descuento ?? '',
            impuestos: res.data.facturacionEmpresa?.impuesto ?? '',
            fechaCorte: fechaCorteEditable ?? '',
            tipoFacturacion: validarTipoFacturacion(
              res.data.facturacionEmpresa?.tipoFacturacion
            ),
            fechaCorte: validarFecha(res.data.facturacionEmpresa?.fechaCorte)
              ? moment(res.data.facturacionEmpresa?.fechaCorte).format(
                  'YYYY-MM-DD'
                )
              : '',
            observacionesFacturacion:
              res.data.facturacionEmpresa?.observaciones ?? '',
            nombreContacto: res.data.facturacionEmpresa?.nombreContacto ?? '',
            correoContacto: res.data.facturacionEmpresa?.correoContacto ?? '',
            telefonoContacto:
              res.data.facturacionEmpresa?.telefonoContacto ?? '',
            condicionesPrefactura:
              res.data.facturacionEmpresa?.condicionesPrefactura ?? null,
            modalidadFacturacion:
              res.data.facturacionEmpresa?.modalidadFacturacion ?? null,
            prefactura: res.data.facturacionEmpresa?.prefactura ?? false,
            licitacion: res.data.facturacionEmpresa?.licitacion ?? false,
          })

          if (actividadEconomicaId) {
            contextoEmpresa.setActividadEconomicaSeleccionada(
              await Promise.resolve(
                ObtenerActividadEconomicaPorId(
                  res.data?.facturacionEmpresa.actividadEconomicaId
                ).then((res) => {
                  return {
                    filtro: res.Nombre,
                    id: res.Id,
                    nombre: res.Nombre,
                  }
                })
              )
            )
          }

          contextoEmpresa.setCodigoInternoEmpresa(
            await validarCodigoInterno(res.data.codigoInterno)
          )
          contextoEmpresa.setformularioAdicional({
            motivoDesactivacion: res.data.motivoDesactivacion,
            ejecutivoComercial: res.data.ejecutivoComercial,
            observaciones: res.data.observaciones,
            estaActiva: res.data.estaActiva,
          })

          const datosB2c = await obtenerInformacionB2C(
            res.data?.usuarioB2cEmpresa?.usuarioB2cId,
            res.data?.usuarioB2cEmpresa?.empresaId ?? res?.data?.id
          )

          contextoEmpresa.setFormularioUsuarioB2c({
            cargo: datosB2c?.Cargo ?? '',
            nombres: datosB2c?.Nombres ?? '',
            correo: datosB2c?.Correo ?? '',
            userPrincipalName: datosB2c?.UserPrincipalName ?? '',
          })

          contextoEmpresa.setActualizar(!contextoEmpresa.actualizar)
          contextoEmpresa.setformularioActualTieneErrores(false)
          contextoEmpresa.setLogoEmpresa({
            nombre: AsginarNombreImagen(res.data.logoUrl),
            url: res.data.logoUrl,
          })
        }
      )
    }
  }

  const AsginarNombreImagen = (url) => {
    if (url) {
      let partes = url.split('/')
      let nombre = partes[partes.length - 1]
      return nombre
    } else {
      return 'Seleccionar archivo'
    }
  }

  const manejarObtenerDatosExportar = async () => {
    let empresas = await ExportarEmpresas()
    let ciudades = await ObtenerTodasCiudades()
    let departamentos = await ObtenerTodosDepartamentos()

    return empresas.data.map((empresa) => ({
      ...empresa,
      ciudad:
        ciudades.find((x) => x.Id.toString() === empresa.ciudad)?.Nombre ?? '',
      departamento:
        departamentos.find(
          (d) =>
            d.Id.toString() ===
              ciudades.find((x) => x.Id.toString() === empresa.ciudad)
                ?.DepartamentoId ?? ''
        )?.Nombre ?? '',
      pais: 'COLOMBIA',
    }))
  }

  useEffect(() => {
    contextoEmpresa.setIdEmpresa(null)
  }, [])

  const manejarCambioEnFiltro = (e) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: e?.target?.value,
        },
      }
    })
  }
  const filtrarInformacion = () => {
    setActualizarInformacion(!actualizarInformacion)
  }

  const limpiarFiltros = () => {
    setDataFiltros(filtrosIniciales)
    setActualizarInformacion(!actualizarInformacion)
  }
  const manejarCambioDePagina = (numeroPagina) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: numeroPagina,
      }
    })
    setActualizarInformacion(!actualizarInformacion)
  }

  return (
    <div
      className={`rounded-md ${
        contextoEmpresa.numeroPagina === 1 ? '' : 'hidden'
      } -space-y-px  flex-wrap items-center w-full flex justify-center`}
    >
      <div className="rounded-md -space-y-px flex justify-evenly flex-nowrap w-10/12 items-center mb-10">
        <div className="w-7/12 flex justify-evenly items-center ">
          <Input
            funcion={null}
            estilosContenedor={'w-5/12'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'number'}
            placeholder={'INGRESE CÓDIGO'}
            titulo={'NIT'}
            onChange={(e) => manejarCambioEnFiltro(e)}
            nombre={'nit'}
            valor={dataFiltros.filtros.nit}
          />

          <Input
            funcion={null}
            estilosContenedor={'w-5/12'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'text'}
            placeholder={'DESCRIPCIÓN'}
            titulo={'Razón social'}
            onChange={(e) => manejarCambioEnFiltro(e)}
            nombre={'nombreEmpresa'}
            valor={dataFiltros.filtros.nombreEmpresa}
          />
        </div>
        <div className="w-3/12 flex justify-center items-center ">
          <BotonSimple
            texto={'Buscar'}
            estilosBoton={`w-32 h-10 text-white bg-cover bg-center bg-no-repeat rounded-lg text-14px m-2 bg-btnBg mt-8`}
            funcion={filtrarInformacion}
          />
          <BotonSimple
            texto={'Limpiar'}
            estilosBoton={`w-32 h-10 text-white bg-cover bg-center bg-no-repeat rounded-lg text-14px m-2 bg-btnBg mt-8`}
            funcion={limpiarFiltros}
          />
        </div>
        <div className="w-2/12 flex justify-center flex-wrap">
          <div className="w-full flex justify-center items-center pt-10">
            <ExportarCSV
              encabezados={encabezadosExportarEmpresas}
              obtenerDatos={manejarObtenerDatosExportar}
              nombreArchivo="Lista_De_Empresas.csv"
            ></ExportarCSV>
          </div>
          <div className="w-full text-center -mt-2">
            <span className="text-gray-400 text-xs">
              Exportar listado empresas
            </span>
          </div>
        </div>
      </div>

      <TablaConPaginacionYFiltros
        columns={columnasTablaEmpresas}
        data={listaEmpresas?.empresas}
        filasPorPagina={10}
        funcionFilaSeleccionada={empresaSeleccionada}
        parametrosCheckbox={{
          titulo: cambiarTextoColumnaPorRol
            ? 'Seleccione para ver'
            : 'Seleccione para modificar',
          estilosColumna: 'w-2/12',
          estilosEncabezado:
            'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
          estilosCelda:
            'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
        }}
        paginacionBackend={true}
        parametrosPaginacionConBackEnd={listaEmpresas?.datosPaginacion}
        maximoDePaginasparaMostrar={10}
        funcionCambiarPagina={manejarCambioDePagina}
      />
      <div className="pt-6 w-full flex justify-center items-center">
        <BotonesFormularioPasos contexto={contextoEmpresa} />
      </div>
    </div>
  )
}
