import {Controller, useForm} from 'react-hook-form'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {
  AgregarEmpresasACache,
  ObtenerEmpresasPorNitONombreFiltro,
} from '../../cache/servicios-cache'
import {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import {Input} from '../inputs'
import {BotonSimple} from '../Botones'
import {RUTAS_APP, TIPO_EXAMEN} from '../../constantes'
import {useObtenerInfoUsuario} from '../../utilidades'
import {ContextApplication} from '../../contexto'
import {useHistory} from 'react-router-dom'
import {CrearSolicitudReporte} from '../../microservicios/Reporte'

const InformeCondicionesSalud = () => {
  const {
    trigger,
    setValue,
    clearErrors,
    reset,
    setError,
    control,
    formState: {errors},
  } = useForm({
    defaultValues: {
      empresaNit: '',
      fechaInicio: '',
      fechaFinal: '',
    },
    mode: 'onChange',
  })

  const empresaSeleccionadaPorDefecto = {
    empresaPadreId: '',
    id: '',
    nombre: '',
    nit: '',
    telefono: '',
    direccion: '',
    observaciones: '',
    tipoFacturacion: '',
    correo: '',
    filtro: '',
  }

  const validacionFechaMaxima = moment().format('YYYY-MM-DD')

  const contextoAplicacion = useContext(ContextApplication)
  const history = useHistory()

  const [empresasPadre, setEmpresasPadre] = useState([])
  const [empresaPadreSeleccionada, setEmpresaPadreSeleccionada] = useState(
    empresaSeleccionadaPorDefecto
  )
  const [fechaMinima, setFechaMinima] = useState('')
  const [fechaMaxima, setFechaMaxima] = useState(validacionFechaMaxima)
  const [estaSeleccionado, setEstaSeleccionado] = useState(true)

  const [tipoExamenes, setTipoExamenes] = useState(TIPO_EXAMEN)

  const [activarErrores, setAtivarErrores] = useState(true)

  const valoresPorDefecto = {
    empresaNit: '',
    fechaInicio: '',
    fechaFinal: '',
  }

  useEffect(() => {
    const fetchData = async () => {
      await AgregarEmpresasACache()
      trigger()
    }
    fetchData()
  }, [])

  useEffect(() => {
    trigger()
  }, [activarErrores])

  const validarFechas = (valor, fechaInicio) => {
    if (fechaInicio) {
      setValue('fechaInicio', valor)
      setFechaMinima(valor)
    } else {
      setValue('fechaFinal', valor)
      setFechaMaxima(valor)
    }
    trigger('fechaInicio' | 'fechaFinal')
  }

  const manejarEmpresaCambiada = async (valor, datosEmpresa) => {
    setEmpresaPadreSeleccionada({
      ...empresaPadreSeleccionada,
      nombre: valor,
    })
    if (valor.length >= 3) {
      setEmpresasPadre(await ObtenerEmpresasPorNitONombreFiltro(valor))
    } else {
      setEmpresasPadre([])
    }
    if (datosEmpresa) {
      setValue('empresaNit', valor)
      clearErrors('empresaNit')
      setEmpresaPadreSeleccionada(datosEmpresa)
    }
  }

  const obtenerFechaActual = moment().format('DD/MM/YYYY')

  const usuario = useObtenerInfoUsuario()

  const enviarSolicitud = async () => {
    const payload = {
      NombreReporte: 'Reporte Informe Condiciones de Salud',
      CorreoSolicitante: usuario.correo,
      NombreSolicitante: usuario.nombre,
      Parametros: {
        SedeId: null,
        EmpresaId: empresaPadreSeleccionada.id,
        FechaDesde: fechaMinima,
        FechaHasta: fechaMaxima,
        ParametrosAdicionales: {
          ...tipoExamenesSeleccionados,
        },
      },
    }

    try {
      await CrearSolicitudReporte(payload)
      modalSolicitudEnviada()
    } catch (error) {
      console.log(error)
    }
  }

  const tipoExamenesSeleccionados = tipoExamenes.reduce((obj, tipo, i) => {
    obj[Object.keys(tipo)[1]] = tipo.seleccionado
    return obj
  }, {})

  useEffect(() => {
    const seleccionado = tipoExamenes.some((tipo) => tipo.seleccionado)
    setEstaSeleccionado(seleccionado)
  }, [tipoExamenes])

  const modalSolicitudEnviada = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'El reporte será enviado a su correo',
      botones: [
        {
          nombre: 'Nuevo reporte',
          click: manejarCerrarModal,
        },
        {
          nombre: 'Finalizar',
          click: irAlInicio,
        },
      ],
    })
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    limpiarCampos()
  }

  const limpiarCampos = () => {
    reset(valoresPorDefecto)
    setTipoExamenes(TIPO_EXAMEN)
    setEmpresaPadreSeleccionada(empresaSeleccionadaPorDefecto)
    setFechaMinima('')
    setFechaMaxima(validacionFechaMaxima)
    setAtivarErrores((prev) => !prev)
  }

  const irAlInicio = () => {
    manejarCerrarModal()
    history.push(RUTAS_APP.MAIN)
  }

  return (
    <div>
      <div className="w-full flex justify-start">
        <p className="font-bold text-cendiatra">
          FECHA Y HORA DE SOLICITUD:{' '}
          <span className="font-normal"> {obtenerFechaActual} </span>
        </p>
      </div>

      <div className="w-full flex flex-wrap mt-10 gap-5">
        <div className="w-1/5">
          <Controller
            name="empresaNit"
            control={control}
            rules={{
              required: true,
            }}
            render={() => (
              <>
                <CampoFiltrarEspañol
                  titulo={'Empresa y/o (NIT)*'}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) => element === 'empresaNit'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : `appearance-none rounded relative block w-full  p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`
                  }
                  tipo={'text'}
                  placeholder={'Autocompletar'}
                  valorDelCampoFiltro={empresaPadreSeleccionada.nombre}
                  informacionFiltrada={empresasPadre}
                  handleChange={(e) => manejarEmpresaCambiada(e.target.value)}
                  handleOptionChange={(e) =>
                    manejarEmpresaCambiada(e.filtro, e)
                  }
                  desactivarOtroFiltro={false}
                />
              </>
            )}
          />
        </div>
        <div className="w-1/5">
          <Controller
            name="fechaInicio"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {value}}) => (
              <Input
                estilosContenedor="w-full my-5px"
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'fechaInicio'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                mostrarErrores={true}
                tipo={'date'}
                titulo={'Fecha de inicio*'}
                valor={value}
                fechaMaxima={fechaMaxima}
                onChange={(e) => validarFechas(e.target.value, true)}
              />
            )}
          />
        </div>
        <div className="w-1/5 my-5px">
          <Controller
            name="fechaFinal"
            control={control}
            rules={{
              required: true,
            }}
            render={({field: {value}}) => (
              <Input
                estilosContenedor="w-full"
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'fechaFinal'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'date'}
                titulo={'Fecha de fin*'}
                valor={value}
                fechaMinima={fechaMinima}
                fechaMaxima={validacionFechaMaxima}
                onChange={(e) => validarFechas(e.target.value)}
              />
            )}
          />
        </div>
      </div>

      <div className="w-full flex flex-wrap mt-10">
        <p className="text-cendiatra">Tipo de exámen</p>
      </div>

      <div>
        <div className="w-10/12 flex mt-5 gap-10">
          {tipoExamenes.map((examen, key) => (
            <div
              key={key}
              className=" flex justify-start items-center bg-white mb-2"
            >
              <input
                type="checkbox"
                className="checkboxEspecial personalizado -mt-2"
                onChange={(e) => {
                  setTipoExamenes(
                    tipoExamenes.map((tipo) =>
                      tipo.id === examen.id
                        ? {...tipo, seleccionado: !tipo.seleccionado}
                        : tipo
                    )
                  )
                }}
                checked={examen.seleccionado}
              />
              <span className="ml-5 text-cendiatra-gris-1">
                {examen[Object.keys(examen)[1]]}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex justify-center mt-10">
        <BotonSimple
          texto={'Enviar solicitud'}
          estilosBoton={`w-32 h-10 text-white ${
            !Object.keys(errors).length && estaSeleccionado
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={() => enviarSolicitud()}
          tipoDeBoton={'submit'}
        />
      </div>
    </div>
  )
}

export default InformeCondicionesSalud
