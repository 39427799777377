import React, {useState} from 'react'
import {regexTextoCaracteresBasicos} from '../constantes'

const CampoFiltrarEspañol = ({
  titulo,
  estilosPersonalizados,
  textoTitulo,
  estilosInput,
  placeholder,
  tipo,
  valorDelCampoFiltro,
  desactivarOtroFiltro,
  informacionFiltrada,
  handleChange,
  handleOptionChange,
  id,
  validarRegex,
  contexto,
  numeroPagina,
  requerido,
  colorTextoLabel = 'text-cendiatra-verde-2',
  tamanoTextoLabel = 'text-13px',
  regex = regexTextoCaracteresBasicos,
  mensajeErrorRegex = 'Se permiten estos caracteres: + ( ) [ ] , / - . ',
  estilosOpcion = '',
  readOnly = false,
}) => {
  const [enfocado, setEnfocado] = useState(false)

  const manejarEnfocado = () => {
    setEnfocado(true)
  }

  const manejarDesenfocado = (e) => {
    setEnfocado(false)
  }

  var errorRegex = regex.test(valorDelCampoFiltro) ? null : mensajeErrorRegex

  var validacionRegex = id + 'regex'

  if (contexto && validarRegex && contexto.numeroPagina === numeroPagina) {
    if (
      regex.test(valorDelCampoFiltro) &&
      valorDelCampoFiltro &&
      contexto.errores.includes(validacionRegex)
    ) {
      contexto.setErrores(contexto.errores.filter((x) => x != validacionRegex))
    }
    if (
      (!regex.test(valorDelCampoFiltro) ||
        (requerido && !valorDelCampoFiltro)) &&
      !contexto.errores.includes(validacionRegex)
    ) {
      contexto.setErrores([...contexto.errores, validacionRegex])
    }
  }

  return (
    <div
      className={` flex ${estilosPersonalizados} justify-center items-center flex-wrap my-5px`}
    >
      <span className={`flex w-full text-cendiatra-semaforo-rojo text-13px`}>
        {contexto && errorRegex !== null && errorRegex}
      </span>
      <label
        className={`flex ${
          textoTitulo
            ? textoTitulo
            : `${tamanoTextoLabel} leading-15px font-medium`
        } ${colorTextoLabel} w-full ${tamanoTextoLabel} leading-15px my-5px`}
      >
        {titulo}
      </label>
      <input
        className={`appearance-none border ${estilosInput} relative block w-full px-3 py-1.5 placeholder-cendiatra-gris-placeholder text-cendiatra-3 rounded-lg focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm`}
        placeholder={placeholder}
        type={tipo}
        autoComplete="off"
        onChange={(e) => handleChange(e)}
        onFocus={manejarEnfocado}
        onBlur={manejarDesenfocado}
        value={valorDelCampoFiltro}
        disabled={desactivarOtroFiltro != '' ? true : false}
        id={id ? id : null}
        readOnly={readOnly}
      />
      <div className="relative w-full -mt-5 z-50">
        {enfocado && informacionFiltrada && informacionFiltrada.length > 0 && (
          <div className="bg-white border border-cendiatra w-full absolute overflow-y-auto max-h-96 break-words">
            {informacionFiltrada.map((elemento) => (
              <div
                className="bg-gray-100 hover:bg-gray-300 text-center"
                onMouseDown={() => handleOptionChange(elemento)}
                key={elemento.id}
                tabIndex="0"
              >
                <span className={`${estilosOpcion} uppercase cursor-pointer`}>
                  {elemento.filtro}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default CampoFiltrarEspañol
