import {useContext, useEffect, useState} from 'react'
import {
  FormularioBusquedaEmpresa,
  FormularioReadOnlyEmpresa,
  TablaUltimosMovimientos,
} from '../../componentes/Saldo'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL, TIPOS_FACTURACION} from '../../constantes'
import {
  ObtenerEmpresaPorIdDB,
  ObtenerUltimosMovimientosPorEmpresaId,
} from '../../microservicios'
import {ContextApplication} from '../../contexto'
import { AgregarEmpresasACache } from '../../cache/servicios-cache'

export default function VerSaldoDelCliente() {
  const contextoApp = useContext(ContextApplication)
  const [informacionEmpresa, setInformacionEmpresa] = useState({})
  const [informacionMovimientos, setInformacionMovimientos] = useState({})

  useEffect(() => {
    const obtenerInformacionInicial = async () =>{
      await AgregarEmpresasACache()
    }
    obtenerInformacionInicial()
  }, [])

  const obtenerInformacionEmpresa = async (empresaId) => {
    if (!empresaId) return

    try {
      const empresa = await ObtenerEmpresaPorIdDB(empresaId)
      const ultimosMovimientos = await ObtenerUltimosMovimientosPorEmpresaId(
        empresaId
      )

      setInformacionMovimientos(
        ultimosMovimientos.status === 200 ? ultimosMovimientos.data : {}
      )
      setInformacionEmpresa(empresa?.status === 200 ? empresa?.data ?? {} : {})
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!Object.keys(informacionEmpresa).length) return

    if (
      informacionEmpresa?.facturacionEmpresa?.tipoFacturacion?.toUpperCase() ===
      TIPOS_FACTURACION.CREDITO
    ) {
      contextoApp.setModal({
        abierto: true,
        contenido: (
          <div className="text-center w-full">
            <h3 className="text-cendiatra text-xl font-bold">
              {informacionEmpresa?.nombre ?? ''}
            </h3>
            <p className="text-cendiatra text-xl font-bold">
              ES CLIENTE {TIPOS_FACTURACION.CREDITO}
            </p>
            <br />
            <p className="normal-case pt-4 text-xl">
              Por favor, recuérdele al cliente que debe seguir <br /> la forma
              de pago establecida en su convenio.
            </p>
          </div>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: () => {
              contextoApp.setModal({
                abierto: false,
                titulo: '',
                contenido: '',
                botones: [],
              })
            },
          },
        ],
      })
    }
  }, [informacionEmpresa])

  return (
    <ContenedorPagina
      tituloPagina={
        TEXTO_VISUAL.BARRA_NAVEGACION.SALDO.VER_SALDO_PORTAL_CLIENTE
      }
    >
      <div className="w-full grid grid-cols-1 gap-7">
        <div>
          <FormularioBusquedaEmpresa
            fnBusqueda={obtenerInformacionEmpresa}
            fnResetear={() => {
              setInformacionEmpresa([])
              setInformacionMovimientos({})
            }}
          />
        </div>
        <div>
          <FormularioReadOnlyEmpresa empresa={informacionEmpresa} />
        </div>
        <div className="flex justify-center">
          <div className="w-9/12">
            <TablaUltimosMovimientos
              informacionEmpresa={{
                tipoConvenio:
                  informacionEmpresa?.facturacionEmpresa?.tipoFacturacion ?? '',
                saldoActual: informacionMovimientos?.saldo ?? 0,
                movimientos: informacionMovimientos?.movimientos ?? [],
              }}
            />
          </div>
        </div>
      </div>
    </ContenedorPagina>
  )
}
