import React, {useEffect, useState} from 'react'

const ListaDesplegable = ({
  id,
  onChange,
  estilosContenedor,
  estilosLista,
  titulo,
  opciones,
  valor,
  deshabilitado,
  funcion,
  onBlur,
  deshabilitarElegir,
  placeholder,
  contexto,
  numeroPagina,
  colorTextoLabel = 'text-cendiatra-verde-2',
  tamanoTextoLabel = 'text-13px',
  nombre,
  readOnly = false,
  mostrarTextBlanco = false,
  esRegenerar,
}) => {
  const valorSeleccionado = opciones.find((val) => {
    if (esRegenerar && val.Id === parseInt(valor)) {
      localStorage.setItem(
        'valorDefectoRegenerar',
        JSON.stringify({
          value: val.Id,
          label: val.Nombre,
        })
      )
    }
    return val.Id === valor
  })

  useEffect(() => {
    if (contexto && contexto.numeroPagina === numeroPagina && !deshabilitado) {
      if (valor != '' && valor != -1 && contexto.errores.includes(id)) {
        contexto.setErrores(contexto.errores.filter((x) => x != id))
      }
      if ((valor == '' || valor == -1) && !contexto.errores.includes(id)) {
        contexto.setErrores([...contexto.errores, id])
      }
    }
  }, [contexto])

  const [cambiarColorInput, setCambiarColorInput] = useState(false)

  const validarSeleccion = (valor) => {
    return setCambiarColorInput(Boolean(valor))
  }

  useEffect(() => {
    validarSeleccion(valor)
  }, [valor])

  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      {titulo ? (
        <label
          className={`block ${tamanoTextoLabel} leading-15px ${colorTextoLabel} my-5px`}
        >
          {titulo}
        </label>
      ) : null}
      <select
        className={` ${estilosLista} ${
          !mostrarTextBlanco
            ? cambiarColorInput
              ? 'text-cendiatra-gris-3'
              : 'text-cendiatra-gris-placeholder'
            : ''
        }`}
        onChange={funcion ? (e) => funcion(e) : onChange}
        onBlur={onBlur}
        onClick={(e) => validarSeleccion(e.target.value)}
        value={valorSeleccionado?.Id ?? valor}
        disabled={deshabilitado}
        name={nombre}
        readOnly={readOnly}
      >
        {!deshabilitarElegir && placeholder ? (
          <option key="-1" value="" disabled={readOnly}>
            {' '}
            {placeholder}{' '}
          </option>
        ) : null}
        {!deshabilitarElegir && !placeholder ? (
          <option key="-1" value="" disabled={readOnly}>
            {' '}
            ELEGIR{' '}
          </option>
        ) : null}
        {opciones.map((item) => (
          <option
            key={item.Id}
            value={item.Id}
            className="text-cendiatra-gris-3"
            disabled={readOnly}
          >
            {item.Nombre}
          </option>
        ))}
      </select>
    </div>
  )
}

export default ListaDesplegable
