import React, {useEffect, useState} from 'react'
import ElementoTabla from '../ElementoTabla'
import Fila from '../Fila'
import {
  ObtenerTodosLosAliados,
  ObtenerInformacionCiudadPorId,
} from '../../cache/servicios-cache'
import {ROLES_APP} from '../../constantes'

const TablaAliados = ({contexto, numeroPagina, claseBloqueo}) => {
  const [seleccionarTodo, setSeleccionarTodo] = useState(false)

  useEffect(() => {
    ObtenerTodosLosAliados().then((res) => {
      const nuevoArray = []
      res.forEach(async (elemento) => {
        let objeto = {
          Id: elemento.Id,
          Nombre:
            (await ObtenerInformacionCiudadPorId(elemento.CiudadId)).Nombre +
            ' - ' +
            elemento.Nombre,
          Activo: false,
        }
        nuevoArray.push(objeto)
      })

      contexto.setAliadosSeleccionados(nuevoArray)
    })
  }, [])

  useEffect(() => {
    if (contexto?.aliadosSeleccionados) {
      const todosLosAliadosSeleccionados = contexto.aliadosSeleccionados?.find(
        (aliado) => !aliado?.Activo
      )
      setSeleccionarTodo(Boolean(!todosLosAliadosSeleccionados))
    }
  }, [contexto.numeroPagina, contexto?.aliadosSeleccionados])

  useEffect(() => {
    if (numeroPagina === contexto.numeroPagina) {
      contexto.setformularioActualTieneErrores(false)
    }
  }, [contexto.numeroPagina])

  const opcionSeleccionada = (item, seleccionado) => {
    const nuevoEstado = contexto.aliadosSeleccionados.map((elemento) => {
      if (elemento.Id === item.Id) {
        return {
          ...elemento,
          Activo: seleccionado,
        }
      }
      return elemento
    })

    if (
      nuevoEstado.filter((x) => x.Activo).length !==
        contexto.aliadosIniciales.length &&
      Boolean(contexto.portafolioSeleccionadoId)
    ) {
      contexto?.setSeguroDeCambio(true)
      return
    }

    contexto.setAliadosSeleccionados(nuevoEstado)
  }

  const checkearTodo = (seleccionado) => {
    const nuevoEstado = contexto.aliadosSeleccionados.map((elemento) => {
      return {
        ...elemento,
        Activo: seleccionado,
      }
    })
    contexto.setAliadosSeleccionados(nuevoEstado)
    setSeleccionarTodo(seleccionado)
  }

  return (
    <div
      className={`${claseBloqueo} w-full rounded flex justify-center items-center flex-wrap`}
    >
      <label className="text-cendiatra text-lg border-b-gray-100 my-5 text-center">
        ALIADOS
        <br />
        CIUDAD - NOMBRE IPS
      </label>
      <div className="w-full flex justify-end items-center -mt-24">
        <div className="w-8/12 text-center"></div>
        <div className="w-4/12 flex justify-center items-center "></div>
        <div className="w-8/12 text-center"></div>
        <div className="w-4/12 flex justify-center items-center flex-wrap">
          <span className="w-full text-center text-gray-500">
            {' '}
            Seleccionar todo
          </span>
          <div className="w-full flex justify-center items-center mt-3">
            <input
              type="checkbox"
              className="checkboxEspecial personalizado -mt-2 "
              onChange={(e) => checkearTodo(e.target.checked)}
              checked={seleccionarTodo}
            />
          </div>
        </div>
      </div>
      <hr className="w-11/12" />
      <Fila
        ancho={'w-full'}
        alineacionHorizontal={'justify-center'}
        flexWrap={'flex-wrap'}
      >
        <ElementoTabla
          datos={contexto.aliadosSeleccionados}
          funcion={opcionSeleccionada}
        />
      </Fila>
    </div>
  )
}

export default TablaAliados
