import React, {useEffect, useContext, useState, useRef} from 'react'
import {useForm, Controller, set} from 'react-hook-form'
import {BotonOpcion, BotonesFormularioPasos} from '../Botones'
import {ContextApplication, ContextoEmpresa} from '../../contexto'
import {
  regexCorreoElectronico,
  regexNombreEmpresaHC,
  regexTextoCaracteresBasicos,
  regexValorContrato,
  regexPorcentaje,
  regexSoloLetras,
  regexSoloNumeros,
} from '../../constantes'
import {Input, ErroresInput, InputPorcentaje, ListaDesplegable} from '../inputs'
import {
  LISTA_TIPOS_FACTURACION,
  MODOS_FACTURACION,
  MODALIDAD_FACTURACION,
} from '../../constantes/tiposFacturacion'
import clsx from 'clsx'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {ObtenerActiviadEconomicaPorNombreOCodigo} from '../../cache/servicios-cache'

export default function FormularioFacturacion({
  numPag,
  modoSoloLectura = false,
  claseBloqueo,
}) {
  const contextoEmpresa = useContext(ContextoEmpresa)
  const contextoAplicacion = useContext(ContextApplication)

  const {
    reset,
    getValues,
    setValue,
    trigger,
    watch,
    clearErrors,
    setError,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {
      activateCompany: true,
      prefactura: false,
    },
    mode: 'onChange',
  })
  const seleccionPrefactura = watch('prefactura')

  const [actividadEconomicaListado, setActividadEconomicaListado] = useState([])

  const [hayActividadEconomica, setHayActividadEconomica] = useState(false)

  const manejarCambioActividadEconomica = async (
    valor,
    datosActividadEconomica
  ) => {
    contextoEmpresa.setActividadEconomicaSeleccionada({
      ...contextoEmpresa.actividadEconomicaSeleccionada,
      nombre: valor,
    })

    if (valor.length >= 3) {
      contextoAplicacion.setIsLoading(true)
      setActividadEconomicaListado(
        await ObtenerActiviadEconomicaPorNombreOCodigo(valor)
      )
      contextoAplicacion.setIsLoading(false)
    } else {
      setActividadEconomicaListado([])
    }
    if (datosActividadEconomica) {
      setValue('actividadEconomicaId', datosActividadEconomica)
      clearErrors('actividadEconomicaId')
      contextoEmpresa.setActividadEconomicaSeleccionada(datosActividadEconomica)
    } else {
      setValue('actividadEconomicaId', datosActividadEconomica)
      setError('actividadEconomicaId', {type: 'require', message: ''})
      contextoEmpresa.setActividadEconomicaSeleccionada({id: ''})
    }
  }

  useEffect(() => {
    trigger('condicionesPrefactura' | 'modalidadPago')
    if (numPag === contextoEmpresa.numeroPagina) {
      setValue('condicionesPrefactura', '')
    }
  }, [seleccionPrefactura, trigger])

  useEffect(() => {
    if (
      parseInt(contextoEmpresa?.formularioFacturacion?.tipoFacturacion) ===
        MODOS_FACTURACION.CONTADO &&
      numPag === contextoEmpresa.numeroPagina
    ) {
      setValue('condicionesPago', '')
      setValue('fechaCorte', '')
      setValue('prefactura', false)
      setValue('condicionesPrefactura', '')
      setValue('modalidadFacturacion', '')
      setValue('licitacion', false)
    }
    trigger()
  }, [contextoEmpresa?.formularioFacturacion?.tipoFacturacion])

  useEffect(() => {
    if (!contextoEmpresa.formularioFacturacion?.actividadEconomicaId?.id) {
      setHayActividadEconomica(true)
    }
  }, [
    contextoEmpresa.formularioFacturacion?.actividadEconomicaId?.id,
    contextoEmpresa.formularioFacturacion,
  ])

  useEffect(() => {
    if (numPag === contextoEmpresa.numeroPagina) {
      trigger()
      contextoEmpresa.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length !== 0 || !hayActividadEconomica
      )
    }
  }, [
    contextoEmpresa.actualizar,
    isValid,
    contextoEmpresa.numeroPagina,
    hayActividadEconomica,
    contextoEmpresa.formularioFacturacion?.actividadEconomicaId?.id,
  ])

  useEffect(() => {
    reset(contextoEmpresa.formularioFacturacion)
    trigger()
  }, [contextoEmpresa.actualizar])

  useEffect(() => {
    const subscription = watch(() => {
      contextoEmpresa.setformularioFacturacion(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const establecerEstilosCamposPreFactura = (seleccionPrefactura, errors) => {
    const estilosBase =
      'appearance-none rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm'
    const bordeConError = 'border-cendiatra-rojo-1'
    const bordeNormal = 'border-cendiatra'
    const deshabilitarCampo =
      'w-full text-white bg-grayColor pointer-events-none'

    const tieneError = Object.keys(errors).includes('condicionesPrefactura')

    if (seleccionPrefactura) {
      return `${estilosBase} ${tieneError ? bordeConError : bordeNormal}`
    } else {
      return `${estilosBase} ${deshabilitarCampo}`
    }
  }

  return (
    <form className={`w-full flex flex-wrap justify-center my-5`}>
      <div className={clsx('w-full', claseBloqueo)}>
        <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
          <div className="w-full flex justify-start flex-wrap">
            <Controller
              name="factura"
              control={control}
              rules={{
                required: false,
                pattern: regexNombreEmpresaHC,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'factura'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Razón social factura'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'factura'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten estos caracteres + ( ) [ ] , / - . &'}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-between flex-wrap items-center my-2">
          <div className="w-6/12 flex justify-start flex-wrap">
            <Controller
              name="RUT53"
              control={control}
              rules={{
                required: true,
                pattern: /^[a-zA-Z0-9-]+$/,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find((element) => element === 'RUT53')
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'RUT casilla 53*'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'RUT53'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten letras, números y guiones'}
                  />
                </>
              )}
            />
          </div>
          <div className="w-custom flex justify-start flex-wrap">
            <Controller
              name="RUT54"
              control={control}
              rules={{
                required: false,
                pattern: /^[a-zA-Z0-9-]+$/,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find((element) => element === 'RUT54')
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'RUT casilla 54'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'RUT54'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten letras, números y guiones'}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-between items-center my-2 gap-2">
          <div className="w-4/12 flex justify-start flex-wrap">
            <Controller
              name="codigoPostal"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'codigoPostal'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'number'}
                    placeholder={'Descripción'}
                    titulo={'Código postal*'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                </>
              )}
            />
          </div>
          <div className="w-4/12 flex justify-start flex-wrap">
            <Controller
              name="actividadEconomica"
              control={control}
              rules={{
                required: false,
                pattern: regexSoloLetras,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'actividadEconomica'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border bg-cendiatra-gris-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border bg-gris border-cendiatra text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Actividad económica obsoleta'}
                    valor={value}
                    readOnly={modoSoloLectura}
                    deshabilitado={true}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'actividadEconomica'}
                    tipoError={'pattern'}
                    mensaje={
                      'Los caracteres especiales y números no estan permitidos'
                    }
                  />
                </>
              )}
            />
          </div>
          <div className="w-4/12 flex justify-start flex-wrap">
            <Controller
              name="actividadEconomicaId"
              control={control}
              rules={{
                required: hayActividadEconomica,
              }}
              render={({field: {value}}) => (
                <CampoFiltrarEspañol
                  estilosPersonalizados={'w-full'}
                  titulo={'Actividad económica principal*'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full  p-1.5 border  text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    Object.keys(errors).find(
                      (element) => element === 'actividadEconomicaId'
                    )
                      ? 'focus:border-cendiatra-rojo-1 border-cendiatra-rojo-1'
                      : 'focus:border-cendiatra border-cendiatra '
                  )}
                  tipo={'text'}
                  placeholder={'Autocompletar'}
                  valorDelCampoFiltro={
                    contextoEmpresa.actividadEconomicaSeleccionada?.nombre
                  }
                  readOnly={modoSoloLectura}
                  informacionFiltrada={actividadEconomicaListado}
                  desactivarOtroFiltro={false}
                  handleChange={(e) =>
                    manejarCambioActividadEconomica(e.target.value)
                  }
                  handleOptionChange={(e) => {
                    manejarCambioActividadEconomica(e.filtro, e)
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-between items-center my-2 gap-2">
          <div className="w-9/12 flex justify-start flex-wrap">
            <Controller
              name="correoFacturacion"
              control={control}
              rules={{
                required: true,
                pattern: regexCorreoElectronico,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'correoFacturacion'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Correo para facturación*'}
                    valor={value}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'correoFacturacion'}
                    tipoError={'pattern'}
                    mensaje={'Ej: ejemplo@ejemplo.com'}
                  />
                </>
              )}
            />
          </div>
          <div className="w-3/12 flex justify-start flex-wrap">
            <Controller
              name="matriculaMercantil"
              control={control}
              rules={{
                required: true,
                pattern: regexTextoCaracteresBasicos,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'matriculaMercantil'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Matrícula mercantil*'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'matriculaMercantil'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten estos caracteres + ( ) [ ] , / - .'}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center mt-4">
          <div className="w-full flex justify-center items-center bg-cendiatra text-white rounded-lg">
            <label className="block text-lg font-medium text-white">
              CONDICIONES DE FACTURACIÓN
            </label>
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-between flex-wrap items-center mt-5">
          <div className="w-full flex justify-between items-center ">
            <div className="w-3/12 flex justify-center flex-wrap ">
              <Controller
                name="tipoFacturacion"
                control={control}
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}}) => (
                  <ListaDesplegable
                    funcion={null}
                    estilosContenedor={'w-full'}
                    estilosLista={
                      Object.keys(errors).find(
                        (element) => element === 'tipoFacturacion'
                      )
                        ? 'appearance-none rounded w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    onChange={onChange}
                    titulo={'Tipo de facturación*'}
                    opciones={LISTA_TIPOS_FACTURACION}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                )}
              />
            </div>

            <div className="w-4/12 flex justify-start flex-wrap">
              <Controller
                name="valorContrato"
                control={control}
                rules={{
                  required: false,
                  pattern: regexValorContrato,
                }}
                render={({field: {onChange, value}}) => (
                  <>
                    <Input
                      onChange={onChange}
                      estilosContenedor={'w-full'}
                      estilosInput={
                        Object.keys(errors).find(
                          (element) => element === 'valorContrato'
                        )
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      tipo={'text'}
                      placeholder={'Descripción'}
                      titulo={'Valor contrato'}
                      valor={value}
                      readOnly={modoSoloLectura}
                    />
                    <ErroresInput
                      erroresFormulario={errors}
                      nombre={'valorContrato'}
                      tipoError={'pattern'}
                      mensaje={"Se permiten números y estos caracteres , . '"}
                    />
                  </>
                )}
              />
            </div>
            <div className="w-4/12 flex justify-start flex-wrap">
              <Controller
                name="descuento"
                control={control}
                rules={{
                  required: false,
                  pattern: regexPorcentaje,
                }}
                render={({field: {onChange, value}}) => (
                  <>
                    <InputPorcentaje
                      estilosContenedor={'w-full'}
                      estilosInput={
                        Object.keys(errors).find(
                          (element) => element === 'descuento'
                        )
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      placeholder={'DESCRIPCIÓN'}
                      titulo={'Descuento'}
                      deshabilitarEscalaDecimal={true}
                      onValueChange={(e) => onChange(e.floatValue)}
                      valor={value}
                      onBlur={(e) =>
                        e.target.value === '%' ? setValue('Descuento', 0) : null
                      }
                      readOnly={modoSoloLectura}
                    />
                    <ErroresInput
                      erroresFormulario={errors}
                      nombre={'descuento'}
                      tipoError={'pattern'}
                      mensaje={
                        'Solo se permiten números y el símbolo de porcentaje(%)'
                      }
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>
        {parseInt(contextoEmpresa?.formularioFacturacion?.tipoFacturacion) ===
        MODOS_FACTURACION.CREDITO ? (
          <div className="w-full flex justify-between items-center mt-1">
            <div className="w-3/12 flex justify-start flex-wrap">
              <Controller
                name="condicionesPago"
                control={control}
                rules={{
                  required: true,
                  pattern: regexSoloNumeros,
                }}
                render={({field: {onChange, value}}) => (
                  <>
                    <Input
                      onChange={onChange}
                      estilosContenedor={'w-full'}
                      estilosInput={
                        Object.keys(errors).find(
                          (element) => element === 'condicionesPago'
                        )
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      tipo={'number'}
                      placeholder={'Descripción'}
                      titulo={'Condiciones de pago (días) *'}
                      valor={value}
                      readOnly={modoSoloLectura}
                    />
                    <ErroresInput
                      erroresFormulario={errors}
                      nombre={'condicionesPago'}
                      tipoError={'pattern'}
                      mensaje={'Solo está permitido ingresar números'}
                    />
                  </>
                )}
              />
            </div>
            <div className="w-3/12 flex justify-start flex-wrap ">
              <Controller
                name="fechaCorte"
                control={control}
                rules={{
                  required: false,
                }}
                render={({field: {onChange, value}}) => (
                  <>
                    <Input
                      onChange={onChange}
                      estilosContenedor={'w-full'}
                      estilosInput={
                        Object.keys(errors).find(
                          (element) => element === 'fechaCorte'
                        )
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      tipo={'date'}
                      placeholder={'Descripción'}
                      titulo={'Dia de corte de facturación'}
                      valor={value}
                      readOnly={modoSoloLectura}
                    />
                  </>
                )}
              />
            </div>
            <div
              className={clsx('w-1/12 flex items-center justify-center mt-1', {
                'pointer-events-none': modoSoloLectura,
              })}
            >
              <Controller
                name="prefactura"
                control={control}
                render={({field: {onChange, value}}) => (
                  <BotonOpcion
                    titulo={'Prefactura'}
                    estaActivo={value ?? false}
                    centrarLabel={true}
                    funcionClick={() => onChange(Boolean(!value))}
                  />
                )}
              />
            </div>
            <div className="w-3/12 flex justify-start flex-wrap " tabindex="-1">
              <Controller
                name="condicionesPrefactura"
                control={control}
                rules={{
                  required: seleccionPrefactura,
                  min: {value: 1, message: 'El valor mínimo es 1 '},
                  max: {
                    value: 10,
                    message: 'No se permite un tiempo superior a 10 días',
                  },
                }}
                render={({field: {onChange, value}, fieldState}) => (
                  <>
                    <Input
                      onChange={onChange}
                      estilosContenedor={'w-full'}
                      estilosInput={establecerEstilosCamposPreFactura(
                        seleccionPrefactura,
                        errors
                      )}
                      tabIndex={'-1'}
                      tipo={'number'}
                      placeholder={'Descripción'}
                      titulo={`Condiciones de prefactura (días) ${
                        seleccionPrefactura ? '*' : ''
                      }`}
                      valor={value ?? ''}
                      readOnly={modoSoloLectura}
                    />
                    <ErroresInput
                      erroresFormulario={errors}
                      nombre={'condicionesPrefactura'}
                      tipoError={'pattern'}
                      mensaje={errors?.condicionesPrefactura?.message ?? ''}
                      mostrarPorTipo={!(fieldState.error?.type !== 'required')}
                    />
                  </>
                )}
              />
            </div>
          </div>
        ) : null}

        <div className="rounded-md -space-y-px flex justify-between flex-wrap items-center">
          <div
            className={`${
              parseInt(
                contextoEmpresa?.formularioFacturacion?.tipoFacturacion
              ) === MODOS_FACTURACION.CREDITO
                ? 'w-5/12'
                : 'w-full '
            } flex justify-start flex-wrap`}
          >
            <Controller
              name="observacionesFacturacion"
              control={control}
              rules={{
                required: false,
                pattern: regexTextoCaracteresBasicos,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'observacionesFacturacion'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Observaciones de facturación'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'observacionesFacturacion'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten estos caracteres + ( ) [ ] , / - .'}
                  />
                </>
              )}
            />
          </div>
          {parseInt(contextoEmpresa?.formularioFacturacion?.tipoFacturacion) ===
          MODOS_FACTURACION.CREDITO ? (
            <>
              <div className="w-5/12 flex justify-center flex-wrap ">
                <Controller
                  name="modalidadFacturacion"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({field: {onChange, value}}) => (
                    <ListaDesplegable
                      funcion={null}
                      estilosContenedor={'w-full'}
                      estilosLista={
                        Object.keys(errors).find(
                          (element) => element === 'modalidadFacturacion'
                        )
                          ? 'appearance-none rounded w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      onChange={onChange}
                      titulo={'Modalidad de facturación*'}
                      opciones={MODALIDAD_FACTURACION}
                      valor={value ?? ''}
                      readOnly={modoSoloLectura}
                    />
                  )}
                />
              </div>
              <div
                className={clsx(
                  'w-1/12 flex items-center justify-center mt-1',
                  {
                    'pointer-events-none': modoSoloLectura,
                  }
                )}
              >
                <Controller
                  name="licitacion"
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <BotonOpcion
                      titulo={'Licitación'}
                      estaActivo={value}
                      centrarLabel={true}
                      funcionClick={() => onChange(Boolean(!value))}
                    />
                  )}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center mt-5">
          <div className="w-full flex justify-center items-center bg-cendiatra text-white rounded-lg">
            <label className="block text-lg font-medium text-white">
              CONTACTO ÁREA CONTABLE
            </label>
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-between flex-wrap items-center mt-5">
          <div className="w-4/12 flex justify-start flex-wrap">
            <Controller
              name="nombreContacto"
              control={control}
              rules={{
                required: false,
                pattern: regexSoloLetras,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'nombreContacto'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Nombre'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'nombreContacto'}
                    tipoError={'pattern'}
                    mensaje={
                      'Los caracteres especiales y números no estan permitidos'
                    }
                  />
                </>
              )}
            />
          </div>
          <div className="w-4/12 flex justify-start flex-wrap">
            <Controller
              name="correoContacto"
              control={control}
              rules={{
                required: false,
                pattern: regexCorreoElectronico,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'correoContacto'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Correo'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'correoContacto'}
                    tipoError={'pattern'}
                    mensaje={'Ej: ejemplo@ejemplo.com'}
                  />
                </>
              )}
            />
          </div>
          <div className="w-3/12 flex justify-start flex-wrap">
            <Controller
              name="telefonoContacto"
              control={control}
              rules={{
                required: false,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'telefonoContacto'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'number'}
                    placeholder={'Descripción'}
                    titulo={'Teléfono'}
                    valor={value}
                    readOnly={modoSoloLectura}
                  />
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="pt-6 w-full flex justify-center items-center">
        <BotonesFormularioPasos contexto={contextoEmpresa} />
      </div>
    </form>
  )
}
