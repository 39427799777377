import React, {useEffect, useState, useContext} from 'react'
import Fila from '../Fila'
import moment from 'moment'
import {ContextApplication, ContextoTarifario} from '../../contexto'
import {ObtenerTarifarioPorEmpresaIdBD} from '../../microservicios'
import CeldaConTooltip from '../CeldaConTooltip'
import {
  AgregarEmpresasACache,
  AgregarTarifariosACache,
  ObtenerTodasLasSedes,
  ObtenerTodosLosAliados,
  ObtenerTodosLosServicios,
  ObtenerTodosLosPaquetes,
  ObtenerInformacionCiudadPorId,
  ObtenerEmpresaPorId,
} from '../../cache/servicios-cache'

import {EncabezadoFondo, TablaConPaginacionYFiltros} from '../Tablas'
import {BotonSimple} from '../Botones'
import {Input} from '../inputs'
import {FachadaObtenerTarifarios} from '../../microservicios'

const SeleccionTarifario = () => {
  const filtrosIniciales = {
    tamano: 10,
    pagina: 1,
    filtros: {
      fechaInicial: '',
      fechaFinal: '',
      nombreEmpresa: '',
      nombreTarifario: '',
      nit: '',
    },
  }

  const [dataFiltros, setDataFiltros] = useState(filtrosIniciales)

  const [listaTarifarios, setListaTarifarios] = useState({
    tarifarios: [],
    datosPaginacion: {},
  })

  const [actualizarInformacion, setActualizarInformacion] = useState(true)

  const contextoApp = useContext(ContextApplication)
  const contextoTarifario = useContext(ContextoTarifario)

  useEffect(() => {
    const obtenerInformacionInicial = async () =>{
      contextoApp.setIsLoading(true)
      await AgregarTarifariosACache(false)
      contextoApp.setIsLoading(false)
    }
    obtenerInformacionInicial()
  }, [])

  useEffect(() => {
    const obtenerTarifarios = async () => {
      await AgregarEmpresasACache()
      const payload = {
        tamano: dataFiltros.tamano,
        pagina: dataFiltros.pagina,
        filtros: {
          fechaInicial: dataFiltros.filtros.fechaInicial || null,
          fechaFinal: dataFiltros.filtros.fechaFinal || null,
          nombreEmpresa: dataFiltros.filtros.nombreEmpresa,
          nombreTarifario: dataFiltros.filtros.nombreTarifario,
          nit: dataFiltros.filtros.nit,
        },
      }
      let tarifariosAMostrar = await FachadaObtenerTarifarios(payload)
      if (tarifariosAMostrar?.data) {
        return setListaTarifarios({
          tarifarios: await Promise.all(
            tarifariosAMostrar?.data?.datos.map(async (elemento) => {
              return {
                ...elemento,
                nombreEmpresa: (await ObtenerEmpresaPorId(elemento?.empresaId))
                  ?.Nombre,
                id: trasnformarNumero(elemento.id),
                fechaInicial: elemento?.fechaInicial
                  ? moment(elemento?.fechaInicial)?.format('YYYY-MM-DD')
                  : null,
                fechaFinal: elemento?.fechaFinal
                  ? moment(elemento?.fechaFinal)?.format('YYYY-MM-DD')
                  : null,
              }
            })
          ),
          datosPaginacion: tarifariosAMostrar?.data?.paginacion,
        })
      } else {
        setListaTarifarios({
          tarifarios: [],
          datosPaginacion: {},
        })
      }
    }
    obtenerTarifarios()
  }, [actualizarInformacion])

  const manejarCambioEnFiltro = (e) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: e?.target?.value,
        },
      }
    })
  }

  const filtrarInformacion = () => {
    setActualizarInformacion(!actualizarInformacion)
  }

  const limpiarFiltros = () => {
    setDataFiltros(filtrosIniciales)
    setActualizarInformacion(!actualizarInformacion)
  }
  const trasnformarNumero = (numero) => {
    if (parseInt(numero) < 10) {
      return `0${numero}`
    } else {
      return numero
    }
  }

  const verificarInformacionSeleccionada = (filaSeleccionada) => {
    if (filaSeleccionada) {
      obtenerDatosTarifario(filaSeleccionada?.original.id)
      contextoTarifario.setformularioActualTieneErrores(false)
    }
  }

  const obtenerDatosTarifario = async (empresaId) => {
    contextoApp.setIsLoading(true)
    const servicios = await ObtenerTodosLosServicios()
    const paquetes = await ObtenerTodosLosPaquetes()
    const sedes = await ObtenerTodasLasSedes()
    const aliados = await ObtenerTodosLosAliados()

    const obtenerArrayConDatos = (arrayServicios, arrayPaquetes) => {
      if (arrayServicios && arrayServicios.length > 0) {
        return arrayServicios
      }
      if (arrayPaquetes && arrayPaquetes.length > 0) {
        return arrayPaquetes
      }
      return []
    }

    ObtenerTarifarioPorEmpresaIdBD(empresaId).then(async (res) => {
      contextoTarifario.setFormularioEmpresa({
        id: res.data.id,
        empresaId: res.data.empresaId,
        fechaInicial: moment(res.data.fechaInicial).format('YYYY-MM-DD'),
        fechaFinal: res.data.fechaFinal
          ? moment(res.data.fechaFinal).format('YYYY-MM-DD')
          : null,
        nombre: res.data.nombre,
        
      })
      contextoTarifario.setPortafolioSeleccionadoId(res.data?.portafolioId)
      contextoTarifario.setNombreTarifarioValidacion(
        res.data.nombre.trim().toLowerCase()
      )

      if (res.data?.portafolioId) {
        contextoTarifario.setPortafolioSeleccionadoId(
          res.data?.portafolioId ?? ''
        )
      }

      const arrayObtenerSedes = obtenerArrayConDatos(
        res?.data?.servicios,
        res?.data?.paquetes
      )

      const preciosServiciosIniciales = {
        preciosSedes:
          res?.data?.servicios.flatMap((item) => item?.preciosSedes) ?? [],
        preciosAliados:
          res?.data?.servicios.flatMap((item) => item?.preciosAliados) ?? [],
      }

      const preciospaquetesIniciales = {
        preciosSedes:
          res?.data?.paquetes?.flatMap((item) => item?.preciosSedes) ?? [],
        preciosAliados:
          res?.data?.paquetes?.flatMap((item) => item?.preciosAliados) ?? [],
      }

      contextoTarifario.setPreciosServiciosIniciales(preciosServiciosIniciales)

      contextoTarifario.setPreciosPaquetesIniciales(preciospaquetesIniciales)

      contextoTarifario.setSedesIniciales(
        arrayObtenerSedes?.[0]?.preciosSedes?.map((sede) => {
          return sede?.sedeId
        }) ?? []
      )
      contextoTarifario.setAliadosIniciales(
        arrayObtenerSedes?.[0]?.preciosAliados?.map((sede) => {
          return sede?.aliadoId
        }) ?? []
      )

      const serviciosIniciales =
        res?.data?.servicios?.map((servicio) => servicio?.servicioId) ?? []
      contextoTarifario.setServiciosIniciales(serviciosIniciales)

      const paquetesIniciales =
        res?.data?.paquetes?.map((paquete) => paquete?.paqueteId) ?? []
      contextoTarifario.setPaquetesIniciales(paquetesIniciales)

      let sedesIds = []
      let aliadosIds = []
      if (res.data.paquetes.length > 0) {
        sedesIds = res.data.paquetes[0].preciosSedes?.map((x) => x.sedeId) ?? []
        aliadosIds =
          res.data.paquetes[0].preciosAliados == null
            ? []
            : res.data.paquetes[0].preciosAliados.map((x) => x.aliadoId)
      } else if (
        Array.isArray(res.data.servicios) &&
        res.data.servicios.length > 0 &&
        Array.isArray(res.data.servicios[0].preciosSedes)
      ) {
        sedesIds =
          res.data.servicios[0].preciosSedes?.map((x) => x.sedeId) ?? []
        aliadosIds =
          res.data.servicios[0].preciosAliados == null
            ? []
            : res.data.servicios[0].preciosAliados.map((x) => x.aliadoId)
      }

      contextoTarifario.setListaPaquetes(
        res.data.paquetes.map((elemento) => {
          return {
            paquete_id: elemento.paqueteId,
            puedeModificarse: elemento.puedeModificarse,
            nombre: paquetes.find((x) => x.Id == elemento.paqueteId).Nombre,
            tipoCambioSedes: null,
            tipoCambioAliados: null,
            precios_sedes:
              elemento.preciosSedes?.map((sede) => {
                return {
                  grupo: sedes.find((x) => x.Id == sede.sedeId).Grupo,
                  id: 'paquete:' + sede.sedeId,
                  nombre: sedes.find((x) => x.Id == sede.sedeId).Nombre,
                  precio: sede.precio,
                  idPrecio: sede.id,
                  nuevoPrecio: sede.precioAModificarse,
                  tipo: 'paquete',
                  idGeneral: elemento.paqueteId,
                }
              }) ?? [],
            precios_aliados:
              elemento.preciosAliados == null
                ? []
                : elemento.preciosAliados.map((aliado) => {
                    return {
                      grupo: 'ALIADOS',
                      id: 'aliado:' + aliado.aliadoId,
                      nombre: aliados.find((x) => x.Id == aliado.aliadoId)
                        .Nombre,
                      precio: aliado.precio,
                      idPrecio: aliado.id,
                      nuevoPrecio: aliado.precioAModificarse,
                      tipo: 'paquete',
                      idGeneral: elemento.paqueteId,
                    }
                  }),
          }
        })
      )

      contextoTarifario.setListaServicios(
        res.data.servicios.map((elemento) => {
          let precios_aliados = []
          let precios_sedes = []

          if (Array.isArray(elemento.preciosAliados)) {
            precios_aliados = elemento.preciosAliados.map((aliado) => {
              return {
                grupo: 'ALIADOS',
                id: 'aliado:' + aliado.aliadoId,
                nombre: aliados.find((x) => x.Id == aliado.aliadoId).Nombre,
                precio: aliado.precio,
                idPrecio: aliado.id,
                nuevoPrecio: aliado.precioAModificarse,
                tipo: 'servicio',
                idGeneral: elemento.servicioId,
              }
            })
          }

          if (Array.isArray(elemento.preciosSedes)) {
            precios_sedes = elemento.preciosSedes.map((sede) => {
              return {
                grupo: sedes.find((x) => x.Id == sede.sedeId).Grupo,
                id: 'servicio:' + sede.sedeId,
                nombre: sedes.find((x) => x.Id == sede.sedeId).Nombre,
                precio: sede.precio,
                idPrecio: sede.id,
                nuevoPrecio: sede.precioAModificarse,
                tipo: 'servicio',
                idGeneral: elemento.servicioId,
              }
            })
          }

          return {
            alias: elemento.alias,
            nombre: servicios.find((x) => x.Id == elemento.servicioId).Nombre,
            servicio_id: elemento.servicioId,
            tipoCambioSedes: null,
            tipoCambioAliados: null,
            precios_aliados,
            precios_sedes,
          }
        })
      )

      contextoTarifario.setSedesSeleccionadas(
        sedes.map((sede) => {
          return {
            Id: sede.Id,
            Nombre: sede.Nombre,
            Ciudad: sede.Ciudad,
            Grupo: sede.Grupo,
            Activo: sedesIds.find((x) => x === sede.Id) ? true : false,
          }
        })
      )
      contextoTarifario.setAliadosSeleccionados(
        await Promise.all(
          aliados.map(async (aliado) => {
            return {
              Id: aliado.Id,
              Nombre:
                (await ObtenerInformacionCiudadPorId(aliado.CiudadId)).Nombre +
                ' - ' +
                aliado.Nombre,
              Activo: aliadosIds.find((x) => x == aliado.Id) ? true : false,
            }
          })
        )
      )
      contextoTarifario.setActualizar(!contextoTarifario.actualizar)
      contextoTarifario.setFilaSeleccionada(-1)
      contextoApp.setIsLoading(false)
    })
  }

  const columns = [
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Empresa o empresa hija'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'nombreEmpresa',
      className: 'w-3/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Código del tarifario'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'id',
      className: 'w-2/12 z-0',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'Nombre del tarifario'}
          paddingFondoClaro={''}
        />
      ),
      accessor: 'nombre',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
      Cell: CeldaConTooltip,
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Fecha inicial'} paddingFondoClaro={''} />
      ),
      accessor: 'fechaInicial',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Fecha final'} paddingFondoClaro={''} />
      ),
      accessor: 'fechaFinal',
      className: 'w-2/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
  ]

  const manejarCambioDePagina = (numeroPagina) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: numeroPagina,
      }
    })
    setActualizarInformacion(!actualizarInformacion)
  }

  return (
    <div className="bg-white rounded-t-3xl rounded-b-3xl mt-5 mb-5 w-full">
      <div className="rounded-md  flex justify-evenly flex-wrap w-full mt-5 items-center">
        <Fila ancho={'w-full justify-evenly items-center mb-10'}>
          <Input
            funcion={null}
            estilosContenedor={'w-2/12'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'text'}
            placeholder={'DESCRIPCIÓN'}
            titulo={'Empresa o empresa hija'}
            onChange={(e) => manejarCambioEnFiltro(e)}
            nombre={'nombreEmpresa'}
            valor={dataFiltros.filtros.nombreEmpresa}
          />
          <Input
            funcion={null}
            estilosContenedor={'w-2/12'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'number'}
            placeholder={'INGRESE CÓDIGO'}
            titulo={'NIT'}
            onChange={(e) => manejarCambioEnFiltro(e)}
            nombre={'nit'}
            valor={dataFiltros.filtros.nit}
          />

          <Input
            funcion={null}
            estilosContenedor={'w-2/12'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'text'}
            placeholder={'DESCRIPCIÓN'}
            titulo={'Nombre tarifario'}
            onChange={(e) => manejarCambioEnFiltro(e)}
            nombre={'nombreTarifario'}
            valor={dataFiltros.filtros.nombreTarifario}
          />

          <Input
            funcion={null}
            estilosContenedor={'w-2/12'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'date'}
            placeholder={'DESCRIPCIÓN'}
            titulo={'Fecha inicial'}
            onChange={(e) => manejarCambioEnFiltro(e)}
            nombre={'fechaInicial'}
            valor={dataFiltros.filtros.fechaInicial}
          />

          <Input
            funcion={null}
            estilosContenedor={'w-2/12'}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'date'}
            placeholder={'DESCRIPCIÓN'}
            titulo={'Fecha final'}
            onChange={(e) => manejarCambioEnFiltro(e)}
            valor={dataFiltros.filtros.fechaFinal}
            nombre={'fechaFinal'}
          />
        </Fila>
        <Fila ancho={'w-full justify-end items-center mb-10 mr-10'}>
          <BotonSimple
            texto={'Buscar'}
            estilosBoton={`w-32 h-10 text-white bg-cover bg-center bg-no-repeat rounded-lg text-14px m-2 bg-btnBg`}
            funcion={filtrarInformacion}
          />
          <BotonSimple
            texto={'Limpiar'}
            estilosBoton={`w-32 h-10 text-white bg-cover bg-center bg-no-repeat rounded-lg text-14px m-2 bg-btnBg`}
            funcion={limpiarFiltros}
          />
        </Fila>
        <Fila ancho={'w-full justify-evenly items-center'} flexWrap={true}>
          <TablaConPaginacionYFiltros
            columns={columns}
            data={listaTarifarios?.tarifarios}
            filasPorPagina={10}
            funcionFilaSeleccionada={verificarInformacionSeleccionada}
            parametrosCheckbox={{
              titulo: '',
              estilosColumna: 'w-1/12',
              estilosEncabezado:
                'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
              estilosCelda:
                'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
            }}
            paginacionBackend={true}
            parametrosPaginacionConBackEnd={listaTarifarios?.datosPaginacion}
            maximoDePaginasparaMostrar={10}
            funcionCambiarPagina={manejarCambioDePagina}
          />
        </Fila>
      </div>
    </div>
  )
}

export default SeleccionTarifario
