import React from 'react'
import {NumericFormat} from 'react-number-format'

const InputPorcentaje = ({
  onValueChange,
  estilosContenedor,
  estilosInput,
  placeholder,
  titulo,
  deshabilitado,
  valor,
  onFocus,
  deshabilitarEscalaDecimal,
  onBlur,
  deshabilitarTab = false,
  readOnly = false,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      <label className="block text-13px text-cendiatra my-2px">{titulo}</label>
      <NumericFormat
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={deshabilitarEscalaDecimal ? 1 : 2}
        fixedDecimalScale={deshabilitarEscalaDecimal ? false : true}
        suffix={'%'}
        value={valor}
        defaultValue={0}
        onValueChange={(values, sourceInfo) => {
          if (sourceInfo.source == 'event') {
            onValueChange(values)
          }
        }}
        className={estilosInput}
        placeholder={placeholder}
        disabled={deshabilitado}
        allowEmptyFormatting={true}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={deshabilitarTab ? -1 : 0}
        readOnly={readOnly}
      />
    </div>
  )
}

export default InputPorcentaje
