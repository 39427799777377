import React, {useState, useEffect} from 'react'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import ExportarCSV from '../Botones/ExportarCSV'
import {
  AgregarPaquetesACache,
  ObtenerPaquetesPorCodigo,
  ObtenerTodosLosPaquetes,
  ObtenerPaquetesPorNombre,
} from '../../cache/servicios-cache'
import BotonSimple from '../Botones/BotonSimple'
import {ExportarPaquetes} from '../../microservicios'
import {TablaConPaginacionYFiltros} from '../Tablas'
import ediatarPaquete from '../../imagenes/modificar.svg'
import visualizarPaquete from '../../imagenes/ModificarOjoDesactivado.svg'
import BotonConImagen from '../BotonConImagen'
import {regexTextoCaracteresBasicos} from '../../constantes'

const SeleccionPaquete = ({
  funcionObtenerDatos = () => {},
  readOnly = false,
}) => {
  const [codigoABuscar, setCodigoABuscar] = useState('')
  const [opcionesFiltradasCodigo, setOpcionesFiltradasCodigo] = useState([])
  const [opcionesFiltradasNombre, setOpcionesFiltradasNombre] = useState([])
  const [nombreABuscar, setNombreABuscar] = useState('')
  const [todosLosPaquetes, setTodosLosPaquetes] = useState([])
  const [arrayDeDatos, setArrayDeDatos] = useState([])

  const headerDefault = [
    {label: 'Id', key: 'id'},
    {label: 'Codigo', key: 'codigoInterno'},
    {label: 'Nombre', key: 'nombre'},
    {label: 'Cuenta De Ingreso', key: 'cuentaIngreso'},
  ]
  const [arregloHeaders, setArregloHeaders] = useState(headerDefault)

  useEffect(() => {
    const obtenerDatosIniciales = async () => {
      try {
        await AgregarPaquetesACache()
        const paquetesCreados = await ObtenerTodosLosPaquetes()
        setTodosLosPaquetes(await ObtenerTodosLosPaquetes(paquetesCreados))
        setArrayDeDatos(paquetesCreados)
      } catch (error) {
        console.log(error)
      }
    }
    obtenerDatosIniciales()
  }, [])

  const filtrarCodigos = (e) => {
    setCodigoABuscar(e.target.value)

    if (e.target.value === '') {
      setNombreABuscar('')
      setCodigoABuscar('')
    }

    if (e.target.value.length >= 2) {
      setNombreABuscar('')
      ObtenerPaquetesPorCodigo(e.target.value).then((res) => {
        setOpcionesFiltradasCodigo(res)
      })
    } else {
      setNombreABuscar('')
      setOpcionesFiltradasNombre([])
      setOpcionesFiltradasCodigo([])
      setArrayDeDatos(todosLosPaquetes)
    }
  }

  const opcionSeleccionadaCodigo = (option) => {
    setCodigoABuscar(option.filtro)
    const servicioSeleccionado = todosLosPaquetes.filter(
      (element) => element.CodigoInterno === option.filtro
    )
    setArrayDeDatos(servicioSeleccionado)
  }

  const validarCaracteres = (opcion) => {
    let Validacion = new RegExp(regexTextoCaracteresBasicos, '')
    return !Validacion.test(opcion)
  }

  const filtrarNombres = (e) => {
    if (validarCaracteres(e.target.value.slice(-1))) {
      window.alert('Se permiten estos caracteres + ( ) [ ] , / - . ')
    } else {
      setNombreABuscar(e.target.value)

      if (e.target.value === '') {
        setNombreABuscar('')
        setCodigoABuscar('')
        return
      }

      if (e.target.value.length >= 2) {
        setCodigoABuscar('')

        ObtenerPaquetesPorNombre(e.target.value).then((res) => {
          setOpcionesFiltradasNombre(res)
        })
      } else {
        setCodigoABuscar('')
        setOpcionesFiltradasNombre([])
        setOpcionesFiltradasCodigo([])
        setArrayDeDatos(todosLosPaquetes)
      }
    }
  }
  const opcionSeleccionadaNombre = (opcion) => {
    setNombreABuscar(opcion.filtro)
    const selectedService = todosLosPaquetes.filter(
      (element) => element.Nombre === opcion.filtro
    )
    setArrayDeDatos(selectedService)
  }

  const resetearFiltros = () => {
    setArrayDeDatos(todosLosPaquetes)
    setNombreABuscar('')
    setCodigoABuscar('')
    setOpcionesFiltradasNombre([])
    setOpcionesFiltradasCodigo([])
  }

  const crearHeaders = (arregloDatos) => {
    const numeroMaximoDeServicios = arregloDatos.reduce((maximo, elemento) => {
      return Math.max(maximo, elemento.servicios.length)
    }, 0)

    const headersParaAgregar = []
    for (let i = 0; i < numeroMaximoDeServicios; i++) {
      headersParaAgregar.push(
        {label: `Código de servicio ${i + 1}`, key: `servicios[${i}].id`},
        {
          label: `Nombre de servicio ${i + 1}`,
          key: `servicios[${i}].nombre`,
        }
      )
    }
    setArregloHeaders(() => [...headerDefault, ...headersParaAgregar])
  }
  const manejarObtenerDatosExportar = async () => {
    try {
      const respuesta = await ExportarPaquetes()

      if (respuesta?.status === 200) {
        crearHeaders(respuesta?.data)
        return respuesta?.data
      }
    } catch (error) {
      console.log(error)
    }

    // return (await ExportarPaquetes()).data
  }

  let columns = [
    {
      Header: 'id',
      accessor: 'Id',
      className: 'hidden',
      headerStyle: 'hidden',
      disableFilters: true,
    },
    {
      Header: 'Código paquete',

      accessor: 'CodigoInterno',
      className: 'w-4/12',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-12 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center',
    },
    {
      Header: 'Nombre del paquete',
      accessor: 'Nombre',
      className: 'w-7/12',
      disableFilters: true,
      headerStyle: 'bg-cendiatra-verde-7 text-white h-12 ',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
    },
    {
      Header: '',
      id: 'boton',
      className: 'w-1/12',
      disableFilters: true,
      headerStyle: 'bg-cendiatra-verde-7 text-white h-12 rounded-r-lg pr-1',
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center ',
      Cell: (row) => (
        <BotonConImagen
          ancho={'w-10'}
          imagen={readOnly ? visualizarPaquete : ediatarPaquete}
          textoAlternativo={'Boton ver paquete'}
          funcionAEjecutar={() => funcionObtenerDatos(row?.row?.original?.Id)}
          medio={true}
        />
      ),
    },
  ]

  return (
    <div className="flex justify-center items-center flex-wrap">
      <div className="rounded-md flex justify-evenly flex-wrap w-11/12 ">
        <CampoFiltrarEspañol
          titulo={'Código paquete'}
          estilosPersonalizados={'w-4/5 md:w-4/12 m-5 '}
          estilosInput={'border-cendiatra'}
          placeholder={'(Autocompletar)'}
          tipo={'text'}
          valorDelCampoFiltro={codigoABuscar}
          desactivarOtroFiltro={''}
          informacionFiltrada={opcionesFiltradasCodigo}
          handleChange={filtrarCodigos}
          handleOptionChange={opcionSeleccionadaCodigo}
          id={1}
        />

        <CampoFiltrarEspañol
          titulo={'Nombre del paquete'}
          estilosPersonalizados={'w-4/5 md:w-4/12 m-5'}
          estilosInput={'border-cendiatra'}
          placeholder={'Descripción (Autocompletar y filtrar)'}
          tipo={'text'}
          valorDelCampoFiltro={nombreABuscar}
          desactivarOtroFiltro={''}
          informacionFiltrada={opcionesFiltradasNombre}
          handleChange={filtrarNombres}
          handleOptionChange={opcionSeleccionadaNombre}
          id={2}
        />

        <div className="w-1/12 flex justify-center items-center ">
          <BotonSimple
            texto={'Limpiar'}
            estilosBoton={
              'group relative w-20 h-9 mt-5  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra'
            }
            funcion={resetearFiltros}
            tipoDeBoton={'button'}
          />
        </div>

        <div className="w-4/5 md:w-2/12 flex justify-center flex-wrap">
          <div className="w-full flex justify-center items-center">
            <ExportarCSV
              encabezados={arregloHeaders}
              obtenerDatos={manejarObtenerDatosExportar}
              nombreArchivo="Lista_De_Paquetes.csv"
            ></ExportarCSV>
          </div>
          <div className="w-full text-center">
            <span className="text-gray-400 text-sm">
              Exportar listado paquetes
            </span>
          </div>
        </div>
      </div>
      <TablaConPaginacionYFiltros
        columns={columns}
        data={arrayDeDatos}
        estilosRow="w-full flex justify-center items-center border-b border-cendiatra-gris-3 rounded-lg py-2 mt-2"
        paginacion={true}
      />
    </div>
  )
}

export default SeleccionPaquete
