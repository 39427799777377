import React, {useEffect, useContext} from 'react'
import {ContextApplication, ContextoEmpresa} from '../../contexto'
import {useHistory, NavLink} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import FormularioAdicional from '../../componentes/Empresa/FormularioAdicional'
import FormularioFacturacion from '../../componentes/Empresa/FormularioFacturacion'
import FormularioDatosEmpresaHija from '../../componentes/Empresa/FormularioDatosEmpresaHija'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import SeleccionEmpresaPadre from '../../componentes/Empresa/SeleccionEmpresaPadre'
import {ObtenerCiudadPorNombre} from '../../cache/servicios-cache/Ciudades.js'
import {CrearEmpresaHijaBD} from '../../microservicios/Empresa'
import ConfirmacionEmpresaHijaCreadaOModificada from '../../componentes/Empresa/ConfirmacionEmpresaHijaCreadaOModificada'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina.jsx'
import {TEXTO_VISUAL} from '../../constantes/textoVisual.js'
import {transformarTipoFacturacion} from '../../utilidades/funcionesComunes.js'

const CrearEmpresaHija = ({}) => {
  const contextoApp = useContext(ContextApplication)
  const contextoEmpresa = useContext(ContextoEmpresa)

  const history = useHistory()

  const listaPestanas = [
    'Seleccionar empresa padre',
    'Datos de la empresa',
    'Facturacíon',
    'Información adicional',
  ]

  useEffect(() => {
    contextoEmpresa.setformularioDatos({})
    contextoEmpresa.setformularioFacturacion({})
    contextoEmpresa.setformularioAdicional({estaActiva: true})
    contextoEmpresa.setNumeroPagina(1)
    contextoEmpresa.setTotalPaginas(4)
    contextoEmpresa.setActualizar(!contextoEmpresa.actualizar)
    contextoEmpresa.setformularioDatos({ciudad: '', departamento: ''})

    return () => {
      contextoEmpresa.setformularioDatos({})
      contextoEmpresa.setformularioFacturacion({})
      contextoEmpresa.setformularioAdicional({estaActiva: true})
      contextoEmpresa.setFormularioUsuarioB2c({})
    }
  }, [])

  const crearEmpresaHija = async () => {
    let ciudadId = (
      await ObtenerCiudadPorNombre(contextoEmpresa.formularioDatos.ciudad)
    )?.Id
    const contenido = {
      codigoInterno: contextoEmpresa.codigoInternoHija,
      nit: contextoEmpresa.formularioDatos.nit,
      verificacionNit: contextoEmpresa.formularioDatos.verificacionNit,
      nombre: contextoEmpresa.formularioDatos.razonSocial,
      direccion: contextoEmpresa.formularioDatos.direccion,
      ciudadId: ciudadId,
      telefono: contextoEmpresa.formularioDatos.telefono,
      correo: contextoEmpresa?.formularioDatos?.correo ?? '',
      nombreEmpresaHC: contextoEmpresa.formularioDatos.nombreEmpresaHC,
      estaActiva: contextoEmpresa.formularioAdicional.estaActiva,
      motivoDesactivacion:
        contextoEmpresa.formularioAdicional.motivoDesactivacion,
      ejecutivoComercial:
        contextoEmpresa.formularioAdicional.ejecutivoComercial,
      observaciones: contextoEmpresa.formularioAdicional.observaciones,
      empresaPadreId: contextoEmpresa.idEmpresaPadre,
      logoUrl: contextoEmpresa.logoEmpresa.url,
      facturacionEmpresa: {
        razonSocial: contextoEmpresa.formularioFacturacion.factura,
        rut53: contextoEmpresa.formularioFacturacion.RUT53,
        rut54: contextoEmpresa.formularioFacturacion.RUT54,
        codigoPostal: contextoEmpresa.formularioFacturacion.codigoPostal,
        actividadEconomica:
          contextoEmpresa.formularioFacturacion.actividadEconomica,
        actividadEconomicaId:
        contextoEmpresa.actividadEconomicaSeleccionada?.id ?? '',
        matriculaMercantil:
          contextoEmpresa.formularioFacturacion.matriculaMercantil,
        correoFacturacion:
          contextoEmpresa.formularioFacturacion.correoFacturacion,
        valorContrato: contextoEmpresa.formularioFacturacion.valorContrato,
        condicionPago:
          contextoEmpresa.formularioFacturacion.condicionesPago ?? null,
        descuento: contextoEmpresa.formularioFacturacion.descuento
          ? JSON.stringify(contextoEmpresa.formularioFacturacion.descuento)
          : '',
        impuesto: '',
        fechaCorte: contextoEmpresa.formularioFacturacion.fechaCorte,
        tipoFacturacion:
          transformarTipoFacturacion(
            contextoEmpresa.formularioFacturacion?.tipoFacturacion
          ) ?? '',
        observaciones:
          contextoEmpresa.formularioFacturacion.observacionesFacturacion,
        nombreContacto: contextoEmpresa.formularioFacturacion.nombreContacto,
        correoContacto: contextoEmpresa.formularioFacturacion.correoContacto,
        telefonoContacto:
          contextoEmpresa.formularioFacturacion.telefonoContacto,
        condicionesPrefactura:
          contextoEmpresa.formularioFacturacion.condicionesPrefactura ?? null,
        modalidadFacturacion:
          contextoEmpresa.formularioFacturacion.modalidadFacturacion ?? null,
        prefactura: contextoEmpresa.formularioFacturacion.prefactura ?? false,
        licitacion: contextoEmpresa.formularioFacturacion.licitacion ?? false,
      },
    }
    await CrearEmpresaHijaBD(contenido)
      .then((res) => {
        if (res.status === 200) {
          contextoApp.setModal({
            abierto: true,
            titulo: 'EMPRESA HIJA CREADA',
            contenido: (
              <ConfirmacionEmpresaHijaCreadaOModificada
                id={res.data}
                codigoInterno={contextoEmpresa.codigoInternoHija}
                nombreEmpresa={contextoEmpresa.formularioDatos.razonSocial}
              />
            ),
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarCerrarModalConfirmacion,
              },
              {
                nombre: 'Crear Nueva',
                click: manejarCrearNueva,
              },
            ],
          })
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoApp.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  const manejarCrearNueva = () => {
    history.go(0)
  }

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.EJECUTIVO_COMERCIAL.CREAR_EMPRESA_HIJA}
    >
      <BarraEncabezadosPestana
        titulos={listaPestanas}
        contexto={contextoEmpresa}
      />
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 1 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <SeleccionEmpresaPadre numPag={1} mostrarImportar={true} />
      </div>
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 2 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <FormularioDatosEmpresaHija numPag={2} />
      </div>
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 3 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <FormularioFacturacion numPag={3} />
      </div>
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 4 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <FormularioAdicional numPag={4} funcionSubmit={crearEmpresaHija} />
      </div>
    </ContenedorPagina>
  )
}

export default CrearEmpresaHija
