export const ROLES_APP = {
  COMERCIAL: 'Comercial',
  RECEPCION: 'Recepcion',
  ADMINISTRADOR: 'administrador',
  MEDICO_OCUPACIONAL: 'medico.ocupacional',
  AUXILIAR_ENFERMERIA: 'auxiliar.enfermeria',
  ADMINISTRADOR_ALIADO: 'administrador.aliado',
  ADMINISTRADOR_CONTABLE: 'administrador.contable',
  AUXILIAR_CONTABLE: 'auxiliar.contable',
  BACTERIOLOGO: 'bacteriologo',
  FONOAUDIOLOGO: 'fonoaudiologo',
  OPTOMETRA: 'optometra',
  PSICOLOGO: 'psicologo',
  JEFE_SEDE: 'jefe.sede',
  GERENTE_GENERAL: 'gerente.general',
  EJECUTIVO_COMERCIAL: 'ejecutivo.comercial',
  FISIOTERAPEUTA: 'fisioterapeuta',
  AUXILIAR_ADMINISTRATIVO: 'auxiliar.administrativo',
  PROFESIONAL_SST: 'profesional.sst',
  GERENTE_COMERCIAL: 'gerente.comercial',
  FACTURADOR: 'facturador',
  LICITADOR: 'licitador',
  TESORERO: 'tesorero',
  AUDITOR: 'auditor',
  RECEPCION_ALIADO: 'recepcion.aliado',
  GERENTE_FINANCIERO: 'gerente.financiero',
}
