import React, {useContext, useState, useEffect, useMemo, useRef} from 'react'
import {useForm, Controller, set} from 'react-hook-form'
import {NumericFormat} from 'react-number-format'
import moment from 'moment'
import {
  Input,
  ListaDesplegable,
  InputMoneda,
  InputPorcentaje,
} from '../../inputs'
import {TablaDinamica, BotonConImagen} from '../../index'
import TablaMediosDePago from './TablaMediosDePago'
import {CeldaConBoton, CeldaPrecio} from '../index'
import {CeldaFormatoDinero} from '../../Celdas'
import BotonQuitar from '../../../imagenes/removeButton.png'
import BotonAgregar from '../../../imagenes/addButton.png'
import IconoAlerta from '../../../imagenes/alerta.svg'
import {
  ObtenerMediosDePago,
  ObtenerMediosDePagoPorId,
} from '../../../cache/servicios-cache'
import {
  ObtenerAtencionConTiquetera,
  ObtenerInformacionCuentaPorEmpresaId,
  ObtenerAtencionConBono,
  // ObtenerInformacionCuentaPorUsuarioId,
} from '../../../microservicios'
import {
  NOMBRES_MEDIOS_PAGO,
  TIPO_ATENCION_REGISTRO,
  TIPOS_FACTURACION,
  voucherRegex,
} from '../../../constantes'
import {ContextApplication, ContextoRegistro} from '../../../contexto'
import {TIPOS_MEDIOS_DE_PAGO} from '../../../constantes/MediosDePago'
import {obtenerNombreAmbiente} from '../../../config'

let fechaActual = moment().format('YYYY-MM-DD')
const Facturacion = ({numeroPagina, modoEdicion}) => {
  const {
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {
      tipoFacturacion: 'CONTADO',
      fechaComprobante: fechaActual,
    },
    mode: 'onChange',
  })

  const mediosSaldoPortalCliente = [
    NOMBRES_MEDIOS_PAGO.USO_DE_SALDO_PORTAL_CLIENTE,
    NOMBRES_MEDIOS_PAGO.USO_DE_SALDO_CUENTA_DEL_CLIENTE,
  ]

  const contextoAplicacion = useContext(ContextApplication)
  const medioDePago = watch('medioPago')

  const [mediosDePago, setMediosDePago] = useState([])
  const [saldoDisponible, setSaldoDisponible] = useState(0)
  const [saldoDisponibleParticular, setSaldoDisponibleParticular] = useState(0)

  const [mostrarSaldo, setMostrarSaldo] = useState(false)
  const [nombreAmbiente, setNombreAmbiente] = useState('')

  useEffect(() => {
    ;(async () => {
      const ambiente = await obtenerNombreAmbiente()

      if (!!ambiente) {
        setNombreAmbiente(ambiente)
      } else {
        console.error('No se encontró nombre de ambiente')
      }
    })()
  }, [])

  const nombreMedioDePago = useMemo(
    () =>
      mediosDePago?.find(
        (medio) => medio?.Id === parseInt(medioDePago ? medioDePago : 0)
      )?.Nombre,
    [medioDePago]
  )

  const contextoRegistro = useContext(ContextoRegistro)

  //Saldo particular
  /*
  useEffect(() => {
    const obtenerInformacionCuentaPorUsuarioId = async () => {
      if (contextoRegistro?.usuarioExiste && contextoRegistro?.usuarioId) {
        try {
          const respuesta = await ObtenerInformacionCuentaPorUsuarioId(
            contextoRegistro.usuarioId
          )
          setSaldoDisponibleParticular(respuesta?.data?.saldo)
          setSaldoDisponible(0)
        } catch (error) {
          console.error(error)
        }
      } else {
        setSaldoDisponibleParticular(0)
      }
    }

    obtenerInformacionCuentaPorUsuarioId()
  }, [
    contextoRegistro?.usuarioId,
    contextoRegistro?.usuarioExiste,
    contextoRegistro?.informacionPersonal?.tipoAtencion,
  ])

  useEffect(() => {
    const saldoParticular = watch('saldoParticular')
    setSaldoDisponibleParticular(saldoParticular)
  }, [watch('saldoParticular')])*/

  useEffect(() => {
    const establecerMediosDePago = async () => {
      const mediosDePago = await ObtenerMediosDePago()

      let listaMediosPago = mediosDePago

      setMediosDePago(listaMediosPago)

      if (contextoRegistro.descontarSaldoPortal) {
        const idUsoDeSaldoPortal =
          TIPOS_MEDIOS_DE_PAGO?.USO_DE_SALDO_CUENTA_CLIENTE_NUMERICO
            .VALOR_NUMERICO
        const medioPago = await ObtenerMediosDePagoPorId(
          JSON.parse(idUsoDeSaldoPortal)
        )

        let id = moment().unix()
        contextoRegistro.setMedioDePagoParaAgregar({
          ...contextoRegistro.medioDePagoParaAgregar,
          id: id,
          idMedioPago: idUsoDeSaldoPortal,
          medioDePago: medioPago.Nombre,
        })
        setValue(
          'medioPago',
          TIPOS_MEDIOS_DE_PAGO?.USO_DE_SALDO_CUENTA_CLIENTE_NUMERICO
            .VALOR_NUMERICO_STRING
        )
        setMostrarSaldo(true)
      }
    }

    establecerMediosDePago()
  }, [contextoRegistro.descontarSaldoPortal])

  useEffect(() => {
    if (
      !contextoRegistro.descontarSaldoPortal &&
      contextoRegistro.numeroPagina === numeroPagina
    ) {
      contextoRegistro.setMediosDePagoSeleccionados([])
      contextoRegistro.setMedioDePagoParaAgregar({
        id: '',
        idMedioPago: '',
        medioDePago: '',
        valor: '',
        voucher: '',
      })
      setValue('medioPago', '')
      clearErrors('voucher')
    }
  }, [contextoRegistro.examenesSeleccionados])

  useEffect(() => {
    if (numeroPagina == contextoRegistro.numeroPagina) {
      trigger()
      contextoRegistro.setformularioActualTieneErrores(!isValid)
    }
    contextoRegistro.setInformacionFacturacion(getValues())
  }, [
    isValid,
    contextoRegistro.numeroPagina,
    contextoRegistro.valorTotalAtencion,
    contextoRegistro.sumatoriaMediosDePago,
    contextoRegistro.empresaSeleccionada,
  ])

  const columns = React.useMemo(() => [
    {
      Header: 'No.',
      id: 'row',
      className: 'w-2/12',
      Cell: (row) => {
        return <div>{row.row.index + 1}</div>
      },
    },

    {
      Header: 'CÓDIGO DE SERVICIO Y/O PAQUETE',
      accessor: 'codigo',
      className: 'w-3/12 ',
    },
    {
      Header: 'NOMBRE DEL SERVICIO Y/O PAQUETE',
      accessor: 'nombre',
      className: 'w-3/12 uppercase',
    },
    {
      Header: 'CANTIDAD',
      accessor: 'cantidad',
      className: 'w-2/12',
    },
    {
      Header: 'VALOR',
      accessor: 'precio',
      className: 'w-2/12',
      Cell: (row) => <CeldaPrecio precio={row.row.original.precio} />,
    },
  ])

  const borrarMedioDePago = (id) => {
    if (contextoRegistro.atencionCreada) {
      return
    }

    clearErrors('tiquetera')
    clearErrors('bono')
    contextoRegistro.setformularioActualTieneErrores(false)
    const medioAEliminar = contextoRegistro?.mediosDePagoSeleccionados?.find(
      (x) => x.id === id
    )

    if (mediosSaldoPortalCliente.includes(medioAEliminar?.medioDePago)) {
      setSaldoDisponible((prv) => prv + parseFloat(medioAEliminar?.valor ?? 0))
      setSaldoDisponibleParticular(
        (prv) => prv + parseFloat(medioAEliminar?.valor ?? 0)
      )
    }

    let nuevaLista = contextoRegistro.mediosDePagoSeleccionados.filter((x) => {
      return x.id !== id
    })
    contextoRegistro.setMediosDePagoSeleccionados(nuevaLista)
  }

  const columnsPago = React.useMemo(() => [
    {
      Header: '',
      accessor: 'medioDePago',
      className: 'w-3/12',
    },
    {
      Header: '',
      accessor: 'valor',
      className: 'w-3/12 ',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={
            'text-center appearance-none rounded relative block w-full pointer-events-none  p-1.5 text-cendiatra-gris-1 text-13px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
          }
          valor={row.row.original.valor}
        />
      ),
    },
    {
      Header: '',
      accessor: 'voucher',
      className: 'w-4/12',
    },
    {
      id: 'CeldaConBoton',
      className: 'w-1/12 bg-cendiatra-verde-7',
      Cell: (row) => (
        <CeldaConBoton
          funcion={() => borrarMedioDePago(row.row.original.id)}
          imagen={BotonQuitar}
        />
      ),
    },
  ])

  const manejarMedioDepago = async (e) => {
    if (e.target.value == '') {
      contextoRegistro.setMedioDePagoParaAgregar({
        id: '',
        idMedioPago: '',
        medioDePago: '',
        valor: '',
        voucher: '',
      })
      setValue('medioPago', '')
      clearErrors('voucher')
      setMostrarSaldo(false)
      return
    }

    if (
      e.target.value ===
      TIPOS_MEDIOS_DE_PAGO?.USO_DE_SALDO_CUENTA_CLIENTE_NUMERICO
        .VALOR_NUMERICO_STRING
    ) {
      setMostrarSaldo(true)
    } else {
      setMostrarSaldo(false)
    }
    setValue('medioPago', e.target.value)
    const medioPago = await ObtenerMediosDePagoPorId(JSON.parse(e.target.value))
    if (medioPago.Nombre != 'TIQUETERA' || medioPago.Nombre != 'BONO') {
      clearErrors('voucher')
    } else {
      setError('voucher', {
        type: 'required',
        message: '',
      })
      setValue('voucher', '')
    }
    let id = moment().unix()
    contextoRegistro.setMedioDePagoParaAgregar((prev) => {
      return {
        ...prev,
        id: id,
        idMedioPago: e.target.value,
        medioDePago: medioPago.Nombre,
      }
    })
  }

  const manejarCambioValor = (e) => {
    contextoRegistro.setMedioDePagoParaAgregar({
      ...contextoRegistro.medioDePagoParaAgregar,
      valor: e.floatValue,
    })
  }

  const manejarCambioVoucher = (e) => {
    if (!!e?.target?.value) {
      e.target.value = e.target.value.toUpperCase()
    }

    if (
      e.target.value == '' &&
      contextoRegistro.medioDePagoParaAgregar.medioDePago == 'TIQUETERA'
    ) {
      setValue('voucher')
      setError('voucher', {
        type: 'required',
        message: '',
      })
    } else {
      clearErrors('voucher')
      setValue('voucher', e.target.value)
    }
    contextoRegistro.setMedioDePagoParaAgregar({
      ...contextoRegistro.medioDePagoParaAgregar,
      voucher: e.target.value,
    })
  }

  const agregarMedioDePago = async () => {
    const {medioDePago, valor, idMedioPago} =
      contextoRegistro.medioDePagoParaAgregar

    const filterMediosDePago = contextoRegistro.mediosDePagoSeleccionados.find(
      (medioDePago) =>
        medioDePago?.idMedioPago?.toString() == idMedioPago?.toString()
    )
    if (filterMediosDePago) {
      return
    }

    const noEsUnValorValido =
      medioDePago === '' || (typeof valor !== 'number' && !valor) || valor < 0

    if (noEsUnValorValido) {
      return
    }
    if (
      contextoRegistro.medioDePagoParaAgregar?.medioDePago ===
        NOMBRES_MEDIOS_PAGO.TIQUETERAS &&
      contextoRegistro.medioDePagoParaAgregar?.voucher === ''
    ) {
      return
    }

    if (
      contextoRegistro.medioDePagoParaAgregar?.medioDePago ===
        NOMBRES_MEDIOS_PAGO.BONOS &&
      contextoRegistro.medioDePagoParaAgregar?.voucher === ''
    ) {
      return
    }

    if (
      mediosSaldoPortalCliente.includes(
        contextoRegistro.medioDePagoParaAgregar.medioDePago
      )
    ) {
      if (
        parseFloat(contextoRegistro.medioDePagoParaAgregar.valor) >
          saldoDisponible &&
        !modoEdicion
        // (saldoDisponible || saldoDisponibleParticular) COmentado por si en el futuro se vuelve a utilizar
      ) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'El valor es mayor a su saldo disponible',
          contenido: '',
          botones: [
            {
              nombre: 'Aceptar',
              click: () => {
                contextoAplicacion.setModal({
                  abierto: false,
                  titulo: '',
                  contenido: '',
                  botones: [],
                })
              },
            },
          ],
        })
        return
      } else {
        setSaldoDisponible(
          (prv) =>
            prv - parseFloat(contextoRegistro.medioDePagoParaAgregar.valor)
        )
        setSaldoDisponibleParticular(
          (prv) =>
            prv - parseFloat(contextoRegistro.medioDePagoParaAgregar.valor)
        )
      }
    }

    if (
      !!contextoRegistro.medioDePagoParaAgregar?.voucher &&
      !voucherRegex.test(contextoRegistro.medioDePagoParaAgregar?.voucher ?? '')
    ) {
      contextoAplicacion.setModal({
        abierto: true,
        titulo:
          'Solo se permiten letras, números, guión ("-") y barras ("/") en el campo Número de voucher, tiquetera, factura helisa.',
        contenido: '',
        botones: [
          {
            nombre: 'Aceptar',
            click: () => {
              contextoAplicacion.setModal({
                abierto: false,
                titulo: '',
                contenido: '',
                botones: [],
              })
            },
          },
        ],
      })
      return
    }

    contextoRegistro.setMediosDePagoSeleccionados((prev) => [
      ...prev,
      contextoRegistro.medioDePagoParaAgregar,
    ])

    contextoRegistro.setMedioDePagoParaAgregar({
      id: '',
      idMedioPago: '',
      medioDePago: '',
      valor: '',
      voucher: '',
    })
    setValue('medioPago', '')
    setValue('voucher', '')
    setMostrarSaldo(false)
  }

  useEffect(() => {
    const mediosDePagoSeleccionados = contextoRegistro.mediosDePagoSeleccionados

    if (mediosDePagoSeleccionados.length === 0) return

    const ultimoMedioSeleccionado = mediosDePagoSeleccionados.slice(-1)[0]

    const obtenerAtencion = (tipo, voucher) => {
      const obtenerFuncion = {
        [NOMBRES_MEDIOS_PAGO.TIQUETERAS]: ObtenerAtencionConTiquetera,
        [NOMBRES_MEDIOS_PAGO.BONOS]: ObtenerAtencionConBono,
      }[tipo]

      if (!obtenerFuncion) return

      obtenerFuncion(voucher)
        .then((res) => {
          if (res.status === 200) {
            const errorTipo = {
              [NOMBRES_MEDIOS_PAGO.TIQUETERAS]: 'tiquetera',
              [NOMBRES_MEDIOS_PAGO.BONOS]: 'bono',
            }[tipo]

            setError(errorTipo, {
              type: 'existent',
              message: `El número de ${errorTipo} ya fue utilizado`,
            })
          }
        })
        .catch(console.log)
    }

    obtenerAtencion(
      ultimoMedioSeleccionado.medioDePago,
      ultimoMedioSeleccionado.voucher
    )
  }, [contextoRegistro.mediosDePagoSeleccionados])

  useEffect(() => {
    contextoRegistro.setValorSubTotalAtencion(
      contextoRegistro.valorTotalServicios - contextoRegistro.valorCortesia
    )
    const valorTotalAtencion = (
      contextoRegistro.valorTotalServicios -
      contextoRegistro.valorCortesia +
      contextoRegistro.valorIva
    ).toFixed(2)

    contextoRegistro.setValorTotalAtencion(parseFloat(valorTotalAtencion))
  }, [
    contextoRegistro.valorCortesia,
    contextoRegistro.valorTotalServicios,
    contextoRegistro.porcentajeCortesia,
  ])

  useEffect(() => {
    if (
      contextoRegistro?.empresaSeleccionada?.id &&
      parseInt(contextoRegistro?.informacionPersonal?.tipoAtencion) ===
        TIPO_ATENCION_REGISTRO.EMPRESA
    ) {
      ObtenerInformacionCuentaPorEmpresaId(
        contextoRegistro.empresaSeleccionada.id
      ).then((respuesta) => {
        setSaldoDisponible(respuesta?.data?.saldo ?? 0)
        setSaldoDisponibleParticular(0)
      })
    } else {
      setSaldoDisponible(0)
    }
  }, [
    contextoRegistro?.empresaSeleccionada?.id,
    contextoRegistro?.informacionPersonal?.tipoAtencion,
  ])

  const manejarCambioPorcentajeCortesia = (e) => {
    if (e.floatValue === undefined) {
      contextoRegistro.setValorCortesia(0)
      contextoRegistro.setPorcentajeCortesia(0)
      return
    } else {
      contextoRegistro.setPorcentajeCortesia(e.floatValue)

      const valorCortesia = (
        contextoRegistro.valorTotalServicios *
        (e.floatValue / 100)
      ).toFixed(2)

      contextoRegistro.setValorCortesia(parseFloat(valorCortesia))
    }
  }
  const manejarCambioValorCortesia = (e) => {
    if (e.floatValue === undefined) {
      contextoRegistro.setValorCortesia(0)
      contextoRegistro.setPorcentajeCortesia(0)
      return
    } else {
      contextoRegistro.setValorCortesia(e.floatValue)
      const porcentajeCortesia = (
        100 *
        (e.floatValue / contextoRegistro.valorTotalServicios)
      ).toFixed(2)

      contextoRegistro.setPorcentajeCortesia(parseFloat(porcentajeCortesia))
    }
  }

  const ObtenerDatosFormulario = () => {
    contextoRegistro.setInformacionFacturacion(getValues())
  }

  //habilita la firma en dllo
  ;['dev', 'desarrollo'].includes(nombreAmbiente) &&
    contextoRegistro.setFirma('https://firma.ejemplo.png')

  return (
    <>
      <div className="w-full flex justify-between items-center my-2">
        <Controller
          name="tipoFacturacion"
          control={control}
          rules={{
            required: false,
          }}
          render={() => (
            <Input
              estilosContenedor={'w-31%'}
              estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              tipo={'text'}
              placeholder={''}
              deshabilitado={true}
              titulo={'Tipo de facturación'}
              valor={contextoRegistro.empresaSeleccionada.tipoFacturacion}
            />
          )}
        />
        <Controller
          name="fechaComprobante"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              tipo={'date'}
              placeholder={''}
              titulo={'Fecha comprobante atención'}
              valor={value}
              deshabilitado={true}
            />
          )}
        />
        <div className="w-31%"></div>
      </div>
      <div className="w-full flex justify-between items-center my-2">
        <TablaDinamica
          data={contextoRegistro.examenesSeleccionados}
          columns={columns}
        />
      </div>
      <TablaMediosDePago
        titulo={'AGREGAR MEDIOS DE PAGO'}
        columns={columnsPago}
        data={contextoRegistro.mediosDePagoSeleccionados}
      >
        <div className="w-full flex justify-between items-center my-2">
          <Controller
            name="medioPago"
            control={control}
            rules={{
              required: false,
            }}
            render={({field: {value}}) => (
              <ListaDesplegable
                funcion={manejarMedioDepago}
                estilosContenedor={'w-3/12'}
                estilosLista={
                  contextoRegistro.atencionCreada ||
                  contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ==
                    'CRÉDITO'
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg pointer-events-none focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                }
                titulo={'Medio de pago'}
                deshabilitado={contextoRegistro.atencionCreada}
                opciones={mediosDePago}
                valor={value}
              />
            )}
          />
          <Controller
            name="valor"
            control={control}
            rules={{
              required: false,
            }}
            render={() => (
              <div className={`${mostrarSaldo ? 'w-2/12' : 'w-3/12'}`}>
                <label className="block text-13px leading-15px text-cendiatra my-5px">
                  Valor $
                </label>
                <NumericFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                  onValueChange={manejarCambioValor}
                  value={contextoRegistro.medioDePagoParaAgregar.valor}
                  className={
                    contextoRegistro.atencionCreada ||
                    contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ==
                      'CRÉDITO'
                      ? 'appearance-none relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 placeholder-white rounded-lg pointer-events-none focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      : 'appearance-none relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                  }
                />
              </div>
            )}
          />
          {mostrarSaldo ? (
            <div className={`${mostrarSaldo ? 'w-2/12' : 'w-4/12'}`}>
              <label className="block text-13px leading-15px text-cendiatra my-5px">
                Saldo disponible, portal cliente
              </label>
              <NumericFormat
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
                value={!saldoDisponible ? 0 : saldoDisponible}
                // value={saldoDisponible || saldoDisponibleParticular} Comentado por si se necesita mas adelante
                className={
                  'appearance-none relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 placeholder-white rounded-lg pointer-events-none focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
              />
            </div>
          ) : null}
          <Controller
            name="voucher"
            control={control}
            rules={{
              required:
                contextoRegistro.medioDePagoParaAgregar.medioDePago ==
                  'TIQUETERA' ||
                contextoRegistro.medioDePagoParaAgregar.medioDePago == 'BONO'
                  ? true
                  : false,
            }}
            render={() => (
              <Input
                funcion={manejarCambioVoucher}
                estilosContenedor={`${mostrarSaldo ? 'w-3/12' : 'w-4/12'}`}
                estilosInput={
                  contextoRegistro.atencionCreada ||
                  contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ==
                    'CRÉDITO'
                    ? 'appearance-none rounded pointer-events-none relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 placeholder-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                }
                placeholder={'Descripción'}
                titulo={'Número de voucher, tiquetera, factura helisa'}
                deshabilitado={contextoRegistro.atencionCreada}
                valor={contextoRegistro.medioDePagoParaAgregar.voucher}
              />
            )}
          />
          <div className="w-1/12">
            <BotonConImagen
              ancho={'mr-11 mt-2'}
              imagen={BotonAgregar}
              estilosImagen={'w-7'}
              textoAlternativo={'Agregar'}
              funcionAEjecutar={agregarMedioDePago}
              desabilitado={
                Object.keys(errors).find(
                  (element) => element === 'tiquetera' || element === 'bono'
                ) ||
                Object.keys(errors).find((element) => element === 'voucher')
              }
            />
          </div>
        </div>
      </TablaMediosDePago>
      <div
        className={
          Object.keys(errors).find(
            (element) => element === 'tiquetera' || element === 'bono'
          )
            ? 'w-full flex flex-wrap justify-center items-center  py-2 mb-1 rounded-lg'
            : 'hidden'
        }
      >
        <div className="w-3/12"></div>
        <div className="w-3/12"></div>

        <div className="w-4/12 bg-cendiatra-gris-5  flex justify-start items-center  py-2 mb-1 rounded-lg -ml-16 -mt-5">
          <div className="w-2/12 flex justify-center items-center">
            <img src={IconoAlerta} alt="alerta" />
          </div>
          <div className="w-10/12">
            <span className="text-red-500 w-full">
              {(errors.tiquetera?.type === 'existent' &&
                errors.tiquetera?.message) ||
                (errors.bono?.type === 'existent' && errors.bono?.message)}
            </span>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center rounded my-2 border border-cendiatra mt-12">
        <div className="w-6/12 flex justify-center items-center">
          <Controller
            name="autorizacion"
            control={control}
            rules={{
              required:
                contextoRegistro.porcentajeCortesia != 0 ||
                contextoRegistro.valorCortesia != 0
                  ? true
                  : false,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                onChange={onChange}
                estilosContenedor={
                  'w-8/12 flex justify-center text-17px font-bold'
                }
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'autorizacion'
                  )
                    ? 'appearance-none h-20 rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10  '
                    : `appearance-none h-20 rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 ${
                        contextoRegistro.atencionCreada ||
                        contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ==
                          'CRÉDITO'
                          ? 'bg-cendiatra-gris-4 placeholder-white pointer-events-none'
                          : ''
                      } `
                }
                tipo={'text'}
                placeholder={''}
                titulo={'AUTORIZACIÓN CORTESÍA*'}
                valor={value}
                deshabilitado={contextoRegistro.atencionCreada}
                onBlur={() => ObtenerDatosFormulario()}
              />
            )}
          />
        </div>
        <div className="w-6/12">
          <div className="w-full flex justify-between items-center my-5 ">
            <div className="w-6/12 flex justify-end items-center pt-2">
              <span className="text-17px font-bold text-cendiatra">
                VALOR TOTAL SERVICIOS
              </span>
            </div>
            <div className="w-4/12 flex flex-wrap justify-center items-center mr-9">
              <InputMoneda
                estilosContenedor={'w-11/12'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm pointer-events-none'
                }
                valor={contextoRegistro.valorTotalServicios}
              />
            </div>
          </div>
          <div className="w-full flex justify-between items-center my-5">
            <div className="w-5/12 flex justify-end items-center ">
              <span className="text-17px font-bold text-cendiatra mr-2">
                CORTESÍA
              </span>
            </div>
            <div className="w-2/12 flex justify-center items-center">
              <Controller
                name="porcentajeCortesia"
                control={control}
                rules={{
                  required: false,
                }}
                render={() => (
                  <InputPorcentaje
                    estilosContenedor={'w-11/12'}
                    estilosInput={
                      contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ==
                        'CRÉDITO' || contextoRegistro.atencionCreada
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm pointer-events-none'
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white text-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                    }
                    valor={contextoRegistro.porcentajeCortesia}
                    onValueChange={manejarCambioPorcentajeCortesia}
                  />
                )}
              />
            </div>
            <span className="w-2 flex justify-center items-center text-cendiatra">
              -
            </span>
            <div className="w-4/12 flex justify-center items-center mr-9">
              <Controller
                name="valorCortesia"
                control={control}
                rules={{
                  required: false,
                }}
                render={() => (
                  <InputMoneda
                    estilosContenedor={'w-11/12'}
                    estilosInput={
                      contextoRegistro.empresaSeleccionada.tipoFacturacion.toUpperCase() ==
                        'CRÉDITO' || contextoRegistro.atencionCreada
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm pointer-events-none'
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-rojo-1 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                    }
                    valor={contextoRegistro.valorCortesia}
                    formatoVacio={true}
                    onValueChange={manejarCambioValorCortesia}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full flex justify-between items-center my-5 ">
            <div className="w-6/12 flex justify-end items-center pt-2">
              <span className="text-17px font-bold text-cendiatra">
                SUB TOTAL
              </span>
            </div>
            <div className="w-4/12 flex justify-center items-center mr-9">
              <InputMoneda
                estilosContenedor={'w-11/12'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm pointer-events-none'
                }
                valor={contextoRegistro.valorSubTotalAtencion}
              />
            </div>
          </div>

          <div className="w-full flex justify-between items-center my-5 ">
            <div className="w-6/12 flex justify-end items-center pt-2">
              <span className="text-17px font-bold text-cendiatra">IVA</span>
            </div>
            <div className="w-4/12 flex justify-center items-center mr-9">
              <InputMoneda
                estilosContenedor={'w-11/12'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm pointer-events-none'
                }
                valor={contextoRegistro.valorIva}
              />
            </div>
          </div>
          <div className="w-full flex justify-between items-center my-5  pt-5 border-t-2 border-cendiatra-gris-3">
            <div className="w-6/12 flex justify-end items-center pt-2">
              <span className="text-17px font-bold text-cendiatra">TOTAL</span>
            </div>
            <div className="w-4/12 flex justify-center items-center mr-9">
              <InputMoneda
                estilosContenedor={'w-11/12'}
                estilosInput={
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-white bg-cendiatra-gris-4 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm pointer-events-none'
                }
                valor={contextoRegistro.valorTotalAtencion}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Facturacion
