import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react'
import TablaAgenda from '../../componentes/Agenda/TablaAgenda'
import EncabezadoFondo from '../../componentes/Tablas/EncabezadoFondo'
import {CeldaDobleTexto} from '../../componentes/Celdas/CeldaDobleTexto'
import ExamenesPaciente from '../../componentes/Agenda/ExamenesPaciente'
import CeldaTipoPaciente from '../../componentes/Agenda/CeldaTipoPaciente.jsx'
import {
  ObtenerEmpresaPorId,
  AgregarEmpresasACache,
} from '../../cache/servicios-cache/Empresas'
import {ObtenerServicioPorId} from '../../cache/servicios-cache/Servicios'
import {ObtenerEstadoServicioAtencionPorId} from '../../cache/servicios-cache/EstadoServicioAtencion'
import moment from 'moment'
import {ContextoAgendaMedico, ContextApplication} from '../../contexto'
import {AgregarTarifariosACache} from '../../cache/servicios-cache/Tarifarios'
import {AgregarServiciosACache} from '../../cache/servicios-cache/Servicios'
import {AgregarPaquetesACache} from '../../cache/servicios-cache/Paquetes'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink, useHistory} from 'react-router-dom'
import {
  FachadaObtenerAtencionesAbiertas,
  CambiarALlamandoServicioAtencion,
  FachadaCambiarAPendienteServicioAtencion,
  CambiarEnProcesoServicioAtencion,
  FachadaCambiarALlamandoLaboratorios,
  FachadaCambiarAAtendiendoLaboratorios,
  FachadaValidarServiciosLaboratorioPorAtencionId,
} from '../../microservicios/Fachada'
import LeyendasAgenda from '../../componentes/Agenda/LeyendasAgenda'
import AtencionesAMostrar from '../../componentes/Agenda/AtencionesAMostrar'
import FiltroAgenda from '../../componentes/Agenda/FiltroAgenda'
import {ObtenerSedesPorCiudadId} from '../../cache/servicios-cache/Sedes.js'
import TextoTiempoEspera from '../../componentes/Agenda/TextoTiempoEspera'
import {LEYENDA_AGENDA} from '../../constantes/leyendaAgendas'
import {ObtenerPermisosServiciosPorRol} from '../../microservicios/Rol'
import {estadosExamenesConst} from '../../constantes/estadoExamen'
import {ObtenerEstadoAtencion} from '../../microservicios/Atencion'
import {ObtenerUsuarioPorId} from '../../microservicios/Usuario'
import {useModal} from '../../hooks/useModal'
import {useMsal} from '@azure/msal-react'
import {useConexionSignalR} from '../../hooks/signalR/useConexionSignalR'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TEXTO_VISUAL, TEXTO_AGENDAS} from '../../constantes/textoVisual'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils.js'
import {informacionMedicoParaServicioAtencion} from '../../utilidades/funcionesComunes.js'

const AgendaRegistro = () => {
  const {accounts} = useMsal()

  const correoUsuario = accounts?.[0]?.idTokenClaims?.preferred_username
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const contextoAplicacion = useContext(ContextApplication)

  const contextoAgendaMedico = useContext(ContextoAgendaMedico)

  const [atencionesDeHoy, setAtencionesDeHoy] = useState(true)

  const [todasLasSedes, setTodasLasSedes] = useState([])

  const [especialidadesMedico , setEspecialidadesMedico] = useState([])

  const [cerrarModal] = useModal()

  const ciudadActualId = parseInt(
    fnObtenerInformacionSessionOLocalStorage('idCiudad', rol)
  )

  const sedeInicialId = parseInt(
    fnObtenerInformacionSessionOLocalStorage('sedeId', rol)
  )

  const history = useHistory()

  const [montado, setMontado] = useState(false)

  useEffect(() => {
    setMontado(true)
    return () => setMontado(false)
  }, [])

  const manejarMostrarTipoAtenciones = () => {
    setAtencionesDeHoy(!atencionesDeHoy)
  }
  useEffect(() => {
    let componenteMontado = true
    if (componenteMontado) {
      obtenerSedes()
    }

    return () => {
      componenteMontado = false
    }
  }, [])

  const obtenerSedes = async () => {
    setTodasLasSedes(await ObtenerSedesPorCiudadId(ciudadActualId))
  }

  useEffect(() => {
    contextoAplicacion.setDeshabilitarMenu(false)
    const obtenerEspecialidadesMedico = async () =>{
      let idsEspecialidades = (await ObtenerPermisosServiciosPorRol()).data
      .serviciosIds
      setEspecialidadesMedico(idsEspecialidades)
    }
    obtenerEspecialidadesMedico()
  }, [])

  const manejarEstadoServicioAtencion = async (servicios) => {
    if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId === estadosExamenesConst.LLAMANDO
      )
    ) {
      return (
        (
          await ObtenerEstadoServicioAtencionPorId(
            servicios?.find(
              (servicio) =>
                servicio?.estadoServicioId === estadosExamenesConst.LLAMANDO
            )?.estadoServicioId ?? 0
          )
        )?.Nombre ?? ''
      )
    } else if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO &&
          servicio?.esLaboratorio
      )
    ) {
      return TEXTO_AGENDAS?.LABORATORIO_CLINICO
    } else if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO
      )
    ) {
      return (
        (
          (await ObtenerServicioPorId(
            servicios?.find(
              (servicio) =>
                servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO
            )?.servicioId
          )) ?? ''
        )?.Nombre ?? ''
      )
    } else if (
      servicios?.find(
        (servicio) =>
          servicio?.estadoServicioId ===
            estadosExamenesConst.IMPRIMIENDO_STICKER && servicio?.esLaboratorio
      )
    ) {
      return TEXTO_AGENDAS?.LABORATORIO_CLINICO
    } else {
      return TEXTO_AGENDAS?.EN_SALA
    }
  }
  const manejarObtenerDatos = useCallback(async () => {
    ObtenerInformacionAgenda()
  }, [])

  const verificarEspecialidad = async (idServicio) => {
    return especialidadesMedico?.includes(idServicio)
  }

  const validarAtencionCorrespondeARol = async (atencion) => {
    let existenServicios = atencion.filter((element) =>
      especialidadesMedico?.includes(element.servicioId)
    )
    return existenServicios
  }

  const validarTipoExamen = (servicio) => {
    if (servicio.esLaboratorio) {
      return TEXTO_AGENDAS?.LABORATORIO_CLINICO
    } else if (servicio.esVacunacion) {
      return TEXTO_AGENDAS?.VACUNACION
    }
  }

  const validarExistenciaAlias = async (servicio) => {
    if (servicio.alias) {
      return servicio.alias ?? ''
    } else {
      return (await ObtenerServicioPorId(servicio.servicioId))?.Nombre ?? ''
    }
  }

  const obtenerLaEmpresaPadreEnCasoDeTener = async (empresaId) => {
    let empresa = await ObtenerEmpresaPorId(empresaId)
    return empresa
  }

  const validarNombreMedico = (servicios, nombreMedico) => {
    const servicioLlamando = servicios.find(
      (servicio) => servicio.estadoServicioId === estadosExamenesConst.LLAMANDO
    )
    if (servicioLlamando) {
      return servicioLlamando?.medicoLlamando?.nombre
    }

    const servicioEnProceso = servicios.find(
      (servicio) =>
        servicio?.estadoServicioId === estadosExamenesConst.EN_PROCESO ||
        servicio?.estadoServicioId === estadosExamenesConst.IMPRIMIENDO_STICKER
    )
    if (servicioEnProceso) {
      return servicioEnProceso?.nombreMedico
    }
    return ''
  }

  const ObtenerInformacionAgenda = (sedeSeleccionada) => {
    FachadaObtenerAtencionesAbiertas(
      true,
      sedeSeleccionada
        ? sedeSeleccionada
        : contextoAgendaMedico.sedeSeleccionada,
      true
    ).then(async (res) => {
      if (res.status === 200) {
        contextoAgendaMedico.setAtencionesDiaActual(
          ordenarListaAtenciones(
            await Promise.all(
              res.data.map(async (atencion) => ({
                oculto: false,
                id: atencion.atencionId,
                idOrden: atencion.ordenId,
                usuarioId: atencion.usuarioId,
                numeroOrdenyFechaCreacion: {
                  Fila1: moment(atencion.fechaCreacion).format('LT'),
                  Fila2: moment(atencion.fechaCreacion).format('DD/MM/YYYY'),
                },
                nombresyDocumento: {
                  Fila1: atencion?.nombreCompleto?.toUpperCase() ?? '',
                  Fila2: atencion.numeroDocumento,
                },
                empresa:
                  (
                    await obtenerLaEmpresaPadreEnCasoDeTener(atencion.empresaId)
                  )?.Nombre?.toUpperCase() ?? '',
                tipoPaciente: atencion.tipoPacienteId,
                examenes: ordenarExamenes(
                  await Promise.all(
                    atencion.servicios.map(async (servicio) => {
                      return {
                        tipoExamen:
                          servicio.esLaboratorio || servicio.esVacunacion
                            ? validarTipoExamen(servicio)
                            : await validarExistenciaAlias(servicio),
                        estadoExamen: servicio.estadoServicioId,
                        medico: informacionMedicoParaServicioAtencion(
                          servicio.estadoServicioId,
                          servicio
                        ),
                        enCurso: servicio.enCurso,
                        especialidadMedico: servicio.accesoPermitido,
                        servicioAtencionId: servicio.servicioAtencionId,
                        servicioId: servicio.servicioId,
                        esLaboratorio: servicio.esLaboratorio,
                        esVacunacion: servicio.esVacunacion,
                        admiteTomaDeDatos: servicio.admiteTomaDeDatos,
                      }
                    })
                  )
                ),
                ocupadoPor: {
                  Fila1: validarNombreMedico(
                    atencion.servicios,
                    atencion.nombreMedico
                  ),
                  Fila2: await manejarEstadoServicioAtencion(
                    atencion.servicios
                  ),
                },
                fechaCreacion: atencion?.fechaCreacion,
                fechaUltimoLlamado: atencion.fechaUltimoLLamado,
              }))
            )
          )
        )
      } else {
        contextoAgendaMedico.setAtencionesDiaActual([])
      }
    })

    FachadaObtenerAtencionesAbiertas(
      false,
      sedeSeleccionada
        ? sedeSeleccionada
        : contextoAgendaMedico.sedeSeleccionada,
      true
    ).then(async (res) => {
      if (res.status === 200) {
        contextoAgendaMedico.setAtencionesPasadas(
          ordenarListaAtenciones(
            await Promise.all(
              res.data.map(async (atencion) => ({
                oculto: false,
                id: atencion.atencionId,
                idOrden: atencion.ordenId,
                usuarioId: atencion.usuarioId,
                numeroOrdenyFechaCreacion: {
                  Fila1: moment(atencion.fechaCreacion).format('LT'),
                  Fila2: moment(atencion.fechaCreacion).format('DD/MM/YYYY'),
                },
                nombresyDocumento: {
                  Fila1: atencion?.nombreCompleto?.toUpperCase() ?? '',
                  Fila2: atencion.numeroDocumento,
                },
                empresa:
                  (
                    await obtenerLaEmpresaPadreEnCasoDeTener(atencion.empresaId)
                  )?.Nombre?.toUpperCase() ?? '',
                tipoPaciente: atencion.tipoPacienteId,
                examenes: ordenarExamenes(
                  await Promise.all(
                    atencion.servicios.map(async (servicio) => {
                      return {
                        tipoExamen:
                          servicio.esLaboratorio || servicio.esVacunacion
                            ? validarTipoExamen(servicio)
                            : await validarExistenciaAlias(servicio),
                        estadoExamen: servicio.estadoServicioId,
                        medico: informacionMedicoParaServicioAtencion(
                          servicio.estadoServicioId,
                          servicio
                        ),
                        enCurso: servicio.enCurso,
                        especialidadMedico: servicio.accesoPermitido,
                        servicioAtencionId: servicio.servicioAtencionId,
                        servicioId: servicio.servicioId,
                        esLaboratorio: servicio.esLaboratorio,
                        esVacunacion: servicio.esVacunacion,
                        admiteTomaDeDatos: servicio.admiteTomaDeDatos,
                      }
                    })
                  )
                ),
                ocupadoPor: {
                  Fila1: '',
                  Fila2: 'PENDIENTE POR CERRAR',
                },
                fechaCreacion: atencion?.fechaCreacion,
                fechaUltimoLlamado: atencion.fechaUltimoLLamado,
              }))
            )
          )
        )
      } else {
        contextoAgendaMedico.setAtencionesPasadas([])
      }
    })
  }

  useEffect(() => {
    let componenteMontado = true
    const AgregarDatosACache = async () => {
      await AgregarEmpresasACache()
      await AgregarTarifariosACache()
      await AgregarServiciosACache()
      await AgregarPaquetesACache()
      if (componenteMontado) {
        manejarObtenerDatos().catch((error) => {
          console.log(error)
        })
      }
    }
    AgregarDatosACache()
    return () => {
      componenteMontado = false
    }
  }, [])

  useEffect(() => {
    if (!montado) {
      return
    }
    if (
      contextoAgendaMedico.sedeSeleccionada == sedeInicialId &&
      contextoAgendaMedico.sedeAnterior != sedeInicialId
    ) {
      contextoAgendaMedico.setActualizarDatos(
        !contextoAgendaMedico.actualizarDatos
      )
      contextoAgendaMedico.setSedeAnterior(
        contextoAgendaMedico.sedeSeleccionada
      )
      contextoAgendaMedico.setMotivoCambioSede('')
      return
    }
  }, [contextoAgendaMedico.sedeSeleccionada])

  const ordenarListaAtenciones = (listaAtenciones) => {
    let listaAtencionesOrdenada = []

    listaAtencionesOrdenada = listaAtenciones.sort((a, b) => {
      if (a.tipoPaciente == 1 && b.tipoPaciente == 2 && a.id - b.id) {
        return 1
      }
      if (a.tipoPaciente == 1 && b.tipoPaciente == 3 && a.id - b.id) {
        return 1
      }
      if (a.tipoPaciente == 2 && b.tipoPaciente == 1 && b.id - a.id) {
        return -1
      }
      if (a.tipoPaciente == 2 && b.tipoPaciente == 3 && b.id - a.id) {
        return -1
      }
      if (a.tipoPaciente == 3 && b.tipoPaciente == 1 && b.id - a.id) {
        return -1
      }
      if (a.tipoPaciente == 3 && b.tipoPaciente == 2 && a.id - b.id) {
        return 1
      }
      return 0
    })

    return listaAtencionesOrdenada
  }
  const ordenarExamenes = (listaExamenes) => {
    let listaExamenesOrdenados = []
    listaExamenesOrdenados = listaExamenes.sort((a, b) => {
      return moment(a.tiempoEspera).isBefore(moment(b.tiempoEspera)) ? -1 : 1
    })
    listaExamenesOrdenados = listaExamenesOrdenados.sort((a, b) => {
      if (a.especialidadMedico == true && b.especialidadMedico == false) {
        return -1
      }
      if (a.especialidadMedico == false && b.especialidadMedico == true) {
        return 1
      }

      return 0
    })
    return listaExamenesOrdenados
  }

  const manejarAgregarAtencionSede = async (message) => {
    let mensajeAtencionAgregado = JSON.parse(message)

    if (
      (await validarAtencionCorrespondeARol(mensajeAtencionAgregado.servicios))
        .length < 1
    ) {
      return
    }

    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensajeAtencionAgregado.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        await Promise.all(
          contextoAgendaMedico.atencionesDiaActual.map(async (atencion) => {
            if (atencion.id === mensajeAtencionAgregado.atencionId) {
              const nuevosExamenes = await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (examen) => {
                  return {
                    tipoExamen: await validarExistenciaAlias(examen),
                    estadoExamen: examen.estadoServicioId,
                    medico: examen.nombreMedico,
                    enCurso: examen.enCurso,
                    especialidadMedico: true,
                    servicioId: examen.servicioId,
                    servicioAtencionId: examen.servicioAtencionId,
                  }
                })
              )

              return {
                ...atencion,
                examenes: nuevosExamenes,
              }
            }
            return atencion
          })
        )
      )
      return
    }

    if (
      contextoAgendaMedico.atencionesPasadas.find(
        (x) => x.id == mensajeAtencionAgregado.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesPasadas(
        await Promise.all(
          contextoAgendaMedico.atencionesPasadas.map(async (atencion) => {
            if (atencion.id === mensajeAtencionAgregado.atencionId) {
              const nuevosExamenes = await Promise.all(
                mensajeAtencionAgregado.servicios.map(async (examen) => {
                  return {
                    tipoExamen: await validarExistenciaAlias(examen),
                    estadoExamen: examen.estadoServicioId,
                    medico: examen.nombreMedico,
                    enCurso: examen.enCurso,
                    especialidadMedico: true,
                    servicioId: examen.servicioId,
                    servicioAtencionId: examen.servicioAtencionId,
                  }
                })
              )

              return {
                ...atencion,
                examenes: nuevosExamenes,
              }
            }
            return atencion
          })
        )
      )
      return
    }

    let atencionAAgregar = {
      oculto: false,
      id: mensajeAtencionAgregado.atencionId,
      idOrden: mensajeAtencionAgregado.ordenId,
      usuarioId: mensajeAtencionAgregado.usuarioId,
      numeroOrdenyFechaCreacion: {
        Fila1: moment(mensajeAtencionAgregado.fechaCreacion).format('LT'),
        Fila2: moment(mensajeAtencionAgregado.fechaCreacion).format(
          'DD/MM/YYYY'
        ),
      },
      nombresyDocumento: {
        Fila1: mensajeAtencionAgregado?.nombreCompleto?.toUpperCase() ?? '',
        Fila2: mensajeAtencionAgregado.numeroDocumento,
      },
      empresa:
        (
          await obtenerLaEmpresaPadreEnCasoDeTener(
            mensajeAtencionAgregado.empresaId
          )
        )?.Nombre?.toUpperCase() ?? '',
      tipoPaciente: mensajeAtencionAgregado.tipoPacienteId,
      examenes: ordenarExamenes(
        await Promise.all(
          mensajeAtencionAgregado.servicios.map(async (servicio) => {
            return {
              tipoExamen:
                servicio.esLaboratorio || servicio.esVacunacion
                  ? validarTipoExamen(servicio)
                  : await validarExistenciaAlias(servicio),
              estadoExamen: servicio.estadoServicioId,
              medico: informacionMedicoParaServicioAtencion(
                servicio.estadoServicioId,
                servicio
              ),
              enCurso: servicio.enCurso,
              especialidadMedico: await verificarEspecialidad(
                servicio.servicioId
              ),
              servicioAtencionId: servicio.servicioAtencionId,
              servicioId: servicio.servicioId,
              esLaboratorio: servicio.esLaboratorio,
              esVacunacion: servicio.esVacunacion,
              admiteTomaDeDatos: servicio.admiteTomaDeDatos,
            }
          })
        )
      ),
      ocupadoPor: {
        Fila1: '',
        Fila2: await manejarEstadoServicioAtencion(
          mensajeAtencionAgregado.servicios
        ),
      },
      fechaCreacion: mensajeAtencionAgregado?.fechaCreacion,
      fechaUltimoLlamado: mensajeAtencionAgregado?.fechaUltimoLLamado,
    }
    const fechaActual = moment().format('YYYY/MM/DD')
    const fechaAtencion = moment(mensajeAtencionAgregado.fechaCreacion).format(
      'YYYY/MM/DD'
    )

    if (fechaActual === fechaAtencion) {
      contextoAgendaMedico.setAtencionesDiaActual(
        ordenarListaAtenciones([
          ...contextoAgendaMedico.atencionesDiaActual,
          atencionAAgregar,
        ])
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        ordenarListaAtenciones([
          ...contextoAgendaMedico.atencionesPasadas,
          atencionAAgregar,
        ])
      )
    }
  }

  const manejarServicioAtencionEnProceso = async (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id === mensaje.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
              if (atencion.id === mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: atencion.examenes.map((examen) => {
                    if (
                      examen.servicioAtencionId === mensaje.servicioAtencionId
                    ) {
                      return {
                        ...examen,
                        estadoExamen: estadosExamenesConst.EN_PROCESO,
                        medico: mensaje.nombreMedico,
                      }
                    }
                    return examen
                  }),
                  ocupadoPor: {
                    Fila1: mensaje.nombreMedico,
                    Fila2:
                      atencion.examenes
                        .find(
                          (examen) =>
                            examen.servicioAtencionId ===
                            mensaje.servicioAtencionId
                        )
                        ?.tipoExamen?.toUpperCase() ?? '',
                  },
                  fechaUltimoLlamado: moment().format(),
                }
              }
              return atencion
            })
          )
        )
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaMedico.atencionesPasadas.map((atencion) => {
              if (atencion.id == mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: atencion.examenes.map((examen) => {
                    if (
                      examen.servicioAtencionId === mensaje.servicioAtencionId
                    ) {
                      return {
                        ...examen,
                        estadoExamen: estadosExamenesConst.EN_PROCESO,
                      }
                    }
                    return examen
                  }),
                }
              }
              return atencion
            })
          )
        )
      )
    }
  }

  const manejarLaboratorioAtencionEnProceso = async (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id === mensaje.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
              if (atencion.id === mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: atencion.examenes.map((examen) => {
                    if (examen.esLaboratorio) {
                      return {
                        ...examen,
                        estadoExamen: estadosExamenesConst.EN_PROCESO,
                        medico: mensaje.nombreMedico,
                      }
                    }
                    return examen
                  }),
                  ocupadoPor: {
                    Fila1: mensaje.nombreMedico,
                    Fila2:
                      atencion.examenes
                        .find((examen) => examen.esLaboratorio)
                        .tipoExamen?.toUpperCase() ?? '',
                  },
                  fechaUltimoLlamado: moment().format(),
                }
              }
              return atencion
            })
          )
        )
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaMedico.atencionesPasadas.map((atencion) => {
              if (atencion.id == mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: atencion.examenes.map((examen) => {
                    if (examen.esLaboratorio) {
                      return {
                        ...examen,
                        estadoExamen: estadosExamenesConst.EN_PROCESO,
                      }
                    }
                    return examen
                  }),
                }
              }
              return atencion
            })
          )
        )
      )
    }
  }

  const manejarAtencionModificada = (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      let atencionesfiltradas = contextoAgendaMedico.atencionesDiaActual.filter(
        (x) => x.id != mensaje.atencionId
      )
      contextoAgendaMedico.setAtencionesDiaActual(atencionesfiltradas)
    } else {
      let atencionesfiltradas = contextoAgendaMedico.atencionesPasadas.filter(
        (x) => x.id != mensaje.atencionId
      )
      contextoAgendaMedico.setAtencionesPasadas(atencionesfiltradas)
    }
  }

  const manejarServicioAtencionFinalizadoSede = async (message) => {
    let mensajeAtencionAgregado = JSON.parse(message)

    if (
      contextoAgendaMedico.atencionesPasadas.find(
        (x) => x.id === mensajeAtencionAgregado.atencionId
      )
    ) {
      return contextoAgendaMedico.setAtencionesPasadas(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaMedico.atencionesPasadas.map((atencion) => {
              if (atencion.id === mensajeAtencionAgregado.atencionId) {
                return {
                  ...atencion,
                  examenes: ordenarExamenes(
                    atencion.examenes.map((examen) => {
                      if (
                        examen.servicioAtencionId ===
                        mensajeAtencionAgregado.servicioAtencionId
                      ) {
                        return {
                          ...examen,
                          estadoExamen: estadosExamenesConst.CERRADO,
                          medico: '',
                          tipoGuardado: '',
                        }
                      }
                      return examen
                    })
                  ),
                  Fila2: 'PENDIENTE POR CERRAR',
                  fechaUltimoLlamado: moment().format(),
                }
              }
              return atencion
            })
          )
        )
      )
    } else {
      if (
        contextoAgendaMedico.atencionesDiaActual.find(
          (x) => x.id == mensajeAtencionAgregado.atencionId
        )
      ) {
        return contextoAgendaMedico.setAtencionesDiaActual(
          ordenarListaAtenciones(
            await Promise.all(
              contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
                if (atencion.id === mensajeAtencionAgregado.atencionId) {
                  return {
                    ...atencion,
                    examenes: ordenarExamenes(
                      atencion.examenes.map((examen) => {
                        if (
                          examen.servicioAtencionId ===
                          mensajeAtencionAgregado.servicioAtencionId
                        ) {
                          return {
                            ...examen,
                            estadoExamen: estadosExamenesConst.CERRADO,
                            medico: '',
                            tipoGuardado: '',
                          }
                        }
                        return examen
                      })
                    ),
                    ocupadoPor: {
                      Fila1: '',
                      Fila2: TEXTO_AGENDAS?.EN_SALA,
                    },
                    fechaUltimoLlamado: moment().format(),
                  }
                }
                return atencion
              })
            )
          )
        )
      }
    }
  }

  const manejarServicioAtencionPendientePorCerrarSede = async (message) => {
    let mensaje = JSON.parse(message)
    if ((await validarAtencionCorrespondeARol(mensaje.servicios)).length < 1) {
      return
    }

    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaMedico.atencionesDiaActual.map(async (atencion) => {
              if (atencion.id == mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: ordenarExamenes(
                    await Promise.all(
                      mensaje.servicios.map(async (servicio) => {
                        return {
                          tipoExamen: servicio.esLaboratorio
                            ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                            : await validarExistenciaAlias(servicio),
                          estadoExamen: servicio.estadoServicioId,
                          medico: informacionMedicoParaServicioAtencion(
                            servicio.estadoServicioId,
                            servicio
                          ),
                          enCurso: servicio.enCurso,
                          especialidadMedico: await verificarEspecialidad(
                            servicio.servicioId
                          ),
                          servicioAtencionId: servicio.servicioAtencionId,
                          servicioId: servicio.servicioId,
                          esLaboratorio: servicio.esLaboratorio,
                          esVacunacion: servicio.esVacunacion,
                        }
                      })
                    )
                  ),
                  ocupadoPor: {
                    Fila1: '',
                    Fila2: TEXTO_AGENDAS?.EN_SALA,
                  },
                  fechaUltimoLlamado: moment().format(),
                }
              }
              return atencion
            })
          )
        )
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        ordenarListaAtenciones(
          await Promise.all(
            contextoAgendaMedico.atencionesPasadas.map(async (atencion) => {
              if (atencion.id == mensaje.atencionId) {
                return {
                  ...atencion,
                  examenes: await Promise.all(
                    mensaje.servicios.map(async (servicio) => {
                      return {
                        tipoExamen: servicio.esLaboratorio
                          ? TEXTO_AGENDAS?.LABORATORIO_CLINICO
                          : await validarExistenciaAlias(servicio),
                        estadoExamen: servicio.estadoServicioId,
                        medico: informacionMedicoParaServicioAtencion(
                          servicio.estadoServicioId,
                          servicio
                        ),
                        enCurso: servicio.enCurso,
                        especialidadMedico: await verificarEspecialidad(
                          servicio.servicioId
                        ),
                        servicioAtencionId: servicio.servicioAtencionId,
                        esLaboratorio: servicio.esLaboratorio,
                        esVacunacion: servicio.esVacunacion,
                      }
                    })
                  ),
                }
              }
              return atencion
            })
          )
        )
      )
    }
  }

  const manejarAtencionFinalizadaSede = (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      let atencionesfiltradas = contextoAgendaMedico.atencionesDiaActual.filter(
        (x) => x.id != mensaje.atencionId
      )
      contextoAgendaMedico.setAtencionesDiaActual(atencionesfiltradas)
    } else {
      let atencionesfiltradas = contextoAgendaMedico.atencionesPasadas.filter(
        (x) => x.id != mensaje.atencionId
      )
      contextoAgendaMedico.setAtencionesPasadas(atencionesfiltradas)
    }
  }

  const manejarAtencionLlamandoPaciente = (message) => {
    let mensaje = JSON.parse(message)

    const fechaActual = moment().format('YYYY/MM/DD')
    const fechaAtencion = moment(mensaje.fechaCreacion).format('YYYY/MM/DD')

    if (fechaActual === fechaAtencion) {
      contextoAgendaMedico.setAtencionesDiaActual(
        contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensaje.atencionId) {
            return {
              ...atencion,
              examenes: ordenarExamenes(
                atencion.examenes.map((examen) => {
                  if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                    return {
                      ...examen,
                      estadoExamen: estadosExamenesConst.LLAMANDO,
                    }
                  }
                  return examen
                })
              ),
              ocupadoPor: {
                Fila1: mensaje.nombreMedico,
                Fila2: 'Llamando paciente...',
              },
              fechaUltimoLlamado: moment().format(),
            }
          }
          return {
            ...atencion,
          }
        })
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        contextoAgendaMedico.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensaje.atencionId) {
            return {
              ...atencion,
              examenes: ordenarExamenes(
                atencion.examenes.map((examen) => {
                  if (examen.servicioAtencionId == mensaje.servicioAtencionId) {
                    return {
                      ...examen,
                      estadoExamen: estadosExamenesConst.LLAMANDO,
                    }
                  }
                  return examen
                })
              ),
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarAtencionPendientePorAtender = async (message) => {
    let mensajeAtencionAgregado = JSON.parse(message)
    if (
      (await validarAtencionCorrespondeARol(mensajeAtencionAgregado.servicios))
        .length < 1
    ) {
      return
    }
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id === mensajeAtencionAgregado.atencionId
      )
    ) {
      let atenciones = await Promise.all(
        contextoAgendaMedico.atencionesDiaActual.map(async (atencion) => {
          if (atencion.id == mensajeAtencionAgregado.atencionId) {
            return {
              ...atencion,
              examenes: ordenarExamenes(
                await Promise.all(
                  mensajeAtencionAgregado.servicios.map(async (servicio) => {
                    return {
                      tipoExamen:
                        servicio.esLaboratorio || servicio.esVacunacion
                          ? validarTipoExamen(servicio)
                          : await validarExistenciaAlias(servicio),
                      estadoExamen: servicio.estadoServicioId,
                      medico: informacionMedicoParaServicioAtencion(
                        servicio.estadoServicioId,
                        servicio
                      ),
                      enCurso: servicio.enCurso,
                      especialidadMedico: await verificarEspecialidad(
                        servicio.servicioId
                      ),
                      servicioAtencionId: servicio.servicioAtencionId,
                      servicioId: servicio.servicioId,
                      esLaboratorio: servicio.esLaboratorio,
                      esVacunacion: servicio.esVacunacion,
                      admiteTomaDeDatos: servicio.admiteTomaDeDatos,
                    }
                  })
                )
              ),
              ocupadoPor: {
                Fila1: '',
                Fila2: 'EN SALA',
              },
              fechaUltimoLlamado: moment().format(),
            }
          } else {
            return atencion
          }
        })
      )
      contextoAgendaMedico.setAtencionesDiaActual(
        ordenarListaAtenciones(atenciones)
      )
    } else if (
      contextoAgendaMedico.atencionesPasadas.find(
        (x) => x.id === mensajeAtencionAgregado.atencionId
      )
    ) {
      let atenciones = await Promise.all(
        contextoAgendaMedico.atencionesPasadas.map(async (atencion) => {
          if (atencion.id == mensajeAtencionAgregado.atencionId) {
            return {
              ...atencion,
              examenes: ordenarExamenes(
                await Promise.all(
                  mensajeAtencionAgregado.servicios.map(async (servicio) => {
                    return {
                      tipoExamen:
                        servicio.esLaboratorio || servicio.esVacunacion
                          ? validarTipoExamen(servicio)
                          : await validarExistenciaAlias(servicio),
                      estadoExamen: servicio.estadoServicioId,
                      medico: informacionMedicoParaServicioAtencion(
                        servicio.estadoServicioId,
                        servicio
                      ),
                      enCurso: servicio.enCurso,
                      especialidadMedico: await verificarEspecialidad(
                        servicio.servicioId
                      ),
                      servicioAtencionId: servicio.servicioAtencionId,
                      servicioId: servicio.servicioId,
                      esLaboratorio: servicio.esLaboratorio,
                      esVacunacion: servicio.esVacunacion,
                      admiteTomaDeDatos: servicio.admiteTomaDeDatos,
                    }
                  })
                )
              ),
              ocupadoPor: {
                Fila1: '',
                Fila2: 'EN SALA',
              },
              fechaUltimoLlamado: moment().format(),
            }
          } else {
            return atencion
          }
        })
      )
      contextoAgendaMedico.setAtencionesPasadas(
        ordenarListaAtenciones(atenciones)
      )
    } else {
      const fechaActual = moment().format('YYYY/MM/DD')
      const fechaAtencion = moment(
        mensajeAtencionAgregado.fechaCreacion
      ).format('YYYY/MM/DD')
      let atencionAAgregar = {
        id: mensajeAtencionAgregado.atencionId,
        idOrden: mensajeAtencionAgregado.ordenId,
        usuarioId: mensajeAtencionAgregado.usuarioId,
        numeroOrdenyFechaCreacion: {
          Fila1: moment(mensajeAtencionAgregado.fechaCreacion).format('LT'),
          Fila2: moment(mensajeAtencionAgregado.fechaCreacion).format(
            'DD/MM/YYYY'
          ),
        },
        nombresyDocumento: {
          Fila1: mensajeAtencionAgregado.nombreCompleto
            ? mensajeAtencionAgregado?.nombreCompleto?.toUpperCase()
            : '',
          Fila2: mensajeAtencionAgregado.numeroDocumento,
        },
        empresa:
          (
            await obtenerLaEmpresaPadreEnCasoDeTener(
              mensajeAtencionAgregado.empresaId
            )
          )?.Nombre?.toUpperCase() ?? '',
        tipoPaciente: mensajeAtencionAgregado.tipoPacienteId,
        examenes: ordenarExamenes(
          await Promise.all(
            mensajeAtencionAgregado.servicios.map(async (servicio) => {
              return {
                tipoExamen:
                  servicio.esLaboratorio || servicio.esVacunacion
                    ? validarTipoExamen(servicio)
                    : await validarExistenciaAlias(servicio),
                estadoExamen: servicio.estadoServicioId,
                medico: informacionMedicoParaServicioAtencion(
                  servicio.estadoServicioId,
                  servicio
                ),
                enCurso: servicio.enCurso,
                especialidadMedico: await verificarEspecialidad(
                  servicio.servicioId
                ),
                servicioAtencionId: servicio.servicioAtencionId,
                servicioId: servicio.servicioId,
                esLaboratorio: servicio.esLaboratorio,
                esVacunacion: servicio.esVacunacion,
                admiteTomaDeDatos: servicio.admiteTomaDeDatos,
              }
            })
          )
        ),
        ocupadoPor: {
          Fila1: '',
          Fila2:
            fechaActual > fechaAtencion
              ? 'PENDIENTE POR CERRAR'
              : await manejarEstadoServicioAtencion(
                  mensajeAtencionAgregado.servicios
                ),
        },
        fechaCreacion: mensajeAtencionAgregado.fechaCreacion,
        fechaUltimoLLamado: mensajeAtencionAgregado.fechaUltimoLLamado,
      }

      if (fechaActual > fechaAtencion) {
        contextoAgendaMedico.setAtencionesPasadas(
          ordenarListaAtenciones([
            ...contextoAgendaMedico.atencionesPasadas,
            atencionAAgregar,
          ])
        )
      } else {
        contextoAgendaMedico.setAtencionesDiaActual(
          ordenarListaAtenciones([
            ...contextoAgendaMedico.atencionesDiaActual,
            atencionAAgregar,
          ])
        )
      }
    }
  }

  const manejarLlamandoLaboratorioSede = (message) => {
    let mensaje = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensaje.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensaje.atencionId) {
            return {
              ...atencion,
              examenes: ordenarExamenes(
                atencion.examenes.map((examen) => {
                  if (examen.esLaboratorio) {
                    return {
                      ...examen,
                      estadoExamen: estadosExamenesConst.LLAMANDO,
                    }
                  }
                  return examen
                })
              ),
              ocupadoPor: {
                Fila1: mensaje.nombreMedico,
                Fila2: 'Llamando paciente...',
              },
              fechaUltimoLlamado: moment().format(),
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        contextoAgendaMedico.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensaje.atencionId) {
            return {
              ...atencion,
              examenes: ordenarExamenes(
                atencion.examenes.map((examen) => {
                  if (examen.esLaboratorio) {
                    return {
                      ...examen,
                      estadoExamen: estadosExamenesConst.LLAMANDO,
                    }
                  }
                  return examen
                })
              ),
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarLaboratorioPendientePorCerrar = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.PENDIENTE_POR_CERRAR,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        contextoAgendaMedico.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.PENDIENTE_POR_CERRAR,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarLaboratorioFinalizadoSede = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                    medico: '',
                    tipoGuardado: '',
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        contextoAgendaMedico.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    }
  }

  const manejarMedicoLaboratorioPendientePorCerrarSede = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.PENDIENTE_POR_CERRAR,
                    medico: mensajeLaboratorioParamodificar.nombreMedico,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        contextoAgendaMedico.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.PENDIENTE_POR_CERRAR,
                    medico: mensajeLaboratorioParamodificar.nombreMedico,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    }
  }
  const manejarMedicoLaboratorioFinalizadoSede = (message) => {
    let mensajeLaboratorioParamodificar = JSON.parse(message)
    if (
      contextoAgendaMedico.atencionesDiaActual.find(
        (x) => x.id == mensajeLaboratorioParamodificar.atencionId
      )
    ) {
      contextoAgendaMedico.setAtencionesDiaActual(
        contextoAgendaMedico.atencionesDiaActual.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    } else {
      contextoAgendaMedico.setAtencionesPasadas(
        contextoAgendaMedico.atencionesPasadas.map((atencion) => {
          if (atencion.id == mensajeLaboratorioParamodificar.atencionId) {
            return {
              ...atencion,
              examenes: atencion.examenes.map((examen) => {
                if (examen.esLaboratorio) {
                  return {
                    ...examen,
                    estadoExamen: estadosExamenesConst.CERRADO,
                  }
                }
                return examen
              }),
            }
          }
          return atencion
        })
      )
    }
  }
  const listaMensajesYFunciones = [
    {
      nombreConexion: 'atencionAgregadaSede',
      funcion: manejarAgregarAtencionSede,
    },
    {
      nombreConexion: 'medicoServicioAtencionEnProcesoSede',
      funcion: manejarServicioAtencionEnProceso,
    },
    {
      nombreConexion: 'medicoServicioAtencionFinalizadoSede',
      funcion: manejarServicioAtencionFinalizadoSede,
    },
    {
      nombreConexion: 'medicoServicioAtencionPendientePorCerrarSede',
      funcion: manejarServicioAtencionPendientePorCerrarSede,
    },
    {
      nombreConexion: 'medicoAtencionFinalizadaSede',
      funcion: manejarAtencionFinalizadaSede,
    },
    {
      nombreConexion: 'medicoServicioAtencionLlamandoSede',
      funcion: manejarAtencionLlamandoPaciente,
    },
    {
      nombreConexion: 'medicoServicioAtencionPendienteAtenderSede',
      funcion: manejarAtencionPendientePorAtender,
    },
    {
      nombreConexion: 'medicoLaboratorioLlamandoSede',
      funcion: manejarLlamandoLaboratorioSede,
    },
    {
      nombreConexion: 'medicoLaboratorioAtendiendoSede',
      funcion: manejarLaboratorioAtencionEnProceso,
    },
    {
      nombreConexion: 'medicoRetirarAtencion',
      funcion: manejarAtencionModificada,
    },
    {
      nombreConexion: 'registroLaboratorioPendientePorCerrarSede',
      funcion: manejarLaboratorioPendientePorCerrar,
    },
    {
      nombreConexion: 'registroLaboratorioFinalizadoSede',
      funcion: manejarLaboratorioFinalizadoSede,
    },
    {
      nombreConexion: 'medicoLaboratorioPendientePorCerrarSede',
      funcion: manejarMedicoLaboratorioPendientePorCerrarSede,
    },
    {
      nombreConexion: 'medicoLaboratorioFinalizadoSede',
      funcion: manejarMedicoLaboratorioFinalizadoSede,
    },
  ]
  const [conexion, agregarSubscripciones, eliminarSubscripciones] =
    useConexionSignalR(listaMensajesYFunciones, contextoAplicacion.sede.sedeId)

  useEffect(() => {
    if (!montado) {
      return
    }
    if (conexion) {
      eliminarSubscripciones(listaMensajesYFunciones)
      agregarSubscripciones(listaMensajesYFunciones)
    }
  }, [
    contextoAgendaMedico.atencionesDiaActual,
    contextoAgendaMedico.atencionesPasadas,
  ])

  const manejaCerrarModal = async (
    servicioAtencionId,
    tipoServicioId,
    atencionId
  ) => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    if (tipoServicioId !== 6) {
      await FachadaCambiarAPendienteServicioAtencion(servicioAtencionId).catch(
        (error) => console.log(error)
      )
    } else {
      await FachadaValidarServiciosLaboratorioPorAtencionId(atencionId).catch(
        (error) => console.log(error)
      )
    }
  }

  const abrirExamenEspecialidaMedico = async (
    idUsuario,
    idOrden,
    atencionId,
    servicioAtencionId,
    examenes,
    tipoServicioId,
    servicioId
  ) => {
    if (tipoServicioId === 6) {
      await FachadaCambiarAAtendiendoLaboratorios(atencionId).catch((error) =>
        console.log(error)
      )
    } else {
      await CambiarEnProcesoServicioAtencion(servicioAtencionId).catch(
        (error) => console.log(error)
      )
    }

    history.push(tipoServicioId === 6 ? '/tomademuestras' : '/examen', {
      usuarioId: idUsuario,
      ordenId: idOrden,
      atencionId: atencionId,
      servicioAtencionId: servicioAtencionId,
      examenes: examenes,
      servicioId: servicioId,
    })
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const abrirModalMedico = async (
    usuarioId,
    ordenId,
    atencionId,
    examenes,
    servicioAtencionId,
    servicioId
  ) => {
    try {
      const estadoAtencion = await ObtenerEstadoAtencion(atencionId)
      if (
        estadoAtencion?.status === 200 &&
        estadoAtencion?.data?.enProceso &&
        correoUsuario !== estadoAtencion?.data?.correoMedico
      ) {
        const datosUsuario = (await ObtenerUsuarioPorId(usuarioId))?.data ?? {}

        const nombreExamen =
          (await ObtenerServicioPorId(estadoAtencion?.data?.servicioId))
            ?.Nombre ?? ''

        contextoAplicacion.setModal({
          abierto: true,
          titulo: (
            <div className="w-full flex justify-center items-center flex-wrap">
              <span className="font-bold w-full">
                {datosUsuario?.nombres} {datosUsuario?.apellidos} CON ATENCIÓN #
                {atencionId}
              </span>
              <span className="w-full font-bold text-center ">
                SE ENCUENTRA OCUPADO POR:
              </span>
            </div>
          ),
          contenido: (
            <div className="w-full flex justify-center items-center flex-wrap">
              <span className="font-bold w-full text-center">
                {estadoAtencion?.data?.nombreMedico}
              </span>
              <span className="w-full font-bold text-center text-cendiatra">
                {nombreExamen}
              </span>
            </div>
          ),
          botones: [
            {
              nombre: 'Regresar',
              click: cerrarModal,
            },
          ],
        })
        return
      }
    } catch (error) {
      console.log(error)
      return
    }

    let tipoServicioId = (await ObtenerServicioPorId(servicioId))
      .ClasificacionId
    if (!atencionesDeHoy) {
      abrirExamenEspecialidaMedico(
        usuarioId,
        ordenId,
        atencionId,
        servicioAtencionId,
        examenes,
        tipoServicioId,
        servicioId
      )
    } else {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: <span className="font-bold">LLAMANDO PACIENTE...</span>,
        contenido: (
          <TextoTiempoEspera
            tiempoInicial={'00:00:00'}
            colorTexto={'text-cendiatra-rojo-1'}
          />
        ),
        botones: [
          {
            nombre: 'Llegó Paciente',
            click: () =>
              abrirExamenEspecialidaMedico(
                usuarioId,
                ordenId,
                atencionId,
                servicioAtencionId,
                examenes,
                tipoServicioId,
                servicioId
              ),
          },
          {
            nombre: 'No Llegó Paciente',
            click: () =>
              manejaCerrarModal(servicioAtencionId, tipoServicioId, atencionId),
          },
        ],
      })
      if (tipoServicioId === 6) {
        await FachadaCambiarALlamandoLaboratorios(atencionId).catch((error) =>
          console.log(error)
        )
      } else {
        await CambiarALlamandoServicioAtencion(servicioAtencionId).catch(
          (error) => console.log(error)
        )
      }
    }
  }

  let columns = useMemo(
    () => [
      {
        Header: (row) => <EncabezadoFondo titulo={'Número de atención'} />,
        accessor: 'id',
        className: 'w-6% ',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center rounded-l-lg ',
      },
      {
        Header: (row) => (
          <EncabezadoFondo titulo={'Hora llegada y fecha de registro'} />
        ),
        accessor: 'numeroOrdenyFechaCreacion',
        className: 'w-7%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        Cell: (row) => (
          <CeldaDobleTexto datos={row.row.original.numeroOrdenyFechaCreacion} />
        ),
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: 'Nombres ó cédula',
        accessor: 'nombresyDocumento',
        className: 'w-15%',
        headerStyle:
          "flex justify-center items-center bg-cendiatra-verde-5 text-white text-14px'",
        filter: 'dobleTexto',
        Cell: (row) => (
          <CeldaDobleTexto datos={row.row.original.nombresyDocumento} />
        ),
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Empresa'} />,
        accessor: 'empresa',
        className: 'w-7%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Tipo paciente'} />,
        accessor: 'tipoPaciente',
        className: 'w-8%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',
        Cell: (row) => (
          <CeldaTipoPaciente
            tipoUsurio={row.row.original.tipoPaciente}
            listaServicios={row?.row?.original?.examenes}
            atencion={atencionesDeHoy ? row.row.original : ''}
          />
        ),
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Exámenes'} />,
        accessor: 'examenes',
        className: 'w-42%',
        headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
        disableFilters: true,
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center',

        Cell: (row) => (
          <ExamenesPaciente
            datos={row.row.original.examenes}
            funcion={(servicioAtencionId, servicioId) =>
              abrirModalMedico(
                row.row.original.usuarioId,
                row.row.original.idOrden,
                row.row.original.id,
                row.row.original.examenes,
                servicioAtencionId,
                servicioId
              )
            }
          />
        ),
      },
      {
        Header: (row) => <EncabezadoFondo titulo={'Ocupado por '} />,
        accessor: 'ocupadoPor',
        className: 'w-15%',
        headerStyle: 'bg-cendiatra-verde-7 text-white rounded-r-lg h-16 pr-1',
        cellStyles:
          'text-13px text-cendiatra-gris-1 h-32 flex justify-center items-center rounded-r-lg',
        Cell: (row) => (
          <CeldaDobleTexto
            datos={row.row.original.ocupadoPor}
            color={'text-cendiatra-verde-4'}
          />
        ),
        disableFilters: true,
      },
    ],
    [
      contextoAgendaMedico.atencionesDiaActual,
      contextoAgendaMedico.atencionesPasadas,
      atencionesDeHoy,
    ]
  )
  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.TITULO_ROL_MEDICO.AGENDA_MEDICO}
    >
      <div
        className="bg-white pl-8 pr-5 rounded-t-3xl rounded-b-3xl"
        style={{minHeight: '80vh'}}
      >
        <div className="w-full flex justify-center items-center">
          <div className="w-full flex justify-between items-center mt-8">
            <AtencionesAMostrar
              estilosContenedor={
                'w-3/12 flex flex-wrap justify-center items-center border border-cendiatra py-5px rounded-lg h-20'
              }
              seleccionado={atencionesDeHoy}
              funcion={manejarMostrarTipoAtenciones}
              tituloLeyendaSuperior={'Atenciones pendientes hoy'}
              tituloLeyendaInferior={
                'Atenciones pendientes por cerrar de días anteriores'
              }
              valorLeyendaSuperior={
                contextoAgendaMedico.atencionesDiaActual.length
              }
              valorLeyendaInferior={
                contextoAgendaMedico.atencionesPasadas.length
              }
            />
            <div className="w-5/12 flex justify-evenly items-start h-28">
              <FiltroAgenda
                tituloLista={'Sede'}
                tituloInput={
                  'Describa el motivo por el cual desea cambiar de sede*'
                }
                opcionesLista={todasLasSedes}
                contexto={contextoAgendaMedico}
                funcionActualizar={(sedeId) => ObtenerInformacionAgenda(sedeId)}
              />
            </div>
            <div className="w-4/12 flex justify-center content-between h-20">
              <div className="w-full flex flex-wrap">
                <LeyendasAgenda leyendas={LEYENDA_AGENDA.MEDICO} />
              </div>
            </div>
          </div>
        </div>

        <TablaAgenda
          ocultarTabla={contextoAgendaMedico.ocultarTablaDatos}
          data={
            atencionesDeHoy
              ? contextoAgendaMedico.atencionesDiaActual
              : contextoAgendaMedico.atencionesPasadas
          }
          columns={columns}
        />
      </div>
    </ContenedorPagina>
  )
}

export default AgendaRegistro
