import React, {useEffect, useState, useContext} from 'react'
import {Input, InputPorcentaje, ListaDesplegable, InputMoneda} from '../inputs'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {BotonOpcion} from '../Botones'
import BotonConImagen from '../BotonConImagen'
import BotonQuitar from '../../imagenes/removeButton.png'
import BotonAgregar from '../../imagenes/addButton.png'
import expandir from '../../imagenes/Expandible/flechaDesplegableExpandirVerde.svg'
import FormularioAgregarRangos from './FormularioAgregarRangos'
import {ObtenerCuentasDeIngreso} from '../../cache/servicios-cache'
import {TIPO_SERVICIOS, CATEGORIA_SERVICIOS} from '../../constantes'
import {ObtenerCupsPorCodigoONombre} from '../../cache/servicios-cache'
import {ContextoGestionOtrosServicios, ContextApplication} from '../../contexto'
import {
  ObtenerUnicoServicioPorNombre,
  AgregarServiciosACache,
} from '../../cache/servicios-cache'
import {AlertaDinamica} from '../MostrarInformacion'
import clsx from 'clsx'

const FilaDatosOtrosServicios = () => {
  const datosServiciosPorDefecto = {
    id: 0,
    nombre: '',
    tipoServicioId: '',
    cup: '',
    cupSeleccionado: null,
    cantidad: '',
    tieneRangos: false,
    iva: '',
    valor: '',
    cuentaContableId: '',
    noEstaDuplicado: true,
    cupsNoExiste: true,
    rangos: [
      {
        id: 0,
        concepto: '',
        rangoMinimo: 0,
        rangoMaximo: 0,
        precioDiaSiguiente: true,
        valor: 0,
      },
    ],
  }
  const contextoAplicacion = useContext(ContextApplication)

  const [cuentasDeingreso, setCuentasDeIngreso] = useState([])
  const [filaSeleccionada, setFilaSeleccionada] = useState(-1)
  const [cupsFiltrados, setCupsFiltrados] = useState([])

  const contextoGestionOtrosServicios = useContext(
    ContextoGestionOtrosServicios
  )

  useEffect(() => {
    const obtenerInformacionInicial = async () => {
      setCuentasDeIngreso(await ObtenerCuentasDeIngreso())
      await AgregarServiciosACache()
    }
    obtenerInformacionInicial()
  }, [])

  const mostrarOEsconderPrecios = (posicion) => {
    setFilaSeleccionada(filaSeleccionada === posicion ? -1 : posicion)
  }
  const validarNuevoServicio = async (nombre) => {
    let existeServicioConElMismoNombre =
      contextoGestionOtrosServicios.datosServicios.filter(
        (elemento) => elemento.nombre?.toUpperCase() === nombre?.toUpperCase()
      )

    return !existeServicioConElMismoNombre.length > 0
  }

  const validarNuevoCups = (cup) => {
    const cupYaExiste = contextoGestionOtrosServicios.datosServicios.filter(
      (elemento) => elemento.cup === cup
    )
    return cupYaExiste.length > 0
  }

  const crearArregloServiciosModificados = (
    indice,
    arreglo,
    nombrePropiedad,
    valor
  ) => {
    if (arreglo[indice].id) {
      contextoGestionOtrosServicios.setServiciosModificados((prev) => {
        const index = prev.findIndex((item) => item.id === arreglo[indice].id)
        if (index !== -1) {
          return prev.map((item) =>
            item.id === arreglo[indice].id
              ? {...item, nombre: arreglo[indice].nombre}
              : item
          )
        }
        return [
          ...prev,
          {id: arreglo[indice].id, nombre: arreglo[indice].nombre},
        ]
      })
    } else {
      const servicioExistente =
        contextoGestionOtrosServicios.serviciosNuevos.find(
          (servicio) => servicio.indice === indice
        )
      if (servicioExistente) {
        contextoGestionOtrosServicios.setServiciosNuevos((prev) =>
          prev.map((servicio, i) => {
            if (indice === servicio.indice) {
              return {
                ...servicio,
                [nombrePropiedad]: valor,
              }
            }
            return servicio
          })
        )
      } else {
        contextoGestionOtrosServicios.setServiciosNuevos((prev) => [
          ...prev,
          {
            id: '',
            nombre:
              nombrePropiedad === 'nombre' ? valor : arreglo[indice].nombre,
            indice: indice,
          },
        ])
      }
    }
  }

  const modificarValoresServicios = async (indice, nombrePropiedad, valor) => {
    let validarExisteNombre
    let arreglo = contextoGestionOtrosServicios.datosServicios

    crearArregloServiciosModificados(indice, arreglo, nombrePropiedad, valor)

    if (nombrePropiedad === 'nombre') {
      validarExisteNombre = await validarNuevoServicio(valor)
    }

    contextoGestionOtrosServicios.setDatosServicios((prev) => {
      const nuevoArreglo = [...prev]

      if (indice >= 0 && indice < nuevoArreglo.length) {
        if (nombrePropiedad === 'tieneRangos' && valor) {
          nuevoArreglo[indice] = {
            ...nuevoArreglo[indice],
            [nombrePropiedad]: valor ?? '',
            valor: '',
          }
        }
        if (nombrePropiedad === 'nombre') {
          return nuevoArreglo?.map((elemento, i) => {
            if (i === indice) {
              return {
                ...elemento,
                [nombrePropiedad]: valor ?? '',
                noEstaDuplicado: validarExisteNombre,
              }
            }
            return {
              ...elemento,
              noEstaDuplicado: true,
            }
          })
        }
        if (
          nombrePropiedad === 'tipoServicioId' &&
          parseInt(valor) === CATEGORIA_SERVICIOS.TIQUETERA
        ) {
          manejarCambioCups(indice, 'cup', '')
          modificarValoresServicios(indice, 'cupsNoExiste', true)
          modificarValoresServicios(indice, 'tieneRangos', false)
          modificarValoresServicios(indice, 'iva', '')
        }

        nuevoArreglo[indice] = {
          ...nuevoArreglo[indice],
          [nombrePropiedad]: valor ?? '',
        }
      }

      return nuevoArreglo
    })
  }
  const manejarCambioCups = async (indice, nombrePropiedad, valor) => {
    if (!valor) {
      contextoGestionOtrosServicios.setDatosServicios((prev) => {
        return prev.map((servicio, i) => {
          if (i === indice) {
            return {
              ...servicio,
              cupsNoExiste: true,
            }
          }
          return servicio
        })
      })
    }
    modificarValoresServicios(indice, nombrePropiedad, valor)
    modificarValoresServicios(indice, 'cupSeleccionado', null)
    if (valor.length >= 3) {
      setCupsFiltrados(await ObtenerCupsPorCodigoONombre(valor))
    } else {
      setCupsFiltrados([])
      modificarValoresServicios(indice, 'cupSeleccionado', null)
    }
  }
  const manejarSeleccionarCup = (indice, informacionCups) => {
    modificarValoresServicios(indice, 'cup', informacionCups.filtro)
    modificarValoresServicios(indice, 'cupSeleccionado', informacionCups)

    const existeCup = validarNuevoCups(informacionCups.filtro)

    contextoGestionOtrosServicios.setDatosServicios((prev) => {
      return prev.map((servicio, i) => {
        if (i === indice) {
          return {
            ...servicio,
            cupsNoExiste: !existeCup,
          }
        }
        return {
          ...servicio,
          cupsNoExiste: true,
        }
      })
    })
  }

  const agregarServicioAlListado = () => {
    contextoGestionOtrosServicios.setDatosServicios((prev) => [
      ...prev,
      {...datosServiciosPorDefecto, indice: prev.length},
    ])
  }

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const mostrarModalConfirmacionEliminacion = (indice, id, nombre) => {
    if (id) {
      return contextoAplicacion.setModal({
        abierto: true,
        contenido: (
          <div className="w-full flex justify-center items-center flex-wrap">
            <div className="w-full text-red-600  text-2xl font-bold">
              SE ELIMINARÁ EL SERVICIO
            </div>
            <span className="w-full text-red-600 text-2xl font-bold mt-10">
              {nombre}
            </span>

            <div className="w-full text-cendiatra mt-10 mb-6 text-2xl font-bold">
              ¿DESEA ELIMINARLO?
            </div>
          </div>
        ),
        botones: [
          {
            nombre: 'No',
            click: manejaCerrarModal,
          },
          {
            nombre: 'Si',
            click: () => quitarServicioPorIndice(indice),
          },
        ],
      })
    }
    quitarServicioPorIndice(indice)
  }

  const quitarServicioPorIndice = (indice) => {
    contextoGestionOtrosServicios.setDatosServicios((prev) => {
      const nuevoArray = [...prev]

      if (indice > -1 && indice < nuevoArray.length) {
        if (nuevoArray[indice]?.id) {
          contextoGestionOtrosServicios.setServiciosAEliminar((prev) => [
            ...prev,
            nuevoArray[indice]?.id,
          ])
        }
      }

      manejaCerrarModal()

      return nuevoArray.filter((servicio, i) => i != indice)
    })
    contextoGestionOtrosServicios.setServiciosNuevos((prev) => {
      const nuevoArray = [...prev]
      return nuevoArray.filter((servicio) => servicio.indice != indice)
    })
  }

  const filterElementosSinValor = (arr) => {
    return arr.filter((obj) => {
      let entradas = Object.entries(obj)
      let rangosHabilitados = obj.tieneRangos
      let esTiquetera =
        parseInt(obj.tipoServicioId) === CATEGORIA_SERVICIOS.TIQUETERA
      for (let [key, value] of entradas) {
        const keysAEvaluar = [
          'tieneRangos',
          'valor',
          'id',
          'cup',
          'cupSeleccionado',
          'iva',
        ]
        if (
          (!value && !keysAEvaluar.includes(key)) ||
          (!value &&
            rangosHabilitados &&
            key !== 'valor' &&
            key !== 'id' &&
            key !== 'cup' &&
            key !== 'cupSeleccionado' &&
            key !== 'iva')
        ) {
          return true
        }
        if (!esTiquetera && key === 'iva' && !value) {
          return true
        }

        if (Array.isArray(value) && rangosHabilitados) {
          return value.some((elemento) => !elemento.valor && !elemento.id)
        }

        if (
          (!esTiquetera && key === 'cup' && !value) ||
          (key === 'valor' && !value && !rangosHabilitados)
        ) {
          return true
        }
      }
      return false
    })
  }

  useEffect(() => {
    const serviciosConErrores = filterElementosSinValor(
      contextoGestionOtrosServicios.datosServicios
    )
    contextoGestionOtrosServicios.setServiciosConErrores(serviciosConErrores)
  }, [contextoGestionOtrosServicios.datosServicios])

  const obtenerEstilosInputCups = (datosServicio, CATEGORIA_SERVICIOS) => {
    return clsx(
      'appearance-none rounded-xl relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
      {
        'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
          (!datosServicio.cupSeleccionado || !datosServicio.cup) &&
          parseInt(datosServicio.tipoServicioId) !==
            CATEGORIA_SERVICIOS.TIQUETERA,
        'border-cendiatra focus:border-cendiatra':
          (datosServicio.cupSeleccionado && datosServicio.cup) ||
          parseInt(datosServicio.tipoServicioId) ===
            CATEGORIA_SERVICIOS.TIQUETERA,
        'text-white bg-cendiatra-gris-1 pointer-events-none text-cendiatra-gris-3':
          parseInt(datosServicio.tipoServicioId) ===
          CATEGORIA_SERVICIOS.TIQUETERA,
      }
    )
  }

  const obtenerEstilosInputIva = (datosServicio, CATEGORIA_SERVICIOS) => {
    return clsx(
      'appearance-none rounded-xl text-cendiatra-gris-3 relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
      {
        'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
          !datosServicio.iva &&
          parseInt(datosServicio.tipoServicioId) !==
            CATEGORIA_SERVICIOS.TIQUETERA,
        'border-cendiatra focus:border-cendiatra':
          datosServicio.iva ||
          parseInt(datosServicio.tipoServicioId) ===
            CATEGORIA_SERVICIOS.TIQUETERA,
        'text-white bg-cendiatra-gris-1 pointer-events-none text-cendiatra-gris-3':
          parseInt(datosServicio.tipoServicioId) ===
          CATEGORIA_SERVICIOS.TIQUETERA,
      }
    )
  }

  return (
    <div className="w-full flex justify-center items-center flex-wrap">
      {contextoGestionOtrosServicios.datosServicios?.map((servicio, index) => (
        <>
          <div className="w-full flex justify-center items-center border border-cendiatra-verde-2 h-14 rounded-2xl  mt-5">
            <div className="w-1/12 flex justify-center items-center text-cendiatra-verde-2 font-bold h-full text-center">
              {servicio.id || ''}
            </div>
            <div className="w-2/12 flex justify-center items-center text-white font-bold h-full text-center p-1">
              <Input
                onChange={(e) =>
                  modificarValoresServicios(index, 'nombre', e.target.value)
                }
                estilosContenedor={'w-full'}
                estilosInput={clsx(
                  'appearance-none rounded-xl relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
                  {
                    'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
                      !contextoGestionOtrosServicios.datosServicios[index]
                        .nombre ||
                      !contextoGestionOtrosServicios.datosServicios[index]
                        .noEstaDuplicado,
                    'border-cendiatra text-cendiatra-gris-3 focus:border-cendiatra':
                      contextoGestionOtrosServicios.datosServicios[index]
                        .nombre &&
                      contextoGestionOtrosServicios.datosServicios[index]
                        .noEstaDuplicado,
                  }
                )}
                tipo={'text'}
                placeholder={'DESCRIPCION'}
                titulo={''}
                valor={servicio.nombre}
              />
            </div>
            <div className="w-1/12 flex justify-center items-center text-cendiatra-verde-2 font-bold h-full text-center p-1">
              <ListaDesplegable
                estilosContenedor={'w-full'}
                estilosLista={clsx(
                  'appearance-none rounded-xl relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
                  {
                    'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
                      !contextoGestionOtrosServicios.datosServicios[index]
                        .tipoServicioId,
                    'border-cendiatra text-cendiatra-gris-3 focus:border-cendiatra':
                      contextoGestionOtrosServicios.datosServicios[index]
                        .tipoServicioId,
                  }
                )}
                titulo={''}
                opciones={TIPO_SERVICIOS}
                valor={servicio.tipoServicioId}
                onChange={(e) =>
                  modificarValoresServicios(
                    index,
                    'tipoServicioId',
                    e.target.value
                  )
                }
              />
            </div>
            <div className="w-2/12 flex justify-center items-center text-white font-bold h-full text-center p-1 ">
              <CampoFiltrarEspañol
                estilosPersonalizados={'w-full -mt-1 text-cendiatra-gris-3'}
                titulo={''}
                estilosInput={obtenerEstilosInputCups(
                  contextoGestionOtrosServicios.datosServicios[index],
                  CATEGORIA_SERVICIOS
                )}
                tipo={'text'}
                placeholder={'Autocompletar'}
                valorDelCampoFiltro={servicio.cup}
                informacionFiltrada={cupsFiltrados}
                handleChange={(e) =>
                  manejarCambioCups(index, 'cup', e.target.value)
                }
                handleOptionChange={(cup) => manejarSeleccionarCup(index, cup)}
                desactivarOtroFiltro={false}
              />
            </div>
            <div className="w-1/12 flex justify-center items-center text-white font-bold h-full text-center p-1">
              <Input
                onChange={(e) =>
                  modificarValoresServicios(index, 'cantidad', e.target.value)
                }
                estilosContenedor={'w-full'}
                estilosInput={clsx(
                  'appearance-none rounded-xl relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
                  {
                    'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
                      !contextoGestionOtrosServicios.datosServicios[index]
                        .cantidad,
                    'border-cendiatra text-cendiatra-gris-3 focus:border-cendiatra':
                      contextoGestionOtrosServicios.datosServicios[index]
                        .cantidad,
                  }
                )}
                tipo={'number'}
                placeholder={'DESCRIPCION'}
                titulo={''}
                valor={servicio.cantidad}
              />
            </div>
            <div
              className={clsx(
                'w-6% flex justify-center items-center text-white font-bold h-full text-center p-1',
                {
                  'pointer-events-none':
                    parseInt(
                      contextoGestionOtrosServicios.datosServicios[index]
                        .tipoServicioId
                    ) === CATEGORIA_SERVICIOS.TIQUETERA,
                }
              )}
            >
              <BotonOpcion
                titulo={''}
                estaActivo={servicio.tieneRangos}
                centrarLabel={true}
                funcionClick={() =>
                  modificarValoresServicios(
                    index,
                    'tieneRangos',
                    !servicio.tieneRangos
                  )
                }
              />
            </div>
            <div className="w-7% flex justify-center items-center text-white font-bold h-full text-center p-1">
              <InputPorcentaje
                estilosContenedor={'w-full'}
                estilosInput={obtenerEstilosInputIva(
                  contextoGestionOtrosServicios.datosServicios[index],
                  CATEGORIA_SERVICIOS
                )}
                deshabilitarEscalaDecimal={true}
                placeholder={
                  parseInt(
                    contextoGestionOtrosServicios.datosServicios[index]
                      ?.tipoServicioId
                  ) === CATEGORIA_SERVICIOS.TIQUETERA
                    ? 'N/A'
                    : 'DESCRIPCIÓN'
                }
                onValueChange={(e) =>
                  modificarValoresServicios(index, 'iva', e.floatValue)
                }
                valor={servicio.iva || ''}
              />
            </div>
            <div className="w-7% flex justify-center items-center text-white font-bold h-full text-center p-1">
              <InputMoneda
                estilosContenedor={'w-full'}
                estilosInput={clsx(
                  'appearance-none rounded-xl relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
                  {
                    'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
                      !contextoGestionOtrosServicios.datosServicios[index]
                        .valor && !servicio.tieneRangos,
                    'border-cendiatra':
                      contextoGestionOtrosServicios.datosServicios[index]
                        .valor || servicio.tieneRangos,
                    'text-white bg-cendiatra-gris-1 pointer-events-none':
                      servicio.tieneRangos,
                    'text-cendiatra-gris-3': !servicio.tieneRangos,
                  }
                )}
                onValueChange={(e) =>
                  modificarValoresServicios(index, 'valor', e.floatValue)
                }
                placeholder={servicio.tieneRangos ? 'N/A' : 'DESCRIPCIÓN'}
                valor={servicio.valor}
              />
            </div>
            <div className="w-2/12 flex justify-center items-center text-white font-bold h-full text-center p-1 right">
              <ListaDesplegable
                estilosContenedor={'w-full'}
                estilosLista={clsx(
                  'appearance-none rounded-xl relative block w-full p-1 border rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10',
                  {
                    'border-cendiatra-rojo-1 text-cendiatra-gris-3 focus:border-cendiatra-rojo-1':
                      !contextoGestionOtrosServicios.datosServicios[index]
                        .cuentaContableId,
                    'border-cendiatra text-cendiatra-gris-3 focus:border-cendiatra':
                      contextoGestionOtrosServicios.datosServicios[index]
                        .cuentaContableId,
                  }
                )}
                titulo={''}
                opciones={cuentasDeingreso}
                valor={servicio.cuentaContableId}
                onChange={(e) =>
                  modificarValoresServicios(
                    index,
                    'cuentaContableId',
                    e.target.value
                  )
                }
              />
            </div>
            <div className="absolute right-80 left">
              {contextoGestionOtrosServicios.datosServicios[index]
                .tieneRangos ? (
                <BotonConImagen
                  estilosContenedor={'w-full relative left-40 bottom-1'}
                  estilosImagen={'h-9 w-9'}
                  imagen={expandir}
                  medio={true}
                  funcionAEjecutar={() => mostrarOEsconderPrecios(index)}
                  rotacion={filaSeleccionada === index ? '180' : '0'}
                  textoAlternativo={'expandir'}
                />
              ) : null}
            </div>
            <div className="absolute right-81">
              <BotonConImagen
                estilosContenedor={'w-full relative left-52 bottom-2'}
                imagen={BotonQuitar}
                textoAlternativo={'quitar'}
                funcionAEjecutar={() =>
                  mostrarModalConfirmacionEliminacion(
                    index,
                    servicio.id,
                    servicio.nombre
                  )
                }
                estilosImagen={'h-7 w-7'}
              />
            </div>
          </div>
          <div
            className={clsx('w-full flex justify-center items-center -mt-5', {
              'border border-cendiatra border-b-0 border-t-0':
                filaSeleccionada === index &&
                contextoGestionOtrosServicios.datosServicios[index].tieneRangos,
            })}
          >
            <div className="w-2/12 flex justify-center items-center mt-2">
              <div className="w-full flex justify-center items-center text-center mt-5 z-50">
                <AlertaDinamica
                  mostrarAlerta={
                    !servicio.noEstaDuplicado || !servicio.cupsNoExiste
                  }
                  mensaje={`${!servicio.noEstaDuplicado ? 'El Nombre' : ''} ${
                    !servicio.noEstaDuplicado && !servicio.cupsNoExiste
                      ? 'y'
                      : ''
                  } ${!servicio.cupsNoExiste ? 'CUPS' : ''} ya existe`}
                  background={
                    'bg-cendiatra-gris-5 border border-cendiatra-rojo-1'
                  }
                  color={'text-cendiatra-rojo-1'}
                />
              </div>
            </div>
          </div>
          <FormularioAgregarRangos
            desplegarFila={filaSeleccionada === index}
            tieneRangos={
              contextoGestionOtrosServicios.datosServicios[index].tieneRangos
            }
            indice={index}
            datos={contextoGestionOtrosServicios.datosServicios}
            setDatos={contextoGestionOtrosServicios.setDatosServicios}
          />
        </>
      ))}
      <div className="w-full flex justify-end items-center mt-5">
        <span className="text-cendiatra font-semibold">Agregar Servicio</span>
        <BotonConImagen
          estilosContenedor={'w-7 '}
          imagen={BotonAgregar}
          textoAlternativo={'quitar'}
          funcionAEjecutar={agregarServicioAlListado}
          estilosImagen={'h-7 w-7 -mt-1 ml-10'}
        />
      </div>
    </div>
  )
}

export default FilaDatosOtrosServicios
