import React, {useState, useEffect, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {
  ObtenerDepartamentoPorCiudad,
  ObtenerDepartamentosPorNombre,
  ObtenerCiudadesPorDepartamento,
  ObtenerEmpresaPorNit,
} from '../../cache/servicios-cache'
import {BotonesFormularioPasos} from '../Botones'
import {ContextoEmpresa} from '../../contexto'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {
  regexCorreoElectronico,
  regexTextoCaracteresBasicos,
  regexNombreEmpresaHC,
  regexDireccion,
  regexSoloLetras,
  TEXTO_VISUAL,
} from '../../constantes'
import {Input, ErroresInput, ListaDesplegable} from '../inputs'

export default function FormularioDatosEmpresa({
  numPag,
  modoSoloLectura = false,
}) {
  const contextoEmpresa = useContext(ContextoEmpresa)

  const [departamentosFiltrados, setDepartamentosFiltrados] = useState([])
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([])

  const {
    register,
    setValue,
    clearErrors,
    setError,
    trigger,
    getValues,
    reset,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {
      activateCompany: true,
      pais: 1,
      correo: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [])

  useEffect(() => {
    if (numPag === contextoEmpresa.numeroPagina) {
      trigger()
      contextoEmpresa.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
    contextoEmpresa.setformularioDatos(getValues())
  }, [isValid, contextoEmpresa.numeroPagina])

  useEffect(() => {
    reset(contextoEmpresa.formularioDatos)
    setValue('pais', 1)
    trigger()
  }, [contextoEmpresa.actualizar])

  const manejarDepartamentoCambiado = (e) => {
    contextoEmpresa.setformularioDatos({
      ...contextoEmpresa.formularioDatos,
      departamento: e.target.value,
    })
    setError('departamento', {type: 'require', message: ''})
    setValue('departamento')
    if (e.target.value.length >= 3) {
      ObtenerDepartamentosPorNombre(e.target.value).then((res) => {
        if (res) {
          setDepartamentosFiltrados(
            res.map((departamento) => ({
              id: departamento.Id,
              filtro: departamento.Nombre,
            }))
          )
        }
      })
    } else {
      setDepartamentosFiltrados([])
    }
  }
  useEffect(() => {
    const subscription = watch(() => {
      contextoEmpresa.setformularioDatos(getValues())
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const manejarDepartamentoSeleccionado = (departamentoSeleccionado) => {
    contextoEmpresa.setformularioDatos({
      ...contextoEmpresa.formularioDatos,
      departamento: departamentoSeleccionado.filtro,
      ciudad: '',
    })
    setValue('departamento', departamentoSeleccionado.filtro)
    clearErrors('departamento')
    setError('ciudad', {type: 'require', message: ''})
    setValue('ciudad')
    setDepartamentosFiltrados([])
    setCiudadesFiltradas([])
  }

  const manejarCiudadCambiada = (e) => {
    contextoEmpresa.setformularioDatos({
      ...contextoEmpresa.formularioDatos,
      ciudad: e.target.value,
    })
    setError('ciudad', {type: 'require', message: ''})
    setValue('ciudad')
    if (e.target.value.length >= 3) {
      ObtenerCiudadesPorDepartamento(
        e.target.value,
        contextoEmpresa.formularioDatos.departamento
      ).then((res) => {
        if (res) {
          setCiudadesFiltradas(
            res.map((ciudad) => ({
              id: ciudad.Id,
              filtro: ciudad.Nombre,
            }))
          )
        }
      })
    } else {
      setCiudadesFiltradas([])
    }
  }

  const manejarCiudadSeleccionada = (ciudadSeleccionada) => {
    contextoEmpresa.setformularioDatos({
      ...contextoEmpresa.formularioDatos,
      ciudad: ciudadSeleccionada.filtro,
    })
    setValue('ciudad', ciudadSeleccionada.filtro)
    clearErrors('ciudad')
    setCiudadesFiltradas([])
    ObtenerDepartamentoPorCiudad(ciudadSeleccionada.filtro).then((res) => {
      setValue('departamento', res.Nombre)
      clearErrors('departamento')
    })
  }

  const validacionNit = async (nit) => {
    if (
      contextoEmpresa.formularioDatos.nit === nit ||
      contextoEmpresa.ultimoNitEmpresa === nit
    ) {
      return true
    }
    let empresaPorNit = await ObtenerEmpresaPorNit(nit)
    return empresaPorNit == undefined
  }

  return (
    <form className={`w-full flex flex-wrap justify-center my-5 `}>
      <div className="w-full">
        <div className="rounded-md -space-y-px flex justify-evenly flex-wrap items-center">
          <div className="w-full md:w-2/4 flex justify-start flex-wrap">
            <div className="w-6/12">
              <Controller
                name="nit"
                control={control}
                rules={{
                  required: true,
                  validate: validacionNit,
                }}
                render={({field: {onChange, value}}) => (
                  <>
                    <Input
                      onChange={onChange}
                      estilosContenedor={'w-full'}
                      estilosInput={
                        Object.keys(errors).find((element) => element === 'nit')
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      tipo={'number'}
                      placeholder={'Ingrese número'}
                      titulo={'NIT*'}
                      valor={value ?? ''}
                      readOnly={modoSoloLectura}
                    />
                    <ErroresInput
                      erroresFormulario={errors}
                      nombre={'nit'}
                      tipoError={'validate'}
                      mensaje={'El nit especificado ya se encuentra registrado'}
                    />
                  </>
                )}
              />
            </div>
            <div className="w-1/12 flex flex-wrap text-center items-center">
              <span className="w-full text-cendiatra text-5xl mt-3">-</span>
            </div>
            <div className="w-5/12">
              <Controller
                name="verificacionNit"
                control={control}
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}}) => (
                  <>
                    <Input
                      onChange={onChange}
                      estilosContenedor={'w-full'}
                      estilosInput={
                        Object.keys(errors).find(
                          (element) => element === 'verificacionNit'
                        )
                          ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                          : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                      }
                      tipo={'number'}
                      placeholder={'Ingrese número'}
                      titulo={'Cod. verificación NIT*'}
                      valor={value ?? ''}
                      readOnly={modoSoloLectura}
                    />
                  </>
                )}
              />
            </div>
          </div>

          <div className="w-full md:w-1/4 flex justify-center flex-wrap">
            <Controller
              name="codigoInternoEmpresa"
              control={control}
              rules={{
                required: false,
              }}
              render={({field: {onChange}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      'appearance-none -mt-0.5 pointer-events-none rounded-none relative block w-full px-3 py-1.5 border border-gris bg-gris placeholder-white text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2 bg-gris'
                    }
                    tipo={'number'}
                    titulo={'Código interno empresa'}
                    valor={contextoEmpresa.codigoInternoEmpresa}
                    readOnly={modoSoloLectura}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-between flex-wrap items-center">
          <div className="w-full md:w-6/12 flex justify-start flex-wrap">
            <Controller
              name="razonSocial"
              control={control}
              rules={{
                required: true,
                pattern: regexNombreEmpresaHC,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'razonSocial'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Razón social*'}
                    valor={value ?? ''}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'razonSocial'}
                    tipoError={'pattern'}
                    mensaje={
                      TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1
                    }
                  />
                </>
              )}
            />
          </div>
          <div className="w-full md:w-custom flex justify-start flex-wrap">
            <Controller
              name="nombreEmpresaHC"
              control={control}
              rules={{
                required: true,
                pattern: regexNombreEmpresaHC,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'nombreEmpresaHC'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Nombre empresa impresiones HC*'}
                    valor={value ?? ''}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'nombreEmpresaHC'}
                    tipoError={'pattern'}
                    mensaje={
                      TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1
                    }
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-between flex-wrap items-center">
          <div className="w-full md:w-4/12 flex justify-start flex-wrap">
            <Controller
              name="direccion"
              control={control}
              rules={{
                required: true,
                pattern: regexDireccion,
              }}
              render={({field: {onChange, value}}) => (
                <>
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'direccion'
                      )
                        ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                        : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                    }
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Dirección*'}
                    valor={value ?? ''}
                    readOnly={modoSoloLectura}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'direccion'}
                    tipoError={'pattern'}
                    mensaje={'Se permiten estos caracteres + ( ) [ ] , / - . #'}
                  />
                </>
              )}
            />
          </div>
          <div className="w-full md:w-4/12 flex justify-start flex-wrap">
            <Controller
              name="pais"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <ListaDesplegable
                  funcion={null}
                  estilosContenedor={'w-full'}
                  estilosLista={
                    Object.keys(errors).find((element) => element === 'pais')
                      ? 'appearance-none rounded w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  onChange={onChange}
                  titulo={'País*'}
                  opciones={[{Id: 1, Nombre: 'Colombia'}]}
                  valor={value ? value : contextoEmpresa.formularioDatos?.pais}
                  readOnly={modoSoloLectura}
                />
              )}
            />
          </div>
          <div className="w-full md:w-3/12 flex justify-start flex-wrap">
            <Controller
              name="departamento"
              control={control}
              rules={{
                required: true,
                pattern: regexTextoCaracteresBasicos,
              }}
              render={({field: {ref, ...rest}}) => (
                <>
                  <CampoFiltrarEspañol
                    {...rest}
                    titulo={'Departamento*'}
                    estilosPersonalizados={'w-full'}
                    textoTitulo={'text-sm font-medium'}
                    estilosInput={
                      Object.keys(errors).find(
                        (element) => element === 'departamento'
                      )
                        ? 'border-red-600'
                        : 'border-cendiatra'
                    }
                    tipo={'text'}
                    valorDelCampoFiltro={
                      contextoEmpresa.formularioDatos.departamento
                    }
                    placeholder={'Autocompletar'}
                    desactivarOtroFiltro={''}
                    informacionFiltrada={departamentosFiltrados}
                    handleChange={manejarDepartamentoCambiado}
                    handleOptionChange={manejarDepartamentoSeleccionado}
                    id={1}
                    readOnly={modoSoloLectura}
                  />
                </>
              )}
            />
          </div>
        </div>
        <div className="rounded-md -space-y-px flex justify-center gap-10 flex-wrap items-center">
          <div className="w-full md:w-4/12 flex justify-start flex-wrap">
            <Controller
              name="ciudad"
              control={control}
              rules={{
                required: true,
                pattern: regexSoloLetras,
              }}
              render={({field: {ref, ...rest}}) => (
                <CampoFiltrarEspañol
                  {...rest}
                  titulo={'Ciudad*'}
                  estilosPersonalizados={'w-full'}
                  textoTitulo={'text-sm font-medium'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'ciudad')
                      ? 'border-red-600'
                      : 'border-cendiatra'
                  }
                  tipo={'text'}
                  valorDelCampoFiltro={contextoEmpresa.formularioDatos.ciudad}
                  desactivarOtroFiltro={''}
                  informacionFiltrada={ciudadesFiltradas}
                  handleChange={manejarCiudadCambiada}
                  handleOptionChange={manejarCiudadSeleccionada}
                  placeholder={'Autocompletar'}
                  id={1}
                  readOnly={modoSoloLectura}
                />
              )}
            />
          </div>
          <div className="w-full md:w-4/12 flex justify-start flex-wrap">
            <Controller
              name="telefono"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) => element === 'telefono'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'number'}
                  placeholder={'Descripción'}
                  titulo={'Teléfono*'}
                  valor={value ?? ''}
                  readOnly={modoSoloLectura}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="pt-6 w-full flex justify-center items-center">
        <BotonesFormularioPasos contexto={contextoEmpresa} />
      </div>
    </form>
  )
}
