import Dexie from 'dexie'
import {
  ObtenerCacheComercial,
  ObtenerSedesCendiatra,
  ObtenerValoresDeReferencia,
  ObtenerLocalidades,
  ObtenerCIE10,
  ObtenerValoresDeReferenciaGato,
} from '../../microservicios/Referencia'
import {dbComun} from '../bases-datos/dbComun'
import {obtenerVersionActual} from './Version'
import {ObtenerCuentasDeIngreso} from '../../microservicios/Servicio'

export const PoblarCacheComun = async (nuevaVersion) => {
  await dbComun.open().then(async () => {
    let version = await obtenerVersionActual(nuevaVersion)
    if (version && !nuevaVersion) return

    await dbComun.table('Version').clear()
    await dbComun.Departamentos.clear()
    await dbComun.Ciudades.clear()
    await dbComun.Cups.clear()
    await dbComun.Sedes.clear()
    await dbComun.Aliados.clear()
    await dbComun.SedesCendiatra.clear()
    await dbComun.GrupoSanguineo.clear()
    await dbComun.EstadoCivil.clear()
    await dbComun.EstadoFactura.clear()
    await dbComun.Escolaridad.clear()
    await dbComun.Raza.clear()
    await dbComun.MediosPago.clear()
    await dbComun.Sexo.clear()
    await dbComun.Zona.clear()
    await dbComun.Estrato.clear()
    await dbComun.IndicativoTelefono.clear()
    await dbComun.FacturarA.clear()
    await dbComun.TipoExamen.clear()
    await dbComun.Eps.clear()
    await dbComun.Arl.clear()
    await dbComun.TipoAtencion.clear()
    await dbComun.TipoDocumento.clear()
    await dbComun.FondoPension.clear()
    await dbComun.Localidades.clear()
    await dbComun.Parentesco.clear()
    await dbComun.TipoTurno.clear()
    await dbComun.EstadoTurno.clear()
    await dbComun.EstadoServicioAtencion.clear()
    await dbComun.EstadoAtencion.clear()
    await dbComun.TipoFacturacion.clear()
    await dbComun.TurnoTrabajo.clear()
    await dbComun.RiesgosFisicos.clear()
    await dbComun.RiesgosBiologicos.clear()
    await dbComun.RiesgosErgonomicos.clear()
    await dbComun.RiesgosLocativos.clear()
    await dbComun.RiesgosNaturales.clear()
    await dbComun.RiesgosSeguridad.clear()
    await dbComun.OtrosRiesgos.clear()
    await dbComun.ConceptoDeAptitud.clear()
    await dbComun.Antecedentes.clear()
    await dbComun.AntecedentesToxicologicos.clear()
    await dbComun.OrganosSistema.clear()
    await dbComun.EstadosOrganoSistema.clear()
    await dbComun.EstadosTunelCarpiano.clear()
    await dbComun.Recomendaciones.clear()
    await dbComun.VigilanciasEpidemiologicas.clear()
    await dbComun.Restricciones.clear()
    await dbComun.ClasificacionExamenFisico.clear()
    await dbComun.Cie11.clear()
    await dbComun.TipoCampo.clear()
    await dbComun.ClasificacionServicio.clear()
    await dbComun.DuracionCita.clear()
    await dbComun.GrupoLaboratorio.clear()
    await dbComun.ObservacionesPreparacion.clear()
    await dbComun.Estructura.clear()
    await dbComun.CausaNotaCredito.clear()
    await dbComun.MotivoModificacionAtencion.clear()
    await dbComun.CuentaDeIngreso.clear()
    await dbComun.ActividadEconomica.clear()
    await ObtenerCacheComercial().then((res) => {
      //Agregar version
      dbComun
        .table('Version')
        .put({Numero: res.data.version, Fecha: res.data.fechaVersion})
        .catch(Dexie.DataError, function (e) {
          console.error(
            'Error al agregar la versión al caché común: ' + (e.stack || e)
          )
        })

      //Agregar departamentos
      dbComun.Departamentos.bulkAdd(
        res.data.departamentos.map((departamento) => ({
          Id: departamento.id,
          Nombre: departamento.nombre,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar departamentos al caché común: ' + (e.stack || e)
        )
      })

      //Agregar ciudades
      dbComun.Ciudades.bulkAdd(
        res.data.ciudades.map((ciudad) => ({
          Id: ciudad.id,
          Nombre: ciudad.nombre,
          DepartamentoId: ciudad.departamentoId,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar ciudades al caché común: ' + (e.stack || e)
        )
      })

      //Agregar cups
      dbComun.Cups.bulkAdd(
        res.data.cups.map((cup) => ({
          Id: cup.id,
          Codigo: cup.codigo,
          Nombre: cup.nombre,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error('Error al agregar cups al caché común: ' + (e.stack || e))
      })

      //Agregar sedes
      dbComun.Sedes.bulkAdd(
        res.data.sedes.map((sede) => ({
          Id: sede.id,
          Nombre: sede.nombre,
          CiudadId: sede.ciudadId,
          Grupo: sede.grupo,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar sedes al caché común: ' + (e.stack || e)
        )
      })

      //Agregar aliados
      dbComun.Aliados.bulkAdd(
        res.data.aliados.map((aliado) => ({
          Id: aliado.id,
          Nombre: aliado.nombre,
          CiudadId: aliado.ciudadId,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar aliados al caché común: ' + (e.stack || e)
        )
      })
    })
    await ObtenerSedesCendiatra().then((res) => {
      dbComun.SedesCendiatra.bulkAdd(
        res.data.map((sede) => ({
          Id: sede.id,
          Nombre: sede.nombre,
          CiudadId: sede.ciudadId,
          Ciudad: sede.ciudad,
          Grupo: sede.grupo,
          Direccion: sede.direccion,
          UrlLogo: sede.urlLogo,
          Telefono: sede.telefono,
          Recepciones: sede.recepciones,
          Consultorios: sede.consultorios,
        }))
      )
    })

    await ObtenerValoresDeReferencia(
      'GrupoSanguineo,EstadoCivil,EstadoFactura,Escolaridad,Raza,MediosPago,Sexo,Zona,Estrato,IndicativoTelefono,FacturarA,TipoExamen,Eps,Arl,TipoAtencion,TipoDocumento,FondoPension,ParentescoFamiliar,Tipoturno,EstadoTurno,EstadoServicioAtencion,EstadoAtencion,TipoFacturacion,TurnoTrabajo,RiesgosFisicos,RiesgosBiologicos,RiesgosErgonomicos,RiesgosLocativos,RiesgosNaturales,RiesgosSeguridad,OtrosRiesgos,ConceptoDeAptitud,Antecedentes,AntecedentesToxicologicos,OrganoSistema,EstadoOrganoSistema,EstadoTunelCarpiano,Recomendaciones,VigilanciaEpidemiologica,Restricciones,ClasificacionExamenFisico,TipoCampo,ClasificacionServicio,DuracionCita,GrupoLaboratorio,ObservacionesPreparacion,Estructura,CausaNotaCredito,MotivoModificacionAtencion, ActividadEconomica'
    ).then((res) => {
      dbComun.GrupoSanguineo.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'GrupoSanguineo')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.EstadoCivil.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EstadoCivil')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.EstadoFactura.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EstadoFactura')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Escolaridad.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Escolaridad')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Raza.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Raza')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.MediosPago.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'MediosPago')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Sexo.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Sexo')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Zona.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Zona')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Estrato.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Estrato')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.IndicativoTelefono.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'IndicativoTelefono')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.FacturarA.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'FacturarA')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.TipoExamen.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'TipoExamen')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Eps.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EPS')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Arl.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'ARL')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.TipoAtencion.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'TipoAtencion')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.TipoDocumento.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'TipoDocumento')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.FondoPension.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'FondoPension')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Parentesco.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'ParentescoFamiliar')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.TipoTurno.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'TipoTurno')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.EstadoTurno.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EstadoTurno')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.EstadoServicioAtencion.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EstadoServicioAtencion')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.EstadoAtencion.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EstadoAtencion')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.TipoFacturacion.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'TipoFacturacion')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.TurnoTrabajo.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'TurnoTrabajo')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.RiesgosFisicos.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'RiesgosFisicos')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.RiesgosBiologicos.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'RiesgosBiologicos')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.RiesgosErgonomicos.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'RiesgosErgonomicos')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.RiesgosLocativos.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'RiesgosLocativos')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.RiesgosNaturales.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'RiesgosNaturales')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.RiesgosSeguridad.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'RiesgosSeguridad')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.OtrosRiesgos.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'OtrosRiesgos')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.ConceptoDeAptitud.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'ConceptoDeAptitud')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Antecedentes.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Antecedentes')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.AntecedentesToxicologicos.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'AntecedentesToxicologicos')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.OrganosSistema.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'OrganoSistema')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.EstadosOrganoSistema.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EstadoOrganoSistema')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.EstadosTunelCarpiano.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'EstadoTunelCarpiano')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Recomendaciones.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Recomendaciones')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.VigilanciasEpidemiologicas.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'VigilanciaEpidemiologica')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Restricciones.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Restricciones')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.ClasificacionExamenFisico.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'ClasificacionExamenFisico')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.TipoCampo.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'TipoCampo')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.ClasificacionServicio.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'ClasificacionServicio')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.DuracionCita.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'DuracionCita')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.GrupoLaboratorio.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'GrupoLaboratorio')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.ObservacionesPreparacion.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'ObservacionesPreparacion')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.Estructura.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'Estructura')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.CausaNotaCredito.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'CausaNotaCredito')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.MotivoModificacionAtencion.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo == 'MotivoModificacionAtencion')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )

      dbComun.ActividadEconomica.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo === 'ActividadEconomica')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )
    })

    await ObtenerValoresDeReferenciaGato('ActividadEconomica').then((res) => {
      dbComun.ActividadEconomica.bulkAdd(
        res.data.listaReferencia
          .filter((elemento) => elemento.tipo === 'ActividadEconomica')
          .map((elemento) => ({
            Id: elemento.identificacion,
            Nombre: elemento.nombre,
          }))
      )
    })

    await ObtenerLocalidades().then((res) => {
      dbComun.Localidades.bulkAdd(
        res.data.map((elemento) => ({
          Id: elemento.id,
          Nombre: elemento.nombre,
        }))
      )
    })

    await ObtenerCIE10().then((res) => {
      dbComun.Cie11.bulkAdd(
        res.data.map((elemento) => ({
          Id: elemento.id,
          Nombre: elemento.nombre,
          Cie10: elemento.cie_10,
        }))
      )
    })

    await ObtenerCuentasDeIngreso().then((res) => {
      dbComun.CuentaDeIngreso.bulkAdd(
        res.data.map((elemento) => ({
          Id: elemento.id,
          Codigo: elemento.codigoInterno,
          Nombre: elemento.nombre,
        }))
      )
    })
  })
}
