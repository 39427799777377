import React, {useState, useContext, useEffect} from 'react'
import {TEXTO_VISUAL} from '../../constantes'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {SeleccionPaquete} from '../../componentes/Paquetes'
import {FormularioCrearPaquete} from '../../componentes/Paquetes'
import {ObtenerPaquetePorId} from '../../microservicios/Servicio'
import {ContextoPaquete} from '../../contexto'
import {ObtenerServicioPorId} from '../../cache/servicios-cache/Servicios'
import {ModificarPaquete} from '../../microservicios/Servicio'
import {ObtenerCuentaDeIngresoPorId} from '../../microservicios'
import {ContextApplication} from '../../contexto'
import {useModal} from '../../hooks/useModal'
import {RUTAS_APP} from '../../constantes'
import {useHistory} from 'react-router-dom'
import {
  AgregarPaquetesACache,
  AgregarServiciosACache,
} from '../../cache/servicios-cache'
import {useMsal} from '@azure/msal-react'
import {ROLES_APP} from '../../constantes/roles.js'

const EditarPaquete = () => {
  const {accounts} = useMsal()

  const rolUsuario = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const soloLectura = rolUsuario.includes(ROLES_APP.GERENTE_FINANCIERO)

  const [idPaqueteAEditar, setIdPaqueteAEditar] = useState(0)

  const contextoAplicacion = useContext(ContextApplication)

  const contextoPaquete = useContext(ContextoPaquete)

  const history = useHistory()

  const [cerrarModal] = useModal()

  const {setInformacionPaquete, setServiciosSeleccionados} = contextoPaquete

  useEffect(() => {
    const obtenerInformacionInicial = async () => {
      try {
        await AgregarPaquetesACache()
        await AgregarServiciosACache()
      } catch (error) {
        console.log(error)
      }
    }
    obtenerInformacionInicial()
  }, [])

  const obtenerDatosPaquete = async (id) => {
    try {
      const respuesta = await ObtenerPaquetePorId(id)
      if (respuesta.status === 200) {
        setInformacionPaquete({
          codigoPaquete: respuesta?.data?.codigoInterno,
          nombrePaquete: respuesta?.data?.nombre,
          cuentaIngreso: respuesta?.data?.cuentaIngresoId,
        })
        setIdPaqueteAEditar(respuesta?.data?.id)
        const serviciosEnPaquete = await Promise.all(
          respuesta?.data?.servicios.map(async (idServicio) => {
            const datosServicio = await ObtenerServicioPorId(idServicio)
            return {
              codigo: datosServicio?.CodigoCompleto,
              nombre: datosServicio?.Nombre,
              id: datosServicio?.Id,
            }
          })
        )

        setServiciosSeleccionados(serviciosEnPaquete)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const finalizarCreacion = () => {
    cerrarModal()
    history.push(RUTAS_APP?.MAIN)
  }
  const limpiarFormulario = () => {
    history.go(0)
  }

  const editarpaquete = async () => {
    const idsServicios = contextoPaquete.serviciosSeleccionados?.map(
      (elemento) => elemento.id
    )
    const modelo = {
      id: idPaqueteAEditar,
      codigoInterno: contextoPaquete?.informacionPaquete?.codigoPaquete,
      nombre: contextoPaquete?.informacionPaquete?.nombrePaquete?.toUpperCase(),
      cuentaIngresoId: contextoPaquete?.informacionPaquete?.cuentaIngreso,
      servicios: idsServicios,
      userEmail: '',
    }
    try {
      const respuesta = await ModificarPaquete(modelo)
      if (respuesta.status === 200) {
        const nombreCuentaDeIngreso = await ObtenerCuentaDeIngresoPorId(
          JSON.parse(contextoPaquete?.informacionPaquete?.cuentaIngreso)
        )

        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'DATOS PAQUETE ACTUALIZADOS',
          contenido: (
            <div className="w-full flex flex-wrap m-2">
              <span className="w-full text-cendiatra text-xl font-bold">
                ID: {idPaqueteAEditar}{' '}
              </span>
              <span className="w-full text-cendiatra text-xl font-bold">
                CÓDIGO PAQUETE:{' '}
                {contextoPaquete?.informacionPaquete?.codigoPaquete}{' '}
              </span>
              <span className="w-full text-cendiatra text-xl font-bold">
                NOMBRE PAQUETE:{' '}
                {contextoPaquete?.informacionPaquete?.nombrePaquete?.toUpperCase()}{' '}
              </span>
              <span className="w-full text-cendiatra text-xl font-bold">
                CUENTA DE INGRESO: {nombreCuentaDeIngreso?.Nombre}{' '}
              </span>
            </div>
          ),
          botones: [
            {
              nombre: 'Finalizar',
              click: finalizarCreacion,
            },
            {
              nombre: 'Regresar',
              click: limpiarFormulario,
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={
        idPaqueteAEditar && soloLectura
          ? TEXTO_VISUAL.EJECUTIVO_COMERCIAL.DETALLE_DEL_PAQUETE
          : TEXTO_VISUAL.EJECUTIVO_COMERCIAL.VER_PAQUETES
      }
    >
      {idPaqueteAEditar ? (
        <FormularioCrearPaquete
          nombreBoton={'Guardar'}
          funcionTransaccion={editarpaquete}
          paqueteIdEditar={idPaqueteAEditar}
          readOnly={soloLectura}
          funcionCerrar={() => setIdPaqueteAEditar(0)}
        />
      ) : (
        <SeleccionPaquete
          funcionObtenerDatos={obtenerDatosPaquete}
          readOnly={soloLectura}
        />
      )}
    </ContenedorPagina>
  )
}

export default EditarPaquete
