import {dbComun} from '../bases-datos/dbComun'

export const ObtenerActiviadEconomicaPorNombreOCodigo = async (filtro) => {
  let ActividadEconomicaPorNombre = (
    await dbComun
      .table('ActividadEconomica')
      .filter(
        ({Nombre}) => Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    id: elemento.Id,
    filtro: elemento.Nombre,
  }))
  return Promise.resolve(ActividadEconomicaPorNombre)
}

export const ObtenerActividadEconomicaPorId = (id) => {
  return Promise.resolve(dbComun.table('ActividadEconomica').get({Id: id}))
}
