import React, {useContext, useEffect} from 'react'
import {ContextApplication, ContextoEmpresa} from '../../contexto'
import {useHistory} from 'react-router-dom'
import closeImg from '../../imagenes/Salir.svg'
import {NavLink} from 'react-router-dom'
import SeleccionEmpresaHija from '../../componentes/Empresa/SeleccionEmpresaHija'
import FormularioAdicional from '../../componentes/Empresa/FormularioAdicional'
import FormularioFacturacion from '../../componentes/Empresa/FormularioFacturacion'
import FormularioDatosEmpresaHija from '../../componentes/Empresa/FormularioDatosEmpresaHija'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana'
import SeleccionEmpresaPadre from '../../componentes/Empresa/SeleccionEmpresaPadre'
import {ModificarEmpresaHija} from '../../microservicios/Empresa.js'
import {ObtenerCiudadPorNombre} from '../../cache/servicios-cache/Ciudades.js'
import ConfirmacionEmpresaHijaCreadaOModificada from '../../componentes/Empresa/ConfirmacionEmpresaHijaCreadaOModificada.jsx'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina.jsx'
import {TEXTO_VISUAL} from '../../constantes/textoVisual.js'
import {
  UseObtenerRolValido,
  transformarTipoFacturacion,
} from '../../utilidades/funcionesComunes'

const EditarEmpresaHija = () => {
  const contextoApp = useContext(ContextApplication)
  const contextoEmpresa = useContext(ContextoEmpresa)

  const history = useHistory()

  const listaPestanas = [
    'Selección empresa',
    'Listado empresas hijas',
    'Datos de la empresa',
    'Facturacíon',
    'Información adicional',
  ]

  useEffect(() => {
    contextoEmpresa.setformularioDatos({})
    contextoEmpresa.setformularioFacturacion({})
    contextoEmpresa.setformularioAdicional({estaActiva: true})
    contextoEmpresa.setNumeroPagina(1)
    contextoEmpresa.setTotalPaginas(5)

    return () => {
      contextoEmpresa.setformularioDatos({})
      contextoEmpresa.setformularioFacturacion({})
      contextoEmpresa.setformularioAdicional({estaActiva: true})
      contextoEmpresa.setFormularioUsuarioB2c({})
    }
  }, [])

  const modificarEmpresaHija = async () => {
    let ciudadId = (
      await ObtenerCiudadPorNombre(contextoEmpresa.formularioDatos.ciudad)
    )?.Id
    const contenido = {
      id: contextoEmpresa.idEmpresa ?? '',
      codigoInterno: contextoEmpresa.codigoInternoHija ?? '',
      nit: contextoEmpresa.formularioDatos.nit ?? '',
      verificacionNit: contextoEmpresa.formularioDatos.verificacionNit ?? '',
      nombre: contextoEmpresa.formularioDatos.razonSocial ?? '',
      direccion: contextoEmpresa.formularioDatos.direccion ?? '',
      ciudadId: ciudadId ?? '',
      telefono: contextoEmpresa.formularioDatos.telefono ?? '',
      correo: contextoEmpresa?.formularioDatos?.correo ?? '',
      nombreEmpresaHC: contextoEmpresa.formularioDatos.nombreEmpresaHC ?? '',
      estaActiva: contextoEmpresa.formularioAdicional.estaActiva ?? '',
      motivoDesactivacion:
        contextoEmpresa.formularioAdicional.motivoDesactivacion ?? '',
      ejecutivoComercial:
        contextoEmpresa.formularioAdicional.ejecutivoComercial ?? '',
      observaciones: contextoEmpresa.formularioAdicional.observaciones ?? '',
      empresaPadreId: contextoEmpresa.idEmpresaPadre ?? '',
      logoUrl: contextoEmpresa.logoEmpresa.url ?? '',
      facturacionEmpresa: {
        id: contextoEmpresa.idEmpresa ?? '',
        razonSocial: contextoEmpresa.formularioFacturacion.factura ?? '',
        rut53: contextoEmpresa.formularioFacturacion.RUT53 ?? '',
        rut54: contextoEmpresa.formularioFacturacion.RUT54 ?? '',
        codigoPostal: contextoEmpresa.formularioFacturacion.codigoPostal ?? '',
        actividadEconomica:
          contextoEmpresa.formularioFacturacion.actividadEconomica ?? '',
        actividadEconomicaId:
          contextoEmpresa.actividadEconomicaSeleccionada?.id ?? '',
        matriculaMercantil:
          contextoEmpresa.formularioFacturacion.matriculaMercantil ?? '',
        correoFacturacion:
          contextoEmpresa.formularioFacturacion.correoFacturacion ?? '',
        valorContrato:
          contextoEmpresa.formularioFacturacion.valorContrato ?? '',
        condicionPago:
          contextoEmpresa.formularioFacturacion.condicionesPago ?? null,
        descuento: contextoEmpresa.formularioFacturacion.descuento
          ? JSON.stringify(contextoEmpresa.formularioFacturacion.descuento)
          : '' ?? '',
        impuesto: '' ?? '',
        fechaCorte: contextoEmpresa.formularioFacturacion.fechaCorte ?? '',
        tipoFacturacion:
          transformarTipoFacturacion(
            contextoEmpresa.formularioFacturacion.tipoFacturacion
          ) ?? '',
        observaciones:
          contextoEmpresa.formularioFacturacion.observacionesFacturacion ?? '',
        nombreContacto:
          contextoEmpresa.formularioFacturacion.nombreContacto ?? '',
        correoContacto:
          contextoEmpresa.formularioFacturacion.correoContacto ?? '',
        telefonoContacto:
          contextoEmpresa.formularioFacturacion.telefonoContacto ?? '',
        condicionesPrefactura:
          contextoEmpresa.formularioFacturacion.condicionesPrefactura ?? null,
        modalidadFacturacion:
          contextoEmpresa.formularioFacturacion.modalidadFacturacion ?? null,
        prefactura: contextoEmpresa.formularioFacturacion.prefactura ?? false,
        licitacion: contextoEmpresa.formularioFacturacion.licitacion ?? false,
      },
    }
    await ModificarEmpresaHija(contenido)
      .then((res) => {
        if (res.status === 200) {
          contextoApp.setModal({
            abierto: true,
            titulo: 'DATOS ACTUALIZADOS EMPRESA HIJA',
            contenido: (
              <ConfirmacionEmpresaHijaCreadaOModificada
                id={contextoEmpresa.idEmpresa}
                codigoInterno={contextoEmpresa.codigoInternoHija}
                nombreEmpresa={contextoEmpresa.formularioDatos.razonSocial}
              />
            ),
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarCerrarModalConfirmacion,
              },
              {
                nombre: 'Regresar',
                click: manejarEditarNueva,
              },
            ],
          })
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoApp.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  const manejarEditarNueva = () => {
    history.go(0)
  }

  const rolValido = UseObtenerRolValido()

  const claseBloqueo = rolValido ? 'pointer-events-none' : ''

  useEffect(() => {
    if (!rolValido || contextoEmpresa.numeroPagina < 3) return

    contextoEmpresa.setformularioActualTieneErrores(false)
  }, [contextoEmpresa, rolValido])

  return (
    <ContenedorPagina
      tituloPagina={
        rolValido
          ? TEXTO_VISUAL.EJECUTIVO_COMERCIAL.VER_EMPRESA_HIJA
          : TEXTO_VISUAL.EJECUTIVO_COMERCIAL.VER_Y_MODIFICAR_EMPRESA_HIJA
      }
    >
      <BarraEncabezadosPestana
        titulos={listaPestanas}
        contexto={contextoEmpresa}
      />

      {contextoEmpresa.numeroPagina === 1 ? (
        <div
          className={`rounded-md -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
        >
          <SeleccionEmpresaPadre numPag={1} mostrarImportar={false} />
        </div>
      ) : null}
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 2 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <SeleccionEmpresaHija numPag={2} />
      </div>
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 3 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <FormularioDatosEmpresaHija claseBloqueo={claseBloqueo} numPag={3} />
      </div>
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 4 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <FormularioFacturacion claseBloqueo={claseBloqueo} numPag={4} />
      </div>
      <div
        className={`rounded-md ${
          contextoEmpresa.numeroPagina === 5 ? '' : 'hidden'
        } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
      >
        <FormularioAdicional
          claseBloqueo={claseBloqueo}
          numPag={5}
          funcionSubmit={modificarEmpresaHija}
        />
      </div>
    </ContenedorPagina>
  )
}

export default EditarEmpresaHija
