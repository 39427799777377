import React, {useState, createContext} from 'react'

export const ContextoEmpresa = createContext('')

export const EmpresaConContexto = ({children}) => {
  const [idEmpresa, setIdEmpresa] = useState()
  const [codigoInternoEmpresa, setCodigoInternoEmpresa] = useState(0)
  const [idEmpresaPadre, setIdEmpresaPadre] = useState()
  const [codigoInternoHija, setcodigoInternoHija] = useState()
  const [actualizar, setActualizar] = useState({})
  const [formularioDatos, setformularioDatos] = useState({
    ciudad: '',
    departamento: '',
    pais: 1,
  })
  const [formularioFacturacion, setformularioFacturacion] = useState({})
  const [formularioUsuarioB2c, setFormularioUsuarioB2c] = useState({})
  const [formularioAdicional, setformularioAdicional] = useState({
    estaActiva: true,
  })
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [totalPaginas, setTotalPaginas] = useState(3)
  const [formularioActualTieneErrores, setformularioActualTieneErrores] =
    useState(true)
  const [logoEmpresa, setLogoEmpresa] = useState({nombre: '', url: ''})
  const [cambiarColorMensaje, setcambiarColorMensaje] = useState('')
  const [modoEdicion, setModoEdicion] = useState(false)
  const [ultimoNitEmpresa, setUltimoNitEmpresa] = useState(0)
  const [actividadEconomicaSeleccionada, setActividadEconomicaSeleccionada] = useState({
    id: '',
    nombre: '',
  })

  return (
    <ContextoEmpresa.Provider
      value={{
        idEmpresa: idEmpresa,
        codigoInternoEmpresa: codigoInternoEmpresa,
        setCodigoInternoEmpresa: setCodigoInternoEmpresa,
        setIdEmpresa: setIdEmpresa,
        idEmpresaPadre: idEmpresaPadre,
        setIdEmpresaPadre: setIdEmpresaPadre,
        codigoInternoHija: codigoInternoHija,
        setcodigoInternoHija: setcodigoInternoHija,
        actualizar: actualizar,
        setActualizar: setActualizar,
        formularioDatos: formularioDatos,
        setformularioDatos: setformularioDatos,
        formularioFacturacion: formularioFacturacion,
        setformularioFacturacion: setformularioFacturacion,
        formularioAdicional: formularioAdicional,
        setformularioAdicional: setformularioAdicional,
        numeroPagina: numeroPagina,
        setNumeroPagina: setNumeroPagina,
        totalPaginas: totalPaginas,
        setTotalPaginas: setTotalPaginas,
        formularioActualTieneErrores: formularioActualTieneErrores,
        setformularioActualTieneErrores: setformularioActualTieneErrores,
        logoEmpresa: logoEmpresa,
        setLogoEmpresa: setLogoEmpresa,
        cambiarColorMensaje: cambiarColorMensaje,
        setcambiarColorMensaje: setcambiarColorMensaje,
        modoEdicion: modoEdicion,
        setModoEdicion: setModoEdicion,
        ultimoNitEmpresa: ultimoNitEmpresa,
        setUltimoNitEmpresa: setUltimoNitEmpresa,
        formularioUsuarioB2c: formularioUsuarioB2c,
        setFormularioUsuarioB2c: setFormularioUsuarioB2c,
        actividadEconomicaSeleccionada: actividadEconomicaSeleccionada,
        setActividadEconomicaSeleccionada: setActividadEconomicaSeleccionada,
      }}
    >
      {children}
    </ContextoEmpresa.Provider>
  )
}
