import React, {useContext, useState, useEffect, useMemo} from 'react'
import {ContextoFormulario} from '../../../contexto'
import {Input} from '../../inputs'

const CampoRango = ({numeroCampo, numeroPestana, numeroSeccion, readOnly}) => {
  const contextoFormulario = useContext(ContextoFormulario)
  const [camposRangos, setCamposRangos] = useState({})
  const campo = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)
    .campos.find((x) => x.numeroCampo == numeroCampo)

  const pestana = contextoFormulario.plantilla.pestanas.find(
    (x) => x.numeroPestana == numeroPestana
  )

  const seccion = contextoFormulario.plantilla.pestanas
    .find((x) => x.numeroPestana == numeroPestana)
    .secciones.find((x) => x.numeroSeccion == numeroSeccion)

  const accesoPestana = useMemo(
    () => pestana?.id ?? pestana?.tituloPestana ?? '',
    [pestana]
  )
  const accesoSeccion = useMemo(
    () => seccion?.id ?? seccion?.nombreSeccion ?? '',
    [seccion]
  )

  const [valorCampo, setValorCampo] = useState('')

  const valorCampoReferencia = useMemo(() => {
    const seccionesPestana =
      contextoFormulario.valoresCampos?.[accesoPestana] ?? {}

    let valor = ''
    for (const seccion in seccionesPestana) {
      for (const campoSeccion in seccionesPestana[seccion]) {
        if (
          campo.atributos?.campoReferencia?.toUpperCase() ===
          campoSeccion.toUpperCase()
        ) {
          valor =
            contextoFormulario.valoresCampos?.[accesoPestana]?.[seccion]?.[
              campoSeccion
            ]
        }
      }
    }
    return valor
  }, [contextoFormulario.valoresCampos?.[accesoPestana]])

  const limpiarValor = () => {
    setValorCampo('')
    contextoFormulario.setValoresCampos({
      ...contextoFormulario.valoresCampos,
      [accesoPestana]: {
        ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
        [accesoSeccion]: {
          ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
            accesoSeccion
          ] ?? {}),
          [campo.tituloCampo]: '',
        },
      },
    })
  }

  useEffect(() => {
    if (valorCampoReferencia) {
      let rangos = campo.atributos.listaRangos
      const parseado = +(+valorCampoReferencia).toFixed(2)
      let rangoActual = rangos.find(
        (x) => parseado >= x.rangoInferior && parseado <= x.rangoSuperior
      )

      if (rangoActual) {
        setValorCampo(rangoActual.valor)
        contextoFormulario.setValoresCampos({
          ...contextoFormulario.valoresCampos,
          [accesoPestana]: {
            ...(contextoFormulario.valoresCampos?.[accesoPestana] ?? {}),
            [accesoSeccion]: {
              ...(contextoFormulario.valoresCampos?.[accesoPestana]?.[
                accesoSeccion
              ] ?? {}),
              [campo.tituloCampo]: rangoActual.valor,
            },
          },
        })
      } else {
        limpiarValor()
      }
    } else {
      limpiarValor()
    }
  }, [campo, valorCampoReferencia])

  return (
    <Input
      estilosContenedor={'w-full'}
      estilosInput={
        'appearance-none rounded relative block w-full p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
      }
      tipo={'text'}
      placeholder={''}
      titulo={campo?.tituloCampo?.toUpperCase() ?? ''}
      valor={valorCampo}
      deshabilitado={true}
      readOnly={readOnly}
    />
  )
}

export default CampoRango
