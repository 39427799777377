import {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {ContenedorErrores} from '../../componentes/Contenedores/ContenedorErrores'
import {FormularioCorreccionFactura} from '../../componentes/gestionFacturacion/FormularioCorreccionFactura'
import {InformacionFactura} from '../../componentes/gestionFacturacion/InformacionFactura'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {RUTAS_APP} from '../../constantes/rutas/rutasApp'
import {TEXTO_VISUAL} from '../../constantes/textoVisual'
import {ContextApplication, ContextoGestionFacturacion} from '../../contexto'
import {useModal} from '../../hooks/useModal'
import {
  corregirFactura,
  obtenerErroresFacturaPorId,
} from '../../microservicios/Fachada'
import {useLocation} from 'react-router-dom'
import {useObtenerRol} from '../../utilidades'
import {ROLES_APP} from '../../constantes'

const TEXTO_PANTALLA =
  TEXTO_VISUAL.GESTION_FACTURACION
    .CORRECCION_ERRORES_TRANSMISION_FIRMA_ELECTRONICA

export const CorreccionErroresTransmisionFirmaElectronica = () => {
  const contextoAplicacion = useContext(ContextApplication)
  const contextoGestionFacturacion = useContext(ContextoGestionFacturacion)
  const history = useHistory()
  const [cerrarModal] = useModal()
  const [facturaConErrores, setFacturaConErrores] = useState({})
  const [informacionFormulario, setInformacionFormulario] = useState({})
  const [errores, setErrores] = useState([])
  const {state} = useLocation()
  const {rolUsuario} = useObtenerRol()

  useEffect(() => {
    let componenteMontado = true
    const llamarObtenerErroresFacturaPorId = async () => {
      const facturaConErrores = await obtenerErroresFacturaPorId(state.id)

      if (componenteMontado) {
        if (Array.isArray(facturaConErrores.erroresFactura)) {
          const errores = facturaConErrores.erroresFactura.map(
            (error) => error.mensaje
          )

          setErrores(errores)
        }

        setFacturaConErrores(facturaConErrores)
      }
    }

    llamarObtenerErroresFacturaPorId()

    return () => {
      componenteMontado = false
    }
  }, [])

  useEffect(() => {
    const {
      id,
      atencionId,
      tipoDocumento,
      identificacion,
      codigoVerificacion,
      nombreCliente,
      departamentoId,
      municipioId,
      direccion,
      codigoPostal,
      correo,
      actividadEconomica,
      matricula,
      responsabilidadFiscal,
    } = facturaConErrores

    setInformacionFormulario({
      id,
      atencionId,
      tipoDocumento,
      identificacion,
      codigoVerificacion,
      nombreCliente,
      departamentoId,
      municipioId,
      direccion,
      codigoPostal,
      correo,
      actividadEconomica,
      matricula,
      responsabilidadFiscal,
    })
  }, [facturaConErrores])

  const redireccionar = () => {
    if (rolUsuario === ROLES_APP.RECEPCION) {
      history.replace(
        RUTAS_APP.GESTION_FACTURACION.FACTURAS_PENDIENTES_CORRECCION_SEDE
      )
    } else {
      history.replace(RUTAS_APP.GESTION_FACTURA_ELECTRONICA)
    }
  }

  const manejadorGuardar = async (valoresFormulario) => {
    await corregirFactura(valoresFormulario)

    contextoAplicacion.setModal({
      abierto: true,
      titulo: TEXTO_VISUAL.GESTION_FACTURACION.MODAL.FACTURA_ACTUALIZADA.TITULO(
        facturaConErrores.numeroFactura
      ),
      contenido:
        TEXTO_VISUAL.GESTION_FACTURACION.MODAL.FACTURA_ACTUALIZADA.MENSAJE,
      botones: [
        {
          nombre: TEXTO_VISUAL.BOTONES.FINALIZAR,
          click: () => {
            cerrarModal()
            history.push('/main')
          },
        },
        {
          nombre: TEXTO_VISUAL.BOTONES.VOLVER,
          click: () => {
            cerrarModal()
            redireccionar()
          },
        },
      ],
    })
  }

  return (
    <ContenedorPagina tituloPagina={TEXTO_PANTALLA.TITULO}>
      <InformacionFactura facturaConErrores={facturaConErrores} />
      <ContenedorErrores errores={errores} />
      <FormularioCorreccionFactura
        informacionFormulario={informacionFormulario}
        manejadorGuardar={manejadorGuardar}
      />
    </ContenedorPagina>
  )
}
